import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductInformation from "./ProductInformation";
import { Box, FormControlLabel, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { Add, FilterAlt } from "@mui/icons-material";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { updateStateData } from "../../../Store/actions";
import { checkUncheckAllCheckboxes, filterSheetResults } from "../../../api_calls/campaignSheet";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import {
  StyledCheckbox,
  StyledStack,
  StyledWhiteCheckbox,
} from "../../../components/commonComponents/StyledComponents/styled";
import AdlevelModal from "../ModalContent/AdlevelModal";
import MultiSelect from "../../../components/commonComponents/Selectors/MultiSelect";

const CampaignSheetResults = (props) => {
  const sheetDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  //use react state to manage product selections for creating adlevels.
  const [productSelection, setProductSelection] = React.useState([]);
  //to show adlevel modal
  const [showAdlevelModal, setShowAdlevelModal] = React.useState(false);
  //description filter
  const [artikelNameFilter, setArtikelNameFilter] = React.useState("");
  const [artikelNameSelectedOption, setArtikelNameSelectedOption] = React.useState([]);

  const t = useCustomTranslation();

  //select records from redux
  const sheetRecords =
    typeof sheetDetails.filter_applied !== "undefined" && sheetDetails.filter_applied === true
      ? sheetDetails.filtered_sheet_records
      : sheetDetails.campaign_sheet_records;

  //filter sheet results
  const applyFilters = (e) => {
    e.preventDefault();
    if (artikelNameFilter.length !== 0) {
      filterSheetResults(artikelNameFilter);
    } else {
      setProductSelection([]);
      //uncheck all the checkboxes
      checkUncheckAllCheckboxes(false);
      //update redux state
      dispatchAction(
        updateStateData({
          filter_applied: false,
        })
      );
    }
  };

  return (
    <>
      <Box>
        <Stack direction={"row"} spacing={4}>
          <h3>{t("uploaded_product_heading_text")}</h3>
        </Stack>
        <Box height={40} />
        {/* --------- filter for uploaded products ------- */}
        <Stack direction={"row"} spacing={2}>
          <MultiSelect
            label="Article Description"
            enableFullWidth={false}
            options={typeof sheetDetails.sheet_filter_keywords !== "undefined" ? sheetDetails.sheet_filter_keywords : []}
            defaultVal={artikelNameSelectedOption}
            selectionCheck={(option, value) => option.value === value.value}
            onChangeCall={(selectedOption) => {
              let userChoices = [];
              selectedOption.forEach((chosen) => {
                userChoices.push(chosen.value);
              });
              setArtikelNameFilter(userChoices);
              setArtikelNameSelectedOption(selectedOption);
            }}
          />

          {/* ------------ filter button ----------- */}
          <ActionButton onClick={applyFilters} label={"Apply Filter"} icon={<FilterAlt />} />
        </Stack>
        <Box height={40} />
        {/* ------- show data from uploaded sheet --------- */}
        <Stack direction={"row"} spacing={4}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="customized table">
              {/* ------ header row ------ */}
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" className="text-wrapper">
                    <FormControlLabel
                      control={
                        <StyledWhiteCheckbox
                          onChange={(e) => {
                            checkUncheckAllCheckboxes(e.target.checked);
                            if (e.target.checked === true) {
                              let selectedItems = [];
                              sheetRecords.map((record, key) => {
                                selectedItems.push(key);
                              });
                              setProductSelection(selectedItems);
                            } else {
                              //reset the selection
                              setProductSelection([]);
                            }
                          }}
                        />
                      }
                      label="Select All"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Article Description
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    GTIN
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {/* -------- table body ------- */}
              <TableBody>
                {sheetRecords.length !== 0 &&
                  sheetRecords.map((sheetData, key) => (
                    <TableRow key={"campaign_id_" + key}>
                      {/* ------ select checkbox ----- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"}>
                          <FormControlLabel
                            control={
                              <StyledCheckbox
                                checked={productSelection.includes(key)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setProductSelection((prevSelection) => [...prevSelection, key]);
                                  } else {
                                    setProductSelection((prevSelection) => prevSelection.filter((item) => item !== key));
                                  }
                                }}
                              />
                            }
                            label=""
                          />
                        </StyledStack>
                      </StyledTableCell>
                      {/* -------- Product description ------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"}>{sheetData.description}</StyledStack>
                      </StyledTableCell>
                      {/* --------- GTIN ------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"}>{sheetData.gtin}</StyledStack>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <Box height={40} />
        {/* -------- Add new row to the campaign sheet data ------ */}
        {productSelection.length !== 0 && (
          <StyledStack direction={"row"} spacing={4}>
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                setShowAdlevelModal(true);
              }}
              label={t("button_text_for_create_adlevel")}
              icon={<Add />}
            />
          </StyledStack>
        )}
        {/* ---------- created adlevels will show here ------------ */}
        <ProductInformation
          campaignId={props.id}
          campaign_name={props.campaign_name}
          retailer={props.retailer}
          usedFor="Upload"
          closeModal={props.closeModal}
        />
      </Box>

      {/* ======== adlevel modal ======== */}
      {showAdlevelModal === true && (
        <AdlevelModal
          productSelection={productSelection}
          showAdlevelModal={showAdlevelModal}
          setShowAdlevelModal={setShowAdlevelModal}
          setProductSelection={setProductSelection}
        />
      )}
    </>
  );
};

export default CampaignSheetResults;
