import React from "react";
import { Box, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Add, Campaign, Dashboard, Edit, ExpandCircleDown, Insights, Settings } from "@mui/icons-material";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import {
  HtmlTooltip,
  IconContainer,
  StyledDarkRedInformationTypography,
  StyledMainBox,
  StyledStack,
} from "../../components/commonComponents/StyledComponents/styled";
import AddUser from "./modalContent/AddUser";
import { fetchCompanies, fetchUsers } from "../../api_calls/userManagement";
import TabularList from "../../components/commonComponents/TabularLists/TabularList";
import { useDispatch, useSelector } from "react-redux";
import { permissionConfig } from "./modalContent/permissionScopes";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { StyledTableCell } from "../../components/commonComponents/TabularLists/styles";
import platformConfig from "../../platformConfig";
import { updateStateData } from "../../Store/actions";
import LoadMoreButton from "../../components/commonComponents/LoadMore/LoadMoreButton";
import { loadMoreData } from "../../api_calls/utils";

const Users = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalDetails, setModalDetails] = React.useState({});
  const dispatchAction = useDispatch();

  const currentStateData = useSelector((state) => state.userSelections);
  let listItems =
    typeof currentStateData.users_list !== "undefined" && currentStateData.users_list.length !== 0
      ? currentStateData.users_list
      : [];
  let userListitems = listItems.length !== 0 ? listItems.slice(0, currentStateData.initial_load_count_for_users) : [];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = (modalFor, userData) => {
    setModalDetails({
      openFor: modalFor,
      user_data: userData,
    });
    setIsModalOpen(true);
  };

  //initially only 10 results will be loaded, further 10 will be loaded on click of load more button
  const loadMore = () => {
    loadMoreData("initial_load_count_for_users");
  };

  React.useEffect(() => {
    fetchUsers();
    fetchCompanies();
    dispatchAction(
      updateStateData({
        initial_load_count_for_users: platformConfig.initial_load_count,
      })
    );
  }, []);

  return (
    <>
      {/* ========== main component body =========== */}
      <StyledMainBox component="main">
        {/* <Box height={30} /> */}
        <h1>Users</h1>
        {/* ============ Action buttons =========== */}
        <Stack direction="row" spacing={2}>
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              openModal("add", {});
            }}
            label={"New User"}
            icon={<Add />}
          />
        </Stack>
        {/* ============== users listing ============ */}
        <Box height={30} />

        {userListitems.length !== 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              {/* ------ header row ------ */}
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" className="text-wrapper">
                    #
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Username
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Email
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Role
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Access
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userListitems.map((user, index) => {
                  return (
                    <TableRow key={index + "_user_row"}>
                      {/* ---------- id --------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"}>{index + 1}</StyledStack>
                      </StyledTableCell>
                      {/* ---------- username ---------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                        <StyledStack direction={"row"}>{user.username}</StyledStack>
                      </StyledTableCell>
                      {/* ---------- email --------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                        <StyledStack direction={"row"}>{user.email}</StyledStack>
                      </StyledTableCell>
                      {/* -------- role --------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"}>{user.role_name}</StyledStack>
                      </StyledTableCell>
                      {/* ---------- access --------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction="row" spacing={2}>
                          {permissionConfig.scopes.map((accessArea, index) => {
                            return (
                              <HtmlTooltip key={accessArea + "_" + index} title={user.permissions[index][accessArea]}>
                                <IconContainer>
                                  {/* ----- overview ----- */}
                                  {accessArea === "overview" && (
                                    <Dashboard
                                      sx={{
                                        color:
                                          user.permissions[index][accessArea] === "Full access for overview"
                                            ? "#AF3241"
                                            : user.permissions[index][accessArea] === "Limited access for overview"
                                            ? "#f25d66"
                                            : "#E6E6E6",
                                      }}
                                    />
                                  )}
                                  {/* ------- campaigns ----------- */}
                                  {accessArea === "campaigns" && (
                                    <Campaign
                                      sx={{
                                        color:
                                          user.permissions[index][accessArea] === "Full access for campaigns: view and edit"
                                            ? "#AF3241"
                                            : user.permissions[index][accessArea] ===
                                              "Limited access for campaigns: view only"
                                            ? "#f25d66"
                                            : "#E6E6E6",
                                      }}
                                    />
                                  )}
                                  {/* ------- campaigns ----------- */}
                                  {accessArea === "retail_analytics" && (
                                    <Insights
                                      sx={{
                                        color:
                                          user.permissions[index][accessArea] === "Full access for retail analytics"
                                            ? "#AF3241"
                                            : user.permissions[index][accessArea] === "Limited access for retail analytics"
                                            ? "#f25d66"
                                            : "#E6E6E6",
                                      }}
                                    />
                                  )}
                                  {/* ------- infrastructure ----------- */}
                                  {accessArea === "infrastructure" && (
                                    <Settings
                                      sx={{
                                        color:
                                          user.permissions[index][accessArea] ===
                                          "Full access for infrastructure: view and edit"
                                            ? "#AF3241"
                                            : user.permissions[index][accessArea] ===
                                              "Limited access for infrastructure: view only"
                                            ? "#f25d66"
                                            : "#E6E6E6",
                                      }}
                                    />
                                  )}
                                </IconContainer>
                              </HtmlTooltip>
                            );
                          })}
                        </StyledStack>
                      </StyledTableCell>
                      {/* -------- action -------- */}
                      <StyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"}>
                          <HtmlTooltip title={<Typography variant="body4">Edit</Typography>}>
                            <IconContainer>
                              <a
                                href="javacript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openModal("update", user);
                                }}
                              >
                                <Edit sx={{ color: "#AF3241" }} />
                              </a>
                            </IconContainer>
                          </HtmlTooltip>
                        </StyledStack>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {/* --------- record count info --------- */}
        <Box mt={2} id="infoText">
          <StyledDarkRedInformationTypography variant="body2">
            Showing {userListitems.length} out of {listItems.length} results.
          </StyledDarkRedInformationTypography>
        </Box>
        {/* ---------- Load more button ----------- */}
        <Box height={20} />
        {userListitems.length < listItems.length && <LoadMoreButton clickAction={loadMore} />}
      </StyledMainBox>
      {/* ============ modal popup for add/update User ========== */}
      {isModalOpen && (
        <AddUser
          open={isModalOpen}
          onClose={closeModal}
          size={"md"}
          closeModal={closeModal}
          usedFor={modalDetails.openFor}
          userInfo={modalDetails.user_data}
        />
      )}
    </>
  );
};

export default AccessControl(Users, "user_management");
