import React, { useState } from "react";
import { StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import { Grid, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useSelector } from "react-redux";

const GraffanaDash = () => {
  const currentStateData = useSelector((state) => state.userSelections);

  return (
    <Grid container spacing={2} sx={{ width: ` calc(100% + 28px)` }}>
      {currentStateData?.graffana_dashboard_link && (
        <iframe
          src={currentStateData.graffana_dashboard_link}
          title="Dashboard"
          // width="100%"
          height="950px"
          style={{ border: "none", width: "100%" }}
        />
      )}
    </Grid>
  );
};

export default GraffanaDash;
