import React from "react";
import {
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  DialogContentText,
} from "@mui/material";
import { Close, IosShare, Save } from "@mui/icons-material";
import ActionButton, { DarkGreyButton } from "../../../components/commonComponents/Buttons/ActionButton";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { useSelector } from "react-redux";
import BrandStoresFilter from "../../../components/commonComponents/Filters/BrandStoresFilter";
import BrandWeeks from "../filters/shared/BrandWeeks";
import { fetchBrandFilters, saveBrandFilters } from "../../../api_calls/brandReport";
import { resetReportShareData } from "../../../api_calls/campaignSheet";
import ShareReport from "./ShareReport";
import BrandAnalysisGroupMultiple from "../filters/shared/BrandAnalysisGroupMultiple";

const BrandReportFilter = ({ open, size, closeModal, campaignRecord }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showReportShare, setShowReportShare] = React.useState(false);

  const manageShareReportPopup = () => {
    // reset the popup state
    setShowConfirmation(false);
    setShowReportShare(false);
    // check whether confirmation need to be shown or directly report share popup can be shown.
    if (typeof currentStateData.report_filter_saved !== "undefined" && currentStateData.report_filter_saved === false) {
      // show confirmation prompt if filters were selected but not saved
      setShowConfirmation(true);
    } else {
      // show report sharing popup
      setShowReportShare(true);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(e) => {
          e.preventDefault();
        }}
        fullWidth={true}
        maxWidth={size}
      >
        <DialogTitle>
          <strong>{showConfirmation ? "Unsaved Changes" : "Brand Report"}</strong>{" "}
        </DialogTitle>
        {showConfirmation ? (
          <DialogContent>
            <DialogContentText>
              <Typography variant="body2">Do you want to save your changes before sharing this report?</Typography>
            </DialogContentText>
          </DialogContent>
        ) : (
          <DialogContent>
            {/* ------- disclaimer text for brand report --------- */}
            <DialogContentText>
              Share the report of this campaign with brands. In the following you may specify which information are shared
              with the brands. You can change the settings even after you have already shared the report.
            </DialogContentText>
            <Box height={20} />
            <Box>
              <Typography variant="h6">Impressions Filter</Typography>
              <Box height={20} />
              {/* ------- stores ------- */}
              <BrandStoresFilter usedFor={"impression_filter"} />
            </Box>
            <Box height={40} />
            <Box>
              <Typography variant="h6">Performance Filter</Typography>
              <Box height={20} />
              <Stack direction={"row"} spacing={2}>
                {/* ------- stores ------- */}
                <BrandStoresFilter usedFor={"performance_filter"} />
                {/* ------- callendar weeks ------- */}
                <BrandWeeks />
                {/* ------- analysis group ------- */}
                <BrandAnalysisGroupMultiple />
              </Stack>
              <Box height={40} />
            </Box>
          </DialogContent>
        )}

        <Box height={20} />
        {showConfirmation ? (
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <CloseButton
                onClick={(e) => {
                  e.preventDefault();
                  setShowReportShare(false);
                  setShowConfirmation(false);
                  // reset the brand filters
                  // fetchBrandFilters(campaignRecord.campaign_id);
                }}
                label="Cancel"
                icon={<Close />}
              />
              <CloseButton
                onClick={(e) => {
                  e.preventDefault();
                  // reset the brand filters
                  // fetchBrandFilters(campaignRecord.campaign_id);
                  setShowReportShare(true);
                }}
                label="No"
                icon={<Close />}
              />
              <ActionButton
                onClick={(e) => {
                  saveBrandFilters(campaignRecord.campaign_id).then((info) => {
                    setShowReportShare(true);
                  });
                }}
                label={"Yes"}
                icon={<Save />}
              />
            </Stack>
          </DialogActions>
        ) : (
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <CloseButton
                onClick={(e) => {
                  e.preventDefault();
                  // reset the brand filters
                  fetchBrandFilters(campaignRecord.campaign_id);
                  closeModal();
                }}
                label="Cancel"
                icon={<Close />}
              />
              <DarkGreyButton
                onClick={(e) => {
                  manageShareReportPopup();
                }}
                label={"Share"}
                icon={<IosShare />}
              />
              <ActionButton
                onClick={(e) => {
                  saveBrandFilters(campaignRecord.campaign_id);
                }}
                label={"Save"}
                icon={<Save />}
              />
            </Stack>
          </DialogActions>
        )}
      </Dialog>
      {showReportShare === true && (
        <ShareReport
          open={showReportShare}
          onClose={() => {
            setShowReportShare(false);
            resetReportShareData();
          }}
          size={"md"}
          closeModal={() => {
            setShowReportShare(false);
            resetReportShareData();
            closeModal();
          }}
          usedFor={"update"}
          campaignRecord={campaignRecord}
        />
      )}
    </>
  );
};

export default BrandReportFilter;
