import React from "react";
import { changeParameter } from "../../../api_calls/TableauVisuals";
import { Box } from "@mui/material";
import SelectBox from "../Selectors/SelectBox";
import SingleSelect from "../Selectors/SingleSelect";

const rankByOptions = ["No Ranking", ...["Absolute Difference (in pp)", "Relative Difference (in %)"].sort()];

const RankByFilter = ({ vizId }) => {
  const [rankSelection, setRankSelection] = React.useState({});
  const [dataLoaded, setDataLoaded] = React.useState(false);

  React.useEffect(() => {
    setRankSelection({ value: rankByOptions[0], label: rankByOptions[0] });

    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  return dataLoaded ? (
    <Box sx={{ width: 220 }}>
      <SingleSelect
        enableFullWidth={true}
        label={"Rank By"}
        selectionCheck={(option, value) => option.label === value.label}
        options={rankByOptions.map((rankName) => {
          return { value: rankName, label: rankName };
        })}
        defaultVal={rankSelection}
        onChangeCall={(selectedOption) => {
          setRankSelection(selectedOption);
          changeParameter("Targeting_Rank By Parameter", selectedOption.value, vizId);
        }}
      />
    </Box>
  ) : (
    <></>
  );
};

export default RankByFilter;
