import * as React from "react";
import { CustomTextInput } from "./style";
import { HtmlTooltip, IconContainer, StyledStack } from "../StyledComponents/styled";
import { Error, Info } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";

export default function NumericInputField({
  showlabel,
  label,
  elemId,
  classDiv,
  fieldName,
  placeholder,
  defaultVal,
  fieldRequired,
  onChangeCall,
  onBlurCall,
  fieldError,
  inputType,
  fieldInfo,
  errorStyle,
}) {
  return (
    <>
      <CustomTextInput
        margin="normal"
        required={fieldRequired}
        fullWidth
        id={elemId}
        label={label}
        name={fieldName}
        placeholder=""
        variant="standard"
        autoFocus={false}
        autoComplete="off"
        value={defaultVal}
        onChange={onChangeCall}
        onBlur={onBlurCall}
        type={"text"}
        error={errorStyle === "text" && fieldError !== "" ? true : false}
        helperText={errorStyle === "text" && fieldError !== "" ? fieldError : ""}
        InputProps={fieldInfo}
      />
      {errorStyle === "tooltip" && typeof fieldError !== "undefined" && fieldError !== "" && (
        <div style={{ marginTop: "30px" }}>
          <HtmlTooltip title={fieldError}>
            <IconContainer>
              <Error sx={{ color: "#AF3241" }} />
            </IconContainer>
          </HtmlTooltip>
        </div>
      )}
    </>
  );
}
