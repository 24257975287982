import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import { Close, Send } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { sendReportShareInfo } from "../../../api_calls/campaigns";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";

const ShareReport = ({ open, onClose, size, usedFor, closeModal, campaignRecord }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldErrors, setFieldErrors] = React.useState({});

  const handleFieldChange = (campaignData) => {
    dispatchAction(
      updateStateData({
        report_share_data: { ...campaignData, id_campaign: campaignRecord.campaign_id },
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> Share Report </DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            {/* -------- enter emails ------- */}
            <Stack direction={"row"} spacing={4}>
              {/* ------- emails -------- */}
              <TextInputField
                fieldRequired={true}
                elemId="brandEmails"
                label="Enter Emails"
                fieldName="email_list"
                showlabel={true}
                defaultVal={
                  typeof currentStateData.report_share_data !== "undefined" &&
                  typeof currentStateData.report_share_data.email_list !== "undefined"
                    ? currentStateData.report_share_data.email_list
                    : ""
                }
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    email_list: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldErrors.email_list !== "undefined" && fieldErrors.email_list !== ""
                    ? fieldErrors.email_list
                    : ""
                }
              />
            </Stack>
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              onClose(false);
            }}
            label="Cancel"
            icon={<Close />}
          />
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              setFieldErrors({});
              sendReportShareInfo().then((info) => {
                if (info.success === false) {
                  setFieldErrors(info.errorMessages);
                } else {
                  closeModal();
                }
              });
            }}
            label={"Send"}
            icon={<Send />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ShareReport;
