import React from "react";
import { StickyTopRowStack } from "../../../../components/commonComponents/StyledComponents/styled";
import { Box } from "@mui/material";
import SelectBox from "../../../../components/commonComponents/Selectors/SelectBox";
import { useDispatch } from "react-redux";
import { updateStateData } from "../../../../Store/actions";
import { checkWorkSpaceFromCookies } from "../../../../api_calls/userManagement";
import SingleSelect from "../../../../components/commonComponents/Selectors/SingleSelect";

const GraffanaFilter = () => {
  const dispatchAction = useDispatch();
  const checkWorkSpaces = checkWorkSpaceFromCookies();

  const [workSpaceName, setWorkSpaceName] = React.useState("");
  const [storeSelection, setStoreSelection] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [selectedStoreName, setSelectedStoreName] = React.useState({});

  const storeValues = {
    Unimarc: ["Los Leones", "Los Trapenses", "Consistorial", "Pedro Fontova", "Vincent Valdes"].sort(),
    Despar: ["Despar Aspromonte"],
    "EDEKA Rhein-Ruhr": ["Edeka Brueggemeier Kleve", "Edeka Stroetmann Coesfeld"],
    "Pro Getraenke AG": [
      "Getraenke City Jaeger",
      "Getraenke Gotta in Juegesheim",
      "Getraenke Stumpf",
      "Getraenke Weiser Heppenheim",
      "Gotta Getraenkemarkt in Roedermark",
    ].sort(),
  };

  const dashboardLinks = {
    Unimarc: {
      "Los Leones": "https://grafana.cyreenapps.de/public-dashboards/8140f2d7cfbe4062a90ee0703efe8b56",
      "Los Trapenses": "https://grafana.cyreenapps.de/public-dashboards/8bc5fb22c939417d863fd56541723e2b",
      Consistorial: "https://grafana.cyreenapps.de/public-dashboards/17ad9e966286435cbba04963a9dc4891",
      "Pedro Fontova": "https://grafana.cyreenapps.de/public-dashboards/f6dba057410845fa84c426e91b85811a",
      "Vincent Valdes": "https://grafana.cyreenapps.de/public-dashboards/0344a8b654a14f848b7e1dcad9804216",
    },
    Despar: {
      "Despar Aspromonte": "https://grafana.cyreenapps.de/public-dashboards/6e11317f46bd47c59f03932f5e0a73bf",
    },
    "EDEKA Rhein-Ruhr": {
      "Edeka Brueggemeier Kleve": "https://grafana.cyreenapps.de/public-dashboards/d0e34d6baba04c38ac471119755065e4",
      "Edeka Stroetmann Coesfeld": "https://grafana.cyreenapps.de/public-dashboards/a649d6ae2b564b1bbfe061f9fa3f47da",
    },
    "Pro Getraenke AG": {
      "Getraenke City Jaeger": "https://grafana.cyreenapps.de/public-dashboards/a53160f03a314f77be124d2d89d0a4e6",
      "Getraenke Gotta in Juegesheim": "https://grafana.cyreenapps.de/public-dashboards/7c2d99ac424946a68f170c6e3de7eec5",
      "Getraenke Stumpf": "https://grafana.cyreenapps.de/public-dashboards/a2276db5c88a4d019f20bbf41311c514",
      "Getraenke Weiser Heppenheim": "https://grafana.cyreenapps.de/public-dashboards/5d143645230446909a04de354959ce43",
      "Gotta Getraenkemarkt in Roedermark":
        "https://grafana.cyreenapps.de/public-dashboards/6ed932046b9c4efcaf84fa84768851bf",
    },
  };

  React.useEffect(() => {
    if (checkWorkSpaces.success === true && checkWorkSpaces.workspace_options.length > 1) {
      let workSpace = checkWorkSpaces?.current_workspace?.name;

      setWorkSpaceName(workSpace);
      setStoreSelection(storeValues[workSpace][0]);
      setSelectedStoreName({ value: storeValues[workSpace][0], label: storeValues[workSpace][0] });

      dispatchAction(
        updateStateData({
          graffana_dashboard_link: dashboardLinks[workSpace][storeValues[workSpace][0]],
        })
      );

      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    }
  }, []);

  return (
    <StickyTopRowStack direction={"row"} spacing={4}>
      {/* ------- stores filter ----------- */}
      <Box sx={{ width: 220 }}>
        {dataLoaded === true && workSpaceName !== "" && (
          <SingleSelect
            enableFullWidth={true}
            label={"Store"}
            selectionCheck={(option, value) => option.label === value.label}
            options={storeValues[workSpaceName].map((storeName) => {
              return { value: storeName, label: storeName };
            })}
            defaultVal={selectedStoreName}
            onChangeCall={(selectedOption) => {
              setSelectedStoreName(selectedOption);
              setStoreSelection(selectedOption.value);
              dispatchAction(
                updateStateData({
                  graffana_dashboard_link: dashboardLinks[workSpaceName][selectedOption.value],
                })
              );
            }}
          />
        )}
      </Box>
    </StickyTopRowStack>
  );
};

export default GraffanaFilter;
