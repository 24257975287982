import React from "react";
import { changeParameter } from "../../../api_calls/TableauVisuals";
import { Box } from "@mui/material";
import AnalysisGroup from "./shared/AnalysisGroup";
import { StickyTopRowStack } from "../../../components/commonComponents/StyledComponents/styled";
import SelectBox from "../../../components/commonComponents/Selectors/SelectBox";
import { useSelector } from "react-redux";
import RankByFilter from "../../../components/commonComponents/Filters/RankByFilter";
import AnalysisGroupSingle from "./shared/AnalysisGroupSingle";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";

// const activityOptions = ["Discount", "Second placement", "Regal wochen", "Mother's Day", "Design Edition"];

const MarketingFilters = ({ vizId }) => {
  const userStateData = useSelector((state) => state.userSelections);
  const [activitySelection, setActivitySelection] = React.useState({});
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const activityOptions =
    typeof userStateData.activityOptions !== "undefined" && userStateData.activityOptions.length !== 0
      ? userStateData.activityOptions.sort()
      : [];
  const handleChange = (e) => {
    setActivitySelection(e.target.value);
    changeParameter("Activity type", e.target.value, vizId);
  };

  React.useEffect(() => {
    if (activityOptions.length !== 0) {
      setActivitySelection({ value: activityOptions[0], label: activityOptions[0] });

      setTimeout(() => {
        setDataLoaded(true);
      }, 1000);
    }
  }, []);

  return (
    <StickyTopRowStack direction={"row"} spacing={4}>
      {/* ------------ activity type --------------- */}
      {dataLoaded === true && activityOptions.length !== 0 && (
        <Box sx={{ width: 220 }}>
          {/* <SelectBox
            label="Activity Type"
            options={activityOptions}
            value={activitySelection}
            onChange={handleChange}
            renderValue={(selected) => {
              return selected;
            }}
            multiSelect={false}
          />  */}

          <SingleSelect
            enableFullWidth={true}
            label={"Activity Type"}
            selectionCheck={(option, value) => option.label === value.label}
            options={activityOptions.map((activityName) => {
              return { value: activityName, label: activityName };
            })}
            defaultVal={activitySelection}
            onChangeCall={(selectedOption) => {
              let userChoices = [];
              if (selectedOption.value.includes("(All)")) {
                userChoices = activityOptions;
              } else {
                userChoices = [selectedOption.value];
              }
              setActivitySelection(selectedOption);
              changeParameter("Activity type", selectedOption.value, vizId);
            }}
          />
        </Box>
      )}
      {/* ------- rank by filter ------ */}
      {/* <RankByFilter /> */}
      {/* ------------ analysis group ------------- */}
      <Box sx={{ width: 220 }}>
        <AnalysisGroupSingle vizId={vizId} multiSelect={false} />
      </Box>
    </StickyTopRowStack>
  );
};

export default MarketingFilters;
