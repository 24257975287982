import React from "react";
import { StickyTopRowStack } from "../../../../components/commonComponents/StyledComponents/styled";
import { Box } from "@mui/material";
import StoreFilter from "../../../../components/commonComponents/Filters/StoreFilter";
import SingleSelectStoreFilter from "../../../../components/commonComponents/Filters/SingleSelectStoreFilter";
import { dbFormatDate, formatToStandardDate, standardFormatToday } from "../../../../api_calls/utils";
import DateRangeFilter from "../../../../components/commonComponents/Filters/DateRangeFilter";
import { filterRangeValue, filterSingleValue } from "../../../../api_calls/TableauVisuals";
import { useSelector } from "react-redux";
import SelectBox from "../../../../components/commonComponents/Selectors/SelectBox";
import MultiSelect from "../../../../components/commonComponents/Selectors/MultiSelect";

const statusOptions = ["Receipts available but not yet processed", "Receipts not yet received", "Receipts processed"];

const RecieptMonitoringFilter = ({ vizId }) => {
  const userStateDate = useSelector((state) => state.userSelections);
  const startDate =
    typeof userStateDate.date_range_start_date !== "undefined"
      ? new Date(userStateDate.date_range_start_date)
      : new Date("2023-02-01");
  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  const [changesMade, setChangesMade] = React.useState(false);
  const [statusSelection, setStatusSelection] = React.useState(statusOptions);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [selectedStatusName, setSelectedStatusName] = React.useState([]);

  const handleChange = (e) => {
    setChangesMade(true);
    setStatusSelection((preState) => {
      if (preState.includes("(All)") && !e.target.value.includes("(All)")) {
        return [];
      } else if (
        preState.includes("(All)") &&
        e.target.value.includes("(All)") &&
        e.target.value.length !== statusOptions.length
      ) {
        const selectedOptions = e.target.value.filter((option) => option !== "(All)");
        return selectedOptions;
      } else if (!preState.includes("(All)") && e.target.value.length === statusOptions.length - 1) {
        // Replace selection with "(All)"
        return statusOptions;
      } else {
        if (e.target.value.includes("(All)")) {
          return statusOptions;
        } else {
          return e.target.value.length === 0 ? [] : e.target.value;
        }
      }
    });
  };

  React.useEffect(() => {
    setStatusSelection(statusOptions);
    setSelectedStatusName(
      statusOptions.map((statusName) => {
        return { value: statusName, label: statusName };
      })
    );
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  return dataLoaded ? (
    <StickyTopRowStack direction={"row"} spacing={4}>
      {/* ------- stores filter ----------- */}
      <Box sx={{ width: 220 }}>
        <MultiSelect
          enableFullWidth={true}
          label="Status"
          options={statusOptions.map((statusName) => {
            return { value: statusName, label: statusName };
          })}
          defaultVal={selectedStatusName}
          selectionCheck={(option, value) => option.label === value.label}
          onBlurCall={(e) => {
            if (statusSelection.length !== 0 && changesMade === true) {
              filterSingleValue("Status", statusSelection, vizId);
              setChangesMade(false);
            }
          }}
          onChangeCall={(selectedOption) => {
            let userChoices = [];
            selectedOption.forEach((chosen) => {
              if (chosen.label !== "(All)") {
                userChoices.push(chosen.label);
              } else {
                userChoices.push([...new Set(statusOptions)]);
              }
            });
            setStatusSelection(userChoices);
            setSelectedStatusName(selectedOption);
            setChangesMade(true);
          }}
        />
      </Box>
      {/* ------- Date filter ------ */}
      <Box sx={{ width: 250 }}>
        <DateRangeFilter
          fieldName={"Date"}
          minSelectableDate={startDate}
          maxSelectableDate={dbFormatDate(yesterday)}
          onValueChange={filterRangeValue}
          vizId={vizId}
        />
      </Box>
    </StickyTopRowStack>
  ) : (
    <></>
  );
};

export default RecieptMonitoringFilter;
