import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import SelectBox from "../../../components/commonComponents/Selectors/SelectBox";
import NumericInputField from "../../../components/commonComponents/TextInputs/NumericInputField";
import { Close, Info, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import {
  checkDeviceStatus,
  fetchCountryId,
  fetchOpeningHourId,
  fetchStoreLocations,
  reset_infrastructure_config_page_data,
  saveStoreLocData,
} from "../../../api_calls/infrastructure";
import platformConfig from "../../../platformConfig";
import { checkUserDetailsFromCookie } from "../../../api_calls/userManagement";
import {
  capitalizeAndReplaceUnderScore,
  generateRandomNumber,
  weekDayNames,
  weekDayOptions,
} from "../../../api_calls/utils";
import {
  HtmlTooltip,
  IconContainer,
  StyledBlackInformationTypography,
  StyledDarkRedInformationTypography,
  StyledCheckbox,
  StyledGreyInfoBox,
  StyledInfoBox,
  StyledLeftAlignedStack,
  StyledSwitch,
  StyledWhiteSwitch,
} from "../../../components/commonComponents/StyledComponents/styled";
import { LeftStyledTableCell, StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import ErrorMessageText from "../../../components/commonComponents/Errors/ErrorMessageText";
import SingleSelect from "../../../components/commonComponents/Selectors/SingleSelect";

const NewLocation = ({ open, size, usedFor, closeModal, sectionToEdit }) => {
  const userStateDetails = useSelector((state) => state.userSelections);
  const loggedUserDetails = checkUserDetailsFromCookie();
  const [manualHours, setManualHours] = React.useState(false);
  const [newRegion, setNewRegion] = React.useState(false);
  const [showStatusInfoText, setShowStatusInfoText] = React.useState(false);
  const navigate = useNavigate();
  const [fieldError, setFieldError] = React.useState({});
  const weekDays = [
    ...["All"],
    ...weekDayOptions.map((day) => {
      return day.label;
    }),
  ];

  const dispatchAction = useDispatch();

  const create_time_selections_replica = () => {
    if (typeof userStateDetails.new_store_loc_data !== "undefined") {
      return {
        store_opening_hour: userStateDetails.new_store_loc_data.store_opening_hour,
        store_opening_hour_id: userStateDetails.new_store_loc_data.store_opening_hour_id,
        weekday_timings: userStateDetails.new_store_loc_data.weekday_timings,
        hours_description: userStateDetails.new_store_loc_data.hours_description,
        open_all_time: userStateDetails.new_store_loc_data.open_all_time,
        selected_default_hours: userStateDetails.new_store_loc_data.selected_default_hours,
        selected_week_days: userStateDetails.new_store_loc_data.selected_week_days,
        same_time_for_all: userStateDetails.new_store_loc_data.same_time_for_all,
        new_opening_hours: userStateDetails.new_store_loc_data.new_opening_hours,
      };
    } else {
      return {};
    }
  };

  const opening_hour_params = (newHours, hourFormat) => {
    let openingHourId = newHours !== "Custom" ? fetchOpeningHourId(newHours) : "";
    let customHours = hourFormat !== "no_24" ? ["00:05", "23:50"] : ["08:00", "21:00"];
    let weekDayHours =
      newHours === "Custom"
        ? {
            monday: customHours,
            tuesday: customHours,
            wednesday: customHours,
            thursday: customHours,
            friday: customHours,
            saturday: customHours,
            sunday: customHours,
          }
        : openingHourId.weekDayHours;
    let selectedDays =
      newHours === "Custom"
        ? {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true,
          }
        : openingHourId.selectedWeekDays;

    let weekDayHoursDesc = newHours === "Custom" ? "Mon.-Sun. 08:00 - 21:00" : newHours;

    let openingHourObj = {
      store_opening_hour: newHours,
      store_opening_hour_id: openingHourId.hourId,
      weekday_timings: weekDayHours,
      hours_description: weekDayHoursDesc,
      open_all_time: hourFormat !== "no_24" ? true : false,
      selected_default_hours: newHours === "Custom" ? customHours : openingHourId.selected_default_hours,
      selected_week_days: selectedDays,
      same_time_for_all: true,
      new_opening_hours: newHours === "Custom" ? true : false,
    };

    if (hourFormat !== "no_24") {
      // 24 hours format selected
      openingHourObj = {
        ...openingHourObj,
        replicated_prev_selections: create_time_selections_replica(),
        same_time_switch_replica: {},
      };
    } else {
      if (
        typeof userStateDetails.new_store_loc_data !== "undefined" &&
        typeof userStateDetails.new_store_loc_data.replicated_prev_selections !== "undefined" &&
        Object.keys(userStateDetails.new_store_loc_data.replicated_prev_selections).length !== 0
      ) {
        openingHourObj = {
          ...openingHourObj,
          ...userStateDetails.new_store_loc_data.replicated_prev_selections,
          replicated_prev_selections: {},
          same_time_switch_replica: {},
        };
      }
    }

    return openingHourObj;
  };

  const handleSameTimeFeature = (sameTimeSelection) => {
    const selectedStoreData =
      typeof userStateDetails.new_store_loc_data !== "undefined" ? userStateDetails.new_store_loc_data : {};

    if (sameTimeSelection === true) {
      let openingHourObj = {
        same_time_switch_replica: {
          store_opening_hour: userStateDetails.new_store_loc_data.store_opening_hour,
          store_opening_hour_id: userStateDetails.new_store_loc_data.store_opening_hour_id,
          weekday_timings: userStateDetails.new_store_loc_data.weekday_timings,
          hours_description: userStateDetails.new_store_loc_data.hours_description,
          // open_all_time: userStateDetails.new_store_loc_data.open_all_time,
          selected_default_hours: userStateDetails.new_store_loc_data.selected_default_hours,
          selected_week_days: userStateDetails.new_store_loc_data.selected_week_days,
          // same_time_for_all: userStateDetails.new_store_loc_data.same_time_for_all,
          new_opening_hours: userStateDetails.new_store_loc_data.new_opening_hours,
        },
      };
      let defaultHours = [
        typeof userStateDetails.new_store_loc_data !== "undefined" &&
        typeof userStateDetails.new_store_loc_data.selected_default_hours !== "undefined"
          ? userStateDetails.new_store_loc_data.selected_default_hours[0]
          : "",
        typeof userStateDetails.new_store_loc_data !== "undefined" &&
        typeof userStateDetails.new_store_loc_data.selected_default_hours !== "undefined"
          ? userStateDetails.new_store_loc_data.selected_default_hours[1]
          : "",
      ];
      const weekDayTimings = {
        monday: defaultHours,
        tuesday: defaultHours,
        wednesday: defaultHours,
        thursday: defaultHours,
        friday: defaultHours,
        saturday: defaultHours,
        sunday: defaultHours,
      };
      openingHourObj = {
        ...openingHourObj,
        weekday_timings: weekDayTimings,
        time_modifications:
          userStateDetails.new_store_loc_data.new_opening_hours === false
            ? true
            : userStateDetails.new_store_loc_data.new_opening_hours,
      };

      dispatchAction(
        updateStateData({
          new_store_loc_data: { ...selectedStoreData, ...openingHourObj, same_time_for_all: sameTimeSelection },
        })
      );
    } else {
      if (
        typeof userStateDetails.new_store_loc_data !== "undefined" &&
        typeof userStateDetails.new_store_loc_data.same_time_switch_replica !== "undefined" &&
        Object.keys(userStateDetails.new_store_loc_data.same_time_switch_replica).length !== 0
      ) {
        let openingHourObj = {
          ...selectedStoreData,
          ...userStateDetails.new_store_loc_data.same_time_switch_replica,
          same_time_switch_replica: {},
        };
        dispatchAction(
          updateStateData({
            new_store_loc_data: { ...selectedStoreData, ...openingHourObj, same_time_for_all: sameTimeSelection },
          })
        );
      } else {
        dispatchAction(
          updateStateData({
            new_store_loc_data: { ...selectedStoreData, same_time_for_all: sameTimeSelection },
          })
        );
      }
    }
  };

  const handleFieldChange = (storeLocData) => {
    const selectedStoreData =
      typeof userStateDetails.new_store_loc_data !== "undefined" ? userStateDetails.new_store_loc_data : {};
    dispatchAction(
      updateStateData({
        new_store_loc_data: { ...selectedStoreData, ...storeLocData },
      })
    );
  };

  const checkTimeForAllStatus = () => {
    const selectedOpeningTimes = userStateDetails?.store_overview?.opening_hours;
    let timeForMonday = [selectedOpeningTimes.monday[0], selectedOpeningTimes.monday[1]];
    let sameTime = true;
    let dayNames = Object.values(weekDayNames).map((dayName) => dayName.toLowerCase());

    dayNames.forEach((day) => {
      if (
        selectedOpeningTimes[day][0] !== "00:00" &&
        selectedOpeningTimes[day][1] !== "00:00" &&
        (selectedOpeningTimes[day][0] !== timeForMonday[0] || selectedOpeningTimes[day][1] !== timeForMonday[1])
      ) {
        sameTime = false;
      }
    });

    return sameTime;
  };

  React.useEffect(() => {
    if (usedFor === "update") {
      handleFieldChange({
        id: userStateDetails?.store_overview?.id,
        store_name: userStateDetails?.store_overview?.name,
        store_country: userStateDetails?.store_overview?.country?.name,
        store_country_id: userStateDetails?.store_overview?.country?.id,
        store_region: userStateDetails?.store_overview?.region?.name,
        store_region_obj: userStateDetails?.store_overview?.region
          ? { label: userStateDetails.store_overview.region.name, id: userStateDetails.store_overview.region.id }
          : {},
        store_region_id: userStateDetails?.store_overview?.region?.id,
        store_street: userStateDetails?.store_overview?.address,
        store_zip: userStateDetails?.store_overview?.zipCode,
        store_city: userStateDetails?.store_overview?.city,
        store_opening_hour: userStateDetails?.store_overview?.opening_hours?.description,
        store_opening_hour_id: userStateDetails?.store_overview?.opening_hours?.id,
        new_opening_hours: false,
        store_trolleys: userStateDetails?.store_overview?.installed_trolleys,
        store_baskets: userStateDetails?.store_overview?.installed_baskets,
        store_size: userStateDetails?.store_overview?.store_size,
        store_status: checkDeviceStatus() === true ? "Active" : "Inactive",
        automated_checkout: userStateDetails?.store_overview?.automated === true ? "Automated" : "Manual/No",
        retailer_store_id: userStateDetails?.store_overview?.number_store_retailer,
        longitude: userStateDetails?.store_overview?.longitude,
        latitude: userStateDetails?.store_overview?.latitude,
        // ...opening_hour_params(userStateDetails?.store_overview?.opening_hours?.description),
        store_opening_hour: userStateDetails?.store_overview?.opening_hours?.description,
        store_opening_hour_id: userStateDetails?.store_overview?.opening_hours?.id,
        data_process_status: userStateDetails?.store_overview?.store_status === 1 ? "Active" : "Inactive",
        weekday_timings: {
          monday: userStateDetails?.store_overview?.opening_hours?.monday,
          tuesday: userStateDetails?.store_overview?.opening_hours?.tuesday,
          wednesday: userStateDetails?.store_overview?.opening_hours?.wednesday,
          thursday: userStateDetails?.store_overview?.opening_hours?.thursday,
          friday: userStateDetails?.store_overview?.opening_hours?.friday,
          saturday: userStateDetails?.store_overview?.opening_hours?.saturday,
          sunday: userStateDetails?.store_overview?.opening_hours?.sunday,
        },
        hours_description: userStateDetails?.store_overview?.opening_hours?.description,
        selected_default_hours: [
          userStateDetails?.store_overview?.opening_hours?.monday[0],
          userStateDetails?.store_overview?.opening_hours?.monday[1],
        ],
        selected_week_days: {
          monday:
            userStateDetails?.store_overview?.opening_hours?.monday[0] === "00:00" &&
            userStateDetails?.store_overview?.opening_hours?.monday[1] === "00:00"
              ? false
              : true,
          tuesday:
            userStateDetails?.store_overview?.opening_hours?.tuesday[0] === "00:00" &&
            userStateDetails?.store_overview?.opening_hours?.tuesday[1] === "00:00"
              ? false
              : true,
          wednesday:
            userStateDetails?.store_overview?.opening_hours?.wednesday[0] === "00:00" &&
            userStateDetails?.store_overview?.opening_hours?.wednesday[1] === "00:00"
              ? false
              : true,
          thursday:
            userStateDetails?.store_overview?.opening_hours?.thursday[0] === "00:00" &&
            userStateDetails?.store_overview?.opening_hours?.thursday[1] === "00:00"
              ? false
              : true,
          friday:
            userStateDetails?.store_overview?.opening_hours?.friday[0] === "00:00" &&
            userStateDetails?.store_overview?.opening_hours?.friday[1] === "00:00"
              ? false
              : true,
          saturday:
            userStateDetails?.store_overview?.opening_hours?.saturday[0] === "00:00" &&
            userStateDetails?.store_overview?.opening_hours?.saturday[1] === "00:00"
              ? false
              : true,
          sunday:
            userStateDetails?.store_overview?.opening_hours?.sunday[0] === "00:00" &&
            userStateDetails?.store_overview?.opening_hours?.sunday[1] === "00:00"
              ? false
              : true,
        },
        same_time_for_all: checkTimeForAllStatus(),
        open_all_time: userStateDetails?.store_overview?.opening_hours?.description === "24hours/7days" ? true : false,
        new_opening_hours: false,
      });
    } else {
      const openingHourPreSelections = opening_hour_params("Custom", "no_24", false);
      handleFieldChange(openingHourPreSelections);
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle>{usedFor === "update" ? "Update Store Information" : "Add Store"}</DialogTitle>
      <DialogContent>
        {["Both", "StoreDetail"].includes(sectionToEdit) && (
          <>
            {usedFor === "Add" && (
              <>
                <Box height={20} />
                <Typography variant="h6">Store Information</Typography>
              </>
            )}
            {/* <Box height={20} /> */}
            {/* -------------- store details -------------- */}
            {/* <Typography variant="h6">Store Details</Typography> */}
            <Box height={20} />
            <Stack direction={"row"} spacing={4}>
              {/* -------- Name -------- */}
              <TextInputField
                fieldRequired={true}
                elemId="name"
                label="Name"
                fieldName="name"
                showlabel={true}
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.store_name !== "undefined"
                    ? userStateDetails.new_store_loc_data.store_name
                    : ""
                }
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    store_name: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.store_name !== "undefined" && fieldError.store_name !== "" ? fieldError.store_name : ""
                }
              />
              {/* --------- country -------- */}
              <SelectBox
                label="Country*"
                readOnlyFlag={true}
                options={userStateDetails.countries_list.map((countryOption) => {
                  return countryOption.name;
                })}
                value={
                  typeof loggedUserDetails.success !== "undefined" && loggedUserDetails.success === true
                    ? loggedUserDetails.user_detail.current_workspace.country.name
                    : ""
                }
                onChange={(e) => {
                  // save details
                  handleFieldChange({
                    store_country: e.target.value,
                    store_country_id: fetchCountryId(e.target.value),
                  });
                }}
                renderValue={(selected) => {
                  return selected;
                }}
                multiSelect={false}
                fieldError={
                  typeof fieldError.store_country !== "undefined" && fieldError.store_country !== ""
                    ? fieldError.store_country
                    : ""
                }
              />
            </Stack>
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {/* -------- Region -------- */}
              <SingleSelect
                enableFullWidth={true}
                label="Region*"
                options={typeof userStateDetails.regions_list !== "undefined" ? userStateDetails.regions_list : []}
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.store_region_obj !== "undefined"
                    ? userStateDetails.new_store_loc_data.store_region_obj
                    : {}
                }
                selectionCheck={(option, value) => option.id === value.id}
                onChangeCall={(selectedOption) => {
                  handleFieldChange({
                    store_region: selectedOption.label,
                    store_region_id: selectedOption.id,
                    store_region_obj: selectedOption,
                    new_region: false,
                  });
                }}
                fieldError={
                  typeof fieldError.store_region !== "undefined" && fieldError.store_region !== ""
                    ? fieldError.store_region
                    : ""
                }
              />

              {/* ---------- street number --------- */}
              <TextInputField
                fieldRequired={true}
                elemId="street"
                label="Street & No."
                fieldName="street"
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.store_street !== "undefined"
                    ? userStateDetails.new_store_loc_data.store_street
                    : ""
                }
                showlabel={true}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    store_street: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.store_street !== "undefined" && fieldError.store_street !== ""
                    ? fieldError.store_street
                    : ""
                }
              />
            </Stack>
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {/* ------------ zip code ----------- */}
              <TextInputField
                fieldRequired={true}
                elemId="zip_code"
                label="Zip Code"
                fieldName="zip_code"
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.store_zip !== "undefined"
                    ? userStateDetails.new_store_loc_data.store_zip
                    : ""
                }
                showlabel={true}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    store_zip: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.store_zip !== "undefined" && fieldError.store_zip !== "" ? fieldError.store_zip : ""
                }
              />
              {/* ---------- city --------- */}
              <TextInputField
                fieldRequired={true}
                elemId="city"
                label="City"
                fieldName="city"
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.store_city !== "undefined"
                    ? userStateDetails.new_store_loc_data.store_city
                    : ""
                }
                showlabel={true}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    store_city: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.store_city !== "undefined" && fieldError.store_city !== "" ? fieldError.store_city : ""
                }
              />
            </Stack>
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {/* ---------- latitude --------- */}
              <TextInputField
                fieldRequired={true}
                elemId="Latitude"
                label="Latitude"
                fieldName="Latitude"
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.latitude !== "undefined"
                    ? userStateDetails.new_store_loc_data.latitude
                    : ""
                }
                showlabel={true}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    latitude: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.latitude !== "undefined" && fieldError.latitude !== "" ? fieldError.latitude : ""
                }
                iconProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HtmlTooltip
                        title={
                          <Box width={250}>
                            <Typography variant="body2">
                              Please use accurate information for latitude and longitude. We recommend using the information
                              displayed by Google Maps. The order of values used by Google Maps is latitude followed by
                              longitude.
                            </Typography>
                          </Box>
                        }
                      >
                        <IconButton>
                          <Info />
                        </IconButton>
                      </HtmlTooltip>
                    </InputAdornment>
                  ),
                }}
              />
              {/* ------------ longitude ----------- */}
              <TextInputField
                fieldRequired={true}
                elemId="Longitude"
                label="Longitude"
                fieldName="longitude"
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.longitude !== "undefined"
                    ? userStateDetails.new_store_loc_data.longitude
                    : ""
                }
                showlabel={true}
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    longitude: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.longitude !== "undefined" && fieldError.longitude !== "" ? fieldError.longitude : ""
                }
                iconProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HtmlTooltip
                        title={
                          <Box width={250}>
                            <Typography variant="body2">
                              Please use accurate information for latitude and longitude. We recommend using the information
                              displayed by Google Maps. The order of values used by Google Maps is latitude followed by
                              longitude.
                            </Typography>
                          </Box>
                        }
                      >
                        <IconButton>
                          <Info />
                        </IconButton>
                      </HtmlTooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Box height={40} />
            {typeof userStateDetails.infra_opening_hours !== "undefined" &&
              userStateDetails.infra_opening_hours.length !== 0 && (
                <Stack direction={"row"} spacing={4}>
                  <Typography variant="body2">
                    <strong>Opening Hours</strong>
                  </Typography>
                </Stack>
              )}
            {/* ----------- weekday timing selections ------------ */}
            <Box height={20} />

            {typeof userStateDetails.new_store_loc_data !== "undefined" &&
              typeof userStateDetails.new_store_loc_data.weekday_timings !== "undefined" && (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 300 }} aria-label="customized table">
                    <TableBody>
                      {/* ---- 24 hours switch ----- */}
                      <TableRow>
                        <LeftStyledTableCell align="left" className="text-wrapper" colSpan={3}>
                          <StyledSwitch
                            checked={
                              typeof userStateDetails.new_store_loc_data !== "undefined" &&
                              typeof userStateDetails.new_store_loc_data.open_all_time !== "undefined"
                                ? userStateDetails.new_store_loc_data.open_all_time
                                : false
                            }
                            onChange={(e) => {
                              const hourFormat = e.target.checked === true ? "24Hours" : "no_24";
                              const openingHourPreSelections = opening_hour_params("Custom", hourFormat);
                              handleFieldChange(openingHourPreSelections);
                            }}
                          />{" "}
                          Open 24h
                        </LeftStyledTableCell>
                      </TableRow>
                      <TableRow>
                        <LeftStyledTableCell align="left" className="text-wrapper">
                          <StyledSwitch
                            checked={
                              typeof userStateDetails.new_store_loc_data !== "undefined" &&
                              typeof userStateDetails.new_store_loc_data.same_time_for_all !== "undefined"
                                ? userStateDetails.new_store_loc_data.same_time_for_all
                                : true
                            }
                            onChange={(e) => {
                              handleSameTimeFeature(e.target.checked);
                            }}
                          />{" "}
                          Same Times for All Days
                        </LeftStyledTableCell>
                        <LeftStyledTableCell align="left" className="text-wrapper">
                          {/* ------- Opening time -------- */}
                          <SingleSelect
                            enableFullWidth={true}
                            label="Opening Time*"
                            elemId={"openingDefault"}
                            options={platformConfig.hour_values.map((hourVal) => {
                              return { label: hourVal, value: hourVal };
                            })}
                            defaultVal={
                              typeof userStateDetails.new_store_loc_data !== "undefined" &&
                              typeof userStateDetails.new_store_loc_data.selected_default_hours !== "undefined"
                                ? {
                                    label: userStateDetails.new_store_loc_data.selected_default_hours[0],
                                    value: userStateDetails.new_store_loc_data.selected_default_hours[0],
                                  }
                                : ""
                            }
                            selectionCheck={(option, value) => option.value === value.value}
                            onChangeCall={(selectedOption) => {
                              let selectedTimings = [
                                selectedOption.value,
                                userStateDetails.new_store_loc_data.selected_default_hours[1],
                              ];

                              // save details
                              handleFieldChange({
                                selected_default_hours: selectedTimings,
                                time_modifications:
                                  userStateDetails.new_store_loc_data.new_opening_hours === false
                                    ? true
                                    : userStateDetails.new_store_loc_data.new_opening_hours,
                                weekday_timings: {
                                  monday: selectedTimings,
                                  tuesday: selectedTimings,
                                  wednesday: selectedTimings,
                                  thursday: selectedTimings,
                                  friday: selectedTimings,
                                  saturday: selectedTimings,
                                  sunday: selectedTimings,
                                },
                              });
                            }}
                            fieldError={
                              typeof fieldError.store_new_opening_hour !== "undefined" &&
                              fieldError.store_new_opening_hour !== ""
                                ? fieldError.store_new_opening_hour
                                : ""
                            }
                          />
                        </LeftStyledTableCell>
                        <LeftStyledTableCell align="left" className="text-wrapper">
                          <SingleSelect
                            enableFullWidth={true}
                            label="Closing Time*"
                            elemId={"closingDefault"}
                            options={platformConfig.hour_values.map((hourVal) => {
                              return { label: hourVal, value: hourVal };
                            })}
                            defaultVal={
                              typeof userStateDetails.new_store_loc_data !== "undefined" &&
                              typeof userStateDetails.new_store_loc_data.selected_default_hours !== "undefined"
                                ? {
                                    label: userStateDetails.new_store_loc_data.selected_default_hours[1],
                                    value: userStateDetails.new_store_loc_data.selected_default_hours[1],
                                  }
                                : ""
                            }
                            selectionCheck={(option, value) => option.value === value.value}
                            onChangeCall={(selectedOption) => {
                              let selectedTimings = [
                                userStateDetails.new_store_loc_data.selected_default_hours[0],
                                selectedOption.value,
                              ];

                              // save details
                              handleFieldChange({
                                selected_default_hours: selectedTimings,
                                time_modifications:
                                  userStateDetails.new_store_loc_data.new_opening_hours === false
                                    ? true
                                    : userStateDetails.new_store_loc_data.new_opening_hours,
                                weekday_timings: {
                                  monday: selectedTimings,
                                  tuesday: selectedTimings,
                                  wednesday: selectedTimings,
                                  thursday: selectedTimings,
                                  friday: selectedTimings,
                                  saturday: selectedTimings,
                                  sunday: selectedTimings,
                                },
                              });
                            }}
                            fieldError={
                              typeof fieldError.store_new_closing_hour !== "undefined" &&
                              fieldError.store_new_closing_hour !== ""
                                ? fieldError.store_new_closing_hour
                                : ""
                            }
                          />
                        </LeftStyledTableCell>
                      </TableRow>
                      {/* --------- show all week day options with opening/closing time ---------- */}
                      {Object.keys(userStateDetails.new_store_loc_data.weekday_timings).map((dayName) => {
                        return (
                          <TableRow id={dayName + "_" + generateRandomNumber(4)} key={dayName}>
                            {/* ---------- weekday checkbox ---------- */}
                            <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                              <StyledLeftAlignedStack direction={"row"} spacing={1}>
                                <StyledCheckbox
                                  checked={userStateDetails.new_store_loc_data.selected_week_days[dayName]}
                                  // style={{ marginRight: 8 }}
                                  onChange={(e) => {
                                    let selectedTimings = [
                                      userStateDetails.new_store_loc_data.selected_default_hours[0],
                                      userStateDetails.new_store_loc_data.selected_default_hours[1],
                                    ];
                                    let timeParams = {
                                      time_modifications:
                                        userStateDetails.new_store_loc_data.new_opening_hours === false
                                          ? true
                                          : userStateDetails.new_store_loc_data.new_opening_hours,
                                      selected_week_days: {
                                        ...userStateDetails.new_store_loc_data.selected_week_days,
                                        [dayName]: e.target.checked,
                                      },
                                      store_opening_hour: "Custom",
                                    };
                                    if (
                                      e.target.checked === true &&
                                      typeof userStateDetails.new_store_loc_data !== "undefined" &&
                                      typeof userStateDetails.new_store_loc_data.same_time_for_all !== "undefined" &&
                                      userStateDetails.new_store_loc_data.same_time_for_all === true
                                    ) {
                                      timeParams = {
                                        ...timeParams,
                                        weekday_timings: {
                                          ...userStateDetails.new_store_loc_data.weekday_timings,
                                          [dayName]: selectedTimings,
                                        },
                                      };
                                    }
                                    handleFieldChange(timeParams);
                                  }}
                                />{" "}
                                <div style={{ marginTop: "5px" }}>{capitalizeAndReplaceUnderScore(dayName)}</div>
                              </StyledLeftAlignedStack>
                            </LeftStyledTableCell>
                            {/* ------------- opening/closing times --------- */}
                            {userStateDetails.new_store_loc_data.selected_week_days[dayName] === false ? (
                              <StyledTableCell
                                align="center"
                                component="td"
                                colSpan={2}
                                scope="row"
                                className="text-wrapper"
                              >
                                {" "}
                                Closed{" "}
                              </StyledTableCell>
                            ) : (
                              <>
                                {/* ------ opening time ------ */}
                                <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                                  <StyledLeftAlignedStack direction={"row"}>
                                    <SingleSelect
                                      enableFullWidth={true}
                                      label="Opening Time*"
                                      elemId={"openingWeekDay_" + dayName}
                                      readOnlyFlag={userStateDetails.new_store_loc_data.same_time_for_all}
                                      options={platformConfig.hour_values.map((hourVal) => {
                                        return { label: hourVal, value: hourVal };
                                      })}
                                      defaultVal={
                                        typeof userStateDetails.new_store_loc_data !== "undefined" &&
                                        typeof userStateDetails.new_store_loc_data.weekday_timings !== "undefined"
                                          ? {
                                              label: userStateDetails.new_store_loc_data.weekday_timings[dayName][0],
                                              value: userStateDetails.new_store_loc_data.weekday_timings[dayName][0],
                                            }
                                          : {}
                                      }
                                      selectionCheck={(option, value) => option.value === value.value}
                                      onChangeCall={(selectedOption) => {
                                        handleFieldChange({
                                          time_modifications:
                                            userStateDetails.new_store_loc_data.new_opening_hours === false
                                              ? true
                                              : userStateDetails.new_store_loc_data.new_opening_hours,
                                          weekday_timings: {
                                            ...userStateDetails.new_store_loc_data.weekday_timings,
                                            [dayName]: [
                                              selectedOption.value,
                                              userStateDetails.new_store_loc_data.weekday_timings[dayName][1],
                                            ],
                                          },
                                        });
                                      }}
                                      fieldError={
                                        typeof fieldError[dayName] !== "undefined" && fieldError[dayName] !== ""
                                          ? fieldError[dayName]
                                          : ""
                                      }
                                    />
                                  </StyledLeftAlignedStack>
                                </LeftStyledTableCell>
                                {/* ------ closing time ------ */}
                                <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                                  <StyledLeftAlignedStack direction={"row"}>
                                    <SingleSelect
                                      enableFullWidth={true}
                                      label="Closing Time*"
                                      elemId={"closingWeekDay_" + dayName}
                                      readOnlyFlag={userStateDetails.new_store_loc_data.same_time_for_all}
                                      options={platformConfig.hour_values.map((hourVal) => {
                                        return { label: hourVal, value: hourVal };
                                      })}
                                      defaultVal={
                                        typeof userStateDetails.new_store_loc_data !== "undefined" &&
                                        typeof userStateDetails.new_store_loc_data.weekday_timings !== "undefined"
                                          ? {
                                              label: userStateDetails.new_store_loc_data.weekday_timings[dayName][1],
                                              value: userStateDetails.new_store_loc_data.weekday_timings[dayName][1],
                                            }
                                          : {}
                                      }
                                      selectionCheck={(option, value) => option.value === value.value}
                                      onChangeCall={(selectedOption) => {
                                        handleFieldChange({
                                          time_modifications:
                                            userStateDetails.new_store_loc_data.new_opening_hours === false
                                              ? true
                                              : userStateDetails.new_store_loc_data.new_opening_hours,
                                          weekday_timings: {
                                            ...userStateDetails.new_store_loc_data.weekday_timings,
                                            [dayName]: [
                                              userStateDetails.new_store_loc_data.weekday_timings[dayName][0],
                                              selectedOption.value,
                                            ],
                                          },
                                        });
                                      }}
                                      fieldError={
                                        typeof fieldError.store_new_opening_hour !== "undefined" &&
                                        fieldError.store_new_opening_hour !== ""
                                          ? fieldError.store_new_opening_hour
                                          : ""
                                      }
                                    />
                                  </StyledLeftAlignedStack>
                                </LeftStyledTableCell>
                              </>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    {typeof fieldError.week_day_selection !== "undefined" && fieldError.week_day_selection !== "" ? (
                      <ErrorMessageText message={fieldError.week_day_selection} />
                    ) : (
                      ""
                    )}
                  </Table>
                </TableContainer>
              )}
          </>
        )}

        {/* -------------- store infrastructure -------------- */}
        {["Both", "StoreInfrastructure"].includes(sectionToEdit) && (
          <>
            <Box height={20} />
            {usedFor === "Add" && (
              <>
                <Typography variant="h6">Store Infrastructure</Typography>
                <Box height={20} />
              </>
            )}
            {usedFor === "update" && (
              <>
                <Box>
                  <Stack direction={"row"} spacing={3}>
                    {/* ------ cyreen store id ------ */}
                    <Box>
                      <Stack direction={"row"} spacing={1}>
                        <StyledDarkRedInformationTypography>Cyreen Store ID :</StyledDarkRedInformationTypography>
                        <StyledBlackInformationTypography>
                          {userStateDetails?.store_overview ? (
                            <strong>{userStateDetails?.store_overview?.number_store_cyreen}</strong>
                          ) : (
                            <strong>__</strong>
                          )}
                        </StyledBlackInformationTypography>
                      </Stack>
                    </Box>
                    {/* ------- store ip --------- */}
                    <Box>
                      <Stack direction={"row"} spacing={1}>
                        <StyledDarkRedInformationTypography>Store IP Address :</StyledDarkRedInformationTypography>
                        <StyledBlackInformationTypography>
                          {userStateDetails?.db_store_ip ? (
                            <strong>
                              10.{userStateDetails?.db_store_ip?.second_octet}.{userStateDetails?.db_store_ip?.third_octet}
                              .0/24
                            </strong>
                          ) : (
                            <strong>__</strong>
                          )}
                        </StyledBlackInformationTypography>
                      </Stack>
                    </Box>
                    {/* ---------- store status ---------- */}
                    <Box>
                      <Stack direction={"row"} spacing={1}>
                        <StyledDarkRedInformationTypography>Store Status :</StyledDarkRedInformationTypography>
                        <StyledBlackInformationTypography>
                          <strong>
                            {typeof userStateDetails.new_store_loc_data !== "undefined" &&
                            typeof userStateDetails.new_store_loc_data.store_status !== "undefined"
                              ? userStateDetails.new_store_loc_data.store_status
                              : ""}{" "}
                          </strong>
                        </StyledBlackInformationTypography>
                        {/* ------- info tooltip for store status ------- */}
                        <HtmlTooltip
                          title={
                            <Box width={450}>
                              <Typography variant="body2">
                                Store Status is determined based on your Devices settings.
                              </Typography>
                              <Box height={5} />
                              <Typography variant="body2">
                                <Box>
                                  <strong>Store Active:</strong> Active Devices
                                  {" >"} 0
                                </Box>
                                <Box height={2} />
                                <Box>
                                  <strong>Store Inactive:</strong> Active Devices = 0
                                </Box>
                              </Typography>
                            </Box>
                          }
                        >
                          <Info sx={{ fontSize: "18px" }} />
                        </HtmlTooltip>
                      </Stack>
                    </Box>
                    {/* -------- data processing --------- */}
                    <Box>
                      <Stack direction={"row"} spacing={1}>
                        <StyledDarkRedInformationTypography>Data Processing :</StyledDarkRedInformationTypography>
                        <StyledBlackInformationTypography>
                          <strong>{userStateDetails?.store_overview?.store_status === 1 ? "Active" : "Inactive"}</strong>
                        </StyledBlackInformationTypography>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>

                <Box height={30} />
              </>
            )}

            <Stack direction={"row"} spacing={4}>
              {/* ------- Retailer Store Id -------- */}
              <NumericInputField
                fieldRequired={false}
                elemId="retailer_store_id"
                label={"Retailer Store ID"}
                fieldName="retailer_store_id"
                errorStyle="text"
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.retailer_store_id !== "undefined"
                    ? userStateDetails.new_store_loc_data.retailer_store_id
                    : ""
                }
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    retailer_store_id: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.retailer_store_id !== "undefined" && fieldError.retailer_store_id !== ""
                    ? fieldError.retailer_store_id
                    : ""
                }
                fieldInfo={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HtmlTooltip
                        title={
                          <Box width={250}>
                            <Typography variant="body2">
                              <strong>Retailer Store ID:</strong> Refers to the internal store ID assigned by the retailer
                              and is included in the receipt data. The Retailer Store ID is essential for processing this
                              store’s receipt data.
                            </Typography>
                          </Box>
                        }
                      >
                        <IconButton>
                          <Info />
                        </IconButton>
                      </HtmlTooltip>
                    </InputAdornment>
                  ),
                }}
              />
              {/* ------------ automated checkouts ------------- */}
              <SingleSelect
                enableFullWidth={true}
                label="Checkout Tracking*"
                elemId={"checkoutTracking"}
                readOnlyFlag={false}
                options={["Automated", "Manual/No"].map((trackVal) => {
                  return { label: trackVal, value: trackVal };
                })}
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.automated_checkout !== "undefined"
                    ? {
                        label: userStateDetails.new_store_loc_data.automated_checkout,
                        value: userStateDetails.new_store_loc_data.automated_checkout,
                      }
                    : { label: "Automated", value: "Automated" }
                }
                selectionCheck={(option, value) => option.value === value.value}
                onChangeCall={(selectedOption) => {
                  handleFieldChange({
                    automated_checkout: selectedOption.value,
                  });
                }}
                fieldError={
                  typeof fieldError.automated_checkout !== "undefined" && fieldError.automated_checkout !== ""
                    ? fieldError.automated_checkout
                    : ""
                }
                fieldInfo={
                  <InputAdornment position="end" sx={{ marginRight: "15px" }}>
                    <HtmlTooltip
                      title={
                        <Box width={250}>
                          <Typography variant="body2">
                            <strong>Automated:</strong> Shoppers are automatically recorded at the checkouts using C.A.P.
                            tracking technology. <br />
                            <strong>Manual/No:</strong> Shoppers are manually recorded at the checkouts by the store's
                            personnel or are not recorded at all.
                          </Typography>
                        </Box>
                      }
                    >
                      <IconButton>
                        <Info />
                      </IconButton>
                    </HtmlTooltip>
                  </InputAdornment>
                }
              />
            </Stack>
            <Box height={40} />
            <Stack direction={"row"} spacing={4}>
              {/* ------- Equipped trolleys -------- */}
              <NumericInputField
                fieldRequired={false}
                elemId="equipped_trolleys"
                label="Equipped Trolleys"
                fieldName="equipped_trolleys"
                errorStyle="text"
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.store_trolleys !== "undefined"
                    ? userStateDetails.new_store_loc_data.store_trolleys
                    : ""
                }
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    store_trolleys: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.store_trolleys !== "undefined" && fieldError.store_trolleys !== ""
                    ? fieldError.store_trolleys
                    : ""
                }
              />
              {/* ---------- equipped baskets */}
              <NumericInputField
                fieldRequired={false}
                elemId="equipped_baskets"
                label="Equipped Baskets"
                errorStyle="text"
                fieldName="equipped_baskets"
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.store_baskets !== "undefined"
                    ? userStateDetails.new_store_loc_data.store_baskets
                    : ""
                }
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    store_baskets: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.store_baskets !== "undefined" && fieldError.store_baskets !== ""
                    ? fieldError.store_baskets
                    : ""
                }
              />

              {/* ------ Store Size -------- */}
              <TextInputField
                fieldRequired={false}
                elemId="store_size"
                label="Store Size (in m&sup2;)"
                fieldName="store_size"
                showlabel={true}
                defaultVal={
                  typeof userStateDetails.new_store_loc_data !== "undefined" &&
                  typeof userStateDetails.new_store_loc_data.store_size !== "undefined"
                    ? userStateDetails.new_store_loc_data.store_size
                    : ""
                }
                inputType={"text"}
                onChangeCall={(e) => {
                  e.preventDefault();
                  handleFieldChange({
                    store_size: e.target.value,
                  });
                }}
                fieldError={
                  typeof fieldError.store_size !== "undefined" && fieldError.store_size !== "" ? fieldError.store_size : ""
                }
              />
            </Stack>
          </>
        )}
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            label="Cancel"
            icon={<Close />}
          />
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              // validate and save store location data
              setFieldError({});
              saveStoreLocData(usedFor).then((info) => {
                if (info.success === false) {
                  if (typeof info.errorMessages !== "undefined") {
                    setFieldError(info.errorMessages);
                  }
                } else {
                  if (usedFor === "update") {
                    reset_infrastructure_config_page_data(userStateDetails.new_store_loc_data.id);
                  } else {
                    fetchStoreLocations(); // refresh the locations list
                    navigate("/infrastructure/configure-location/" + info.store_id);
                  }
                  closeModal();
                }
              });
            }}
            label={usedFor === "update" ? "Update" : "Save"}
            icon={<Save />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default NewLocation;
