import React from "react";
import { Box, Stack } from "@mui/material";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import { Add, Save } from "@mui/icons-material";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import SimpleModal from "../../components/commonComponents/Modals/SimpleModal";
import AddRole from "./modalContent/AddRole";
import { saveRoleInformation } from "../../api_calls/userManagement";

const Roles = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalDetails, setModalDetails] = React.useState({});

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setModalDetails({
      title: "Add New Role",
      content: <AddRole />,
      confirmAction: (e) => {
        e.preventDefault();
        saveRoleInformation();
        closeModal();
      },
    });
    setIsModalOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* ========= sidebar ============ */}
      <SideNav />
      {/* ========== main component body =========== */}
      <StyledMainBox component="main">
        {/* <Box height={30} /> */}
        <h1>Roles and Permissions</h1>
        {/* ============ Action buttons =========== */}
        <Stack direction="row" spacing={2}>
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
            label={"New Role"}
            icon={<Add />}
          />
        </Stack>
        {/* ============== users listing ============ */}
        <Box height={30} />
      </StyledMainBox>
      {/* ============ modal popup for different actions ========== */}
      <SimpleModal
        open={isModalOpen}
        onClose={closeModal}
        title={modalDetails.title}
        content={modalDetails.content}
        confirmAction={modalDetails.confirmAction}
        confirmIcon={<Save />}
        confirmLabel={"Confirm"}
        size={"md"}
      />
    </Box>
  );
};

export default Roles;
