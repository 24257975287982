import * as React from "react";
import { filterSingleValue } from "../../../../api_calls/TableauVisuals";
import MultiSelect from "../../../../components/commonComponents/Selectors/MultiSelect";
import { useSelector } from "react-redux";
import { checkUserRole } from "../../../../api_calls/userManagement";

export default function AnalysisGroup({ vizId, multiSelect }) {
  const userStateData = useSelector((state) => state.userSelections);
  const [changesMade, setChangesMade] = React.useState(false);
  const [selectedAnalysisGroup, setAnalysisGroup] = React.useState([]);
  const [selectedAnalysisGroupName, setAnalysisGroupName] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const userRole = userStateData?.brand_report_view === true ? { user_role: "Brand" } : checkUserRole();
  let adlevelNames =
    typeof userStateData.adlevel_names !== "undefined" && userStateData.adlevel_names.length !== 0
      ? [
          ...userStateData.adlevel_names.map((adlevelInfo) => {
            return adlevelInfo.label;
          }),
        ].sort()
      : [];

  if (userRole.user_role === "Brand") {
    // check brand filter object to fetch stores for brand (selected by retailer)
    adlevelNames = userStateData?.brand_filters?.performance_filter?.analysis_group
      ? multiSelect
        ? [...userStateData.brand_filters.performance_filter.analysis_group].sort()
        : [...userStateData.brand_filters.performance_filter.analysis_group].sort().filter((item) => item !== "(All)")
      : [];
  }

  React.useEffect(() => {
    setAnalysisGroup(adlevelNames);

    if (adlevelNames.length !== 0) {
      setAnalysisGroupName(
        adlevelNames.map((groupName) => {
          return { value: groupName, label: groupName };
        })
      );
    }

    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, []);

  return dataLoaded ? (
    <MultiSelect
      enableFullWidth={true}
      label={"Analysis Groups"}
      options={adlevelNames.map((groupName) => {
        return { value: groupName, label: groupName };
      })}
      defaultVal={selectedAnalysisGroupName}
      selectionCheck={(option, value) => option.label === value.label}
      usedFor="AnalysisGroup"
      onChangeCall={(selectedOption) => {
        let userChoices = [];
        selectedOption.forEach((chosen) => {
          if (chosen.label !== "(All)") {
            userChoices.push(chosen.label);
          } else {
            userChoices.push([...new Set(adlevelNames)]);
          }
        });
        setAnalysisGroup(userChoices);
        setAnalysisGroupName(selectedOption);
        setChangesMade(true);
      }}
      onBlurCall={(e) => {
        if (selectedAnalysisGroup.length !== 0 && changesMade === true) {
          let adlevelSelections = selectedAnalysisGroup;
          filterSingleValue("name_adlevel", adlevelSelections, vizId);
          setChangesMade(false);
        }
      }}
    />
  ) : (
    <></>
  );
}
