// CommonModal.js
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack } from "@mui/material";
import ActionButton from "../Buttons/ActionButton";
import { Close } from "@mui/icons-material";
import CloseButton from "../Buttons/CloseButton";

const SimpleModal = ({ open, onClose, title, content, confirmLabel, confirmIcon, confirmAction, size }) => {
  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton onClick={onClose} label="Cancel" icon={<Close />} />
          <ActionButton onClick={confirmAction} label={confirmLabel} icon={confirmIcon} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleModal;
