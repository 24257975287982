import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { deleteMarketingActivities } from "../../../api_calls/marketingActivities";
import { deleteItems } from "../../../Store/actions";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import ConfirmationPopup from "../../../components/commonComponents/ConfirmationPopup";

const DeleteActivity = (props) => {
  const dispatchAction = useDispatch();
  const t = useCustomTranslation()

  // to reset the pre-selected data or any new information added by user (when popup is closed)
  const reset_data = () => {
    // reset in redux state also
    dispatchAction(deleteItems(["activity_ids_to_delete"]));
  };

  const deleteRecords = async () => {
    // delete the record
    deleteMarketingActivities().then((info) => {
      if (info.success === true) {
        toast("Marketing activity deleted successfully.", { type: "success" });
      } else {
        toast("Sorry, something went wrong.", { type: "error" });
      }
      reset_data();
      props.closePopup(false);
    });
    
  };

  return (
    <ConfirmationPopup
      showConfirmationPopup={props.showdeleteActivityPopup}
      confirmationText={t("activity_delete_confirmation_text")}
      closePopup={props.closePopup}
      confirmationActionCall={deleteRecords}
    />
  );
};

export default DeleteActivity;
