import React from "react";
// import { useSelector } from "react-redux";
import { initializeViz } from "../../../api_calls/TableauVisuals";
import { connectedApptoken } from "../../../api_calls/tableauApiRequests";
import { generateRandomNumber } from "../../../api_calls/utils";

const TableauDash = ({ vizUrl, vizId, applyFilter, filterConfig }) => {
  //generate connected app token to load the workbook
  React.useEffect(() => {
    connectedApptoken().then((info) => {
      if (info.success === true) {
        initializeViz(info.response.token, vizUrl, vizId);
      }
    });
  }, []);

  return (
    <tableau-viz id={vizId} toolbar="hidden">
      {/* ------- apply filters if needed -------- */}
      {typeof applyFilter !== "undefined" &&
        applyFilter === true &&
        Object.keys(filterConfig).map((filterKey) => {
          return (
            <viz-filter
              key={filterKey + "_" + generateRandomNumber(3)}
              field={filterConfig[filterKey].name}
              value={filterConfig[filterKey].values}
            />
          );
        })}
    </tableau-viz>
  );
};

export default TableauDash;
