import React from "react";
import { Box, Link, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import { useSelector } from "react-redux";
import {
  HtmlTooltip,
  IconContainer,
  IconText,
  StyledDarkRedInformationTypography,
  StyledStack,
} from "../../../components/commonComponents/StyledComponents/styled";
import ProgressBar from "../../../components/commonComponents/Progress/ProgressBar";
import { Store, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { loadPictures, resetPicturesData } from "../../../api_calls/pictureRequests";
import AddNewPicture from "../ModalContent/AddPicture";
import LoadMoreButton from "../../../components/commonComponents/LoadMore/LoadMoreButton";
import { loadMoreData } from "../../../api_calls/utils";
import { clearfilters } from "../../../api_calls/campaignRequests";
import ShowPicture from "../ModalContent/ShowPicture";

const PicturesListing = () => {
  const picturesDetails = useSelector((state) => state.userSelections);
  const [loadPicModal, setLoadPic] = React.useState(false);
  const navigate = useNavigate();

  let listItems =
    typeof picturesDetails.pics_list_filter_applied !== "undefined" && picturesDetails.pics_list_filter_applied === true
      ? picturesDetails.filtered_pictures_list.pictures_list
      : typeof picturesDetails.pictures_list !== "undefined"
      ? picturesDetails.pictures_list
      : [];
  let picturesListitems = listItems.length !== 0 ? listItems.slice(0, picturesDetails.initial_load_count_for_pictures) : [];

  const [showModal, setShowModal] = React.useState(false);
  const [picInfo, setpicInfo] = React.useState({});

  //initially only 10 results will be loaded, further 10 will be loaded on click of load more button
  const loadMore = () => {
    loadMoreData("initial_load_count_for_pictures");
  };

  React.useEffect(() => {
    loadPictures();
    clearfilters();
  }, []);

  return picturesListitems.length !== 0 ? (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          {/* ------ header row ------ */}
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="text-wrapper">
                #
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Name
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Progress
              </StyledTableCell>
              <StyledTableCell align="left" className="text-wrapper">
                Stores
              </StyledTableCell>
              {/* <StyledTableCell align="left" className="text-wrapper">
                Retailer
              </StyledTableCell> */}
              <StyledTableCell align="left" className="text-wrapper">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {picturesListitems.map((pic, index) => {
              return (
                <TableRow key={index + "_campaign_row"}>
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    {index + 1}
                  </StyledTableCell>
                  {/* --------- name ---------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper wrapLargeText">
                    <StyledStack direction={"row"}>
                      <Link
                        href="#"
                        underline="none"
                        title={pic.name}
                        sx={{ color: "#AF3241" }}
                        onClick={(e) => {
                          e.preventDefault();
                          setpicInfo(pic);
                          setLoadPic(true);
                        }}
                      >
                        {pic.name}
                      </Link>
                    </StyledStack>
                  </StyledTableCell>
                  {/* ---------- progress --------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <ProgressBar startDate={pic.start_date} endDate={pic.end_date} />
                  </StyledTableCell>

                  {/* ---------- stores ---------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <StyledStack direction={"row"}>
                      <HtmlTooltip
                        title={
                          pic.selected_store_options.length !== 0
                            ? pic.selected_store_options.map((store) => store.label).join(", ")
                            : "Stores"
                        }
                      >
                        <IconContainer>
                          <Store sx={{ color: "#AF3241" }} />
                          <IconText variant="body2"> {pic.stores} </IconText>
                        </IconContainer>
                      </HtmlTooltip>
                    </StyledStack>
                  </StyledTableCell>
                  {/* -------- Retailer --------- */}
                  {/* <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <StyledStack direction={"row"}>{pic.retailer}</StyledStack>
                  </StyledTableCell> */}
                  {/* ----------- action ---------- */}
                  <StyledTableCell component="td" scope="row" className="text-wrapper">
                    <StyledStack direction={"row"} spacing={2}>
                      <HtmlTooltip title="Edit">
                        <IconContainer>
                          <a
                            href="#editPic"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowModal(true);
                              setpicInfo(pic);
                            }}
                          >
                            <Edit sx={{ color: "#AF3241" }} />
                          </a>
                        </IconContainer>
                      </HtmlTooltip>
                    </StyledStack>
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* --------- record count info --------- */}
      <Box mt={2} id="infoText">
        <StyledDarkRedInformationTypography variant="body2">
          Showing {picturesListitems.length} out of {listItems.length} results.
        </StyledDarkRedInformationTypography>
      </Box>
      {/* ---------- Load more button ----------- */}
      <Box height={20} />
      {picturesListitems.length < listItems.length && <LoadMoreButton clickAction={loadMore} />}

      {/* -------- add products modal --------- */}
      {showModal === true && (
        <AddNewPicture
          open={showModal}
          onClose={() => {
            resetPicturesData();
            setShowModal(false);
          }}
          size={"md"}
          closeModal={() => {
            resetPicturesData();
            setShowModal(false);
          }}
          usedFor={"update"}
          pictureinfo={picInfo}
        />
      )}
      {/* ------ load picture ------ */}
      {loadPicModal === true && (
        <ShowPicture
          open={loadPicModal}
          onClose={() => {
            setLoadPic(false);
          }}
          size={"md"}
          picInfo={picInfo}
          closeModal={() => {
            setLoadPic(false);
          }}
        />
      )}
    </Box>
  ) : (
    <Box>
      {typeof picturesDetails.loading !== "undefined" && picturesDetails.loading === true ? (
        <p>Please wait while we are loading results...</p>
      ) : (
        <p>No pictures found</p>
      )}
    </Box>
  );
};

export default PicturesListing;
