import React from "react";
import { Box, FormControlLabel, Stack, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { StyledCheckbox, StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import { CSVLink } from "react-csv";
import UploadButton from "../../../components/commonComponents/FileUpload/UploadButton";
import { Close, Save } from "@mui/icons-material";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { validateAlphabets, validateNumbers } from "../../../api_calls/utils";
import { restructure_campaign_sheet_data } from "../../../api_calls/campaignSheet";
import { addNewProductAdlevels, fetchCampaign } from "../../../api_calls/campaignRequests";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { updateStateData } from "../../../Store/actions";
import CampaignSheetResults from "../listings/CampaignSheetResults";
import { useCustomTranslation } from "../../../hooks/useCustomTranslation";
import { useNavigate } from "react-router-dom";

const AddProducts = ({ open, onClose, size, usedFor, closeModal, campaignRecord }) => {
  const [manualAddition, setManualAddition] = React.useState(false);
  const [adlevelData, setAdlevelData] = React.useState({});
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldError, setFieldError] = React.useState({});
  const id = campaignRecord.campaign_id;
  const retailer = campaignRecord.retailer_company_name;
  const campaign_name = campaignRecord.campaign_name;
  const navigate = useNavigate();
  const t = useCustomTranslation();
  // handle file upload
  const handleFileUpload = (acceptedFile) => {
    if (acceptedFile.type !== "text/csv") {
      //show success notification
      toast("Please upload a csv file", { type: "error" });
    } else {
      Papa.parse(acceptedFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          //restructure the csv data to show filters using initial characters from description
          restructure_campaign_sheet_data(results.data, 10, id, retailer);
          //save to the redux
          dispatchAction(
            updateStateData({
              filter_applied: false,
            })
          );
        },
      });
    }
  };

  const csvHeaders = [
    { label: "description", key: "description" },
    { label: "gtin", key: "gtin" },
  ];

  const csvData = [
    { description: "Product 1", gtin: "123456781" },
    { description: "Product 2", gtin: "123456782" },
    { description: "Product 3", gtin: "123456783" },
    { description: "Product 4", gtin: "123456784" },
  ];

  const adlevelAccuracyObj = (adlevelData) => {
    let verifiedAdlevelNumber = {};
    let verifiedAdlevelName = {};
    let verifiedAdlevelGTINCombination = {};
    let productsObj = {};
    let gtinsObj = {};

    let adlevelNameKey = adlevelData.nameAdLevel.replaceAll(" ", "_");
    let adlevelDescUniq = adlevelData.description.replaceAll(" ", "_");
    let combinationKeyWithDesc =
      adlevelDescUniq + "-" + adlevelData.gtin + "-" + adlevelData.numberAdLevel + "-" + adlevelData.nameAdLevel;
    let combinationKeyWithoutDesc = adlevelData.gtin + "-" + adlevelData.numberAdLevel + "-" + adlevelData.nameAdLevel;
    const listItems = currentStateData?.created_adlevels;
    // const sortedData = sortAdlevel(true, listItems);
    let error = false;
    let errorMessages = {};
    if (listItems.length !== 0) {
      listItems.forEach((adlevelItem, index) => {
        let adlevelNameKey = adlevelItem.nameAdLevel.replaceAll(" ", "_");
        let adlevelDesc = adlevelItem.description.replaceAll(" ", "_");
        let combinationKeyWithDesc =
          adlevelDesc + "-" + adlevelItem.gtin + "-" + adlevelItem.numberAdLevel + "-" + adlevelItem.nameAdLevel;
        let combinationKeyWithoutDesc = adlevelItem.gtin + "-" + adlevelItem.numberAdLevel + "-" + adlevelItem.nameAdLevel;
        // Check if the combination of GTIN, numberAdLevel, and nameAdLevel already exists
        verifiedAdlevelGTINCombination[combinationKeyWithDesc] = true;
        verifiedAdlevelGTINCombination[combinationKeyWithoutDesc] = true;
        // adlevel item doesn't exist in the already verified items, so add a new record to verified variables
        verifiedAdlevelNumber = {
          ...verifiedAdlevelNumber,
          [adlevelItem.numberAdLevel]: adlevelItem.nameAdLevel,
        };
        verifiedAdlevelName = {
          ...verifiedAdlevelName,
          [adlevelNameKey]: adlevelItem.numberAdLevel,
        };
        // products unique array with gtin values
        if (typeof productsObj[adlevelDesc] === "undefined") {
          productsObj = {
            ...productsObj,
            [adlevelDesc]: adlevelItem.gtin,
          };
        }
        if (typeof gtinsObj[adlevelItem.gtin] === "undefined") {
          gtinsObj = {
            ...gtinsObj,
            [adlevelItem.gtin]: adlevelDesc,
          };
        }
      });
      // validate adlevel accuracy
      if (Object.keys(verifiedAdlevelNumber).length) {
        // Check if the combination of GTIN, numberAdLevel, and nameAdLevel already exists
        if (
          verifiedAdlevelGTINCombination[combinationKeyWithDesc] ||
          verifiedAdlevelGTINCombination[combinationKeyWithoutDesc]
        ) {
          error = true;
          errorMessages = {
            ...errorMessages,
            gtin:
              "Duplicate GTIN within analysis group " +
              adlevelData.numberAdLevel +
              ". Only allowed once within same analysis group",
          };
        }

        // verify duplicate product name with same gtin
        // if (Object.keys(productsObj).includes(adlevelDescUniq.toString())) {
        //   if (productsObj[adlevelDescUniq] !== adlevelData.gtin) {
        //     error = true;
        //     errorMessages = {
        //       ...errorMessages,
        //       desc: "A specific Article Description must not be associated with multiple GTINs",
        //     };
        //   }
        // }
        // verify duplicate gtin name with same prod
        // if (Object.keys(gtinsObj).includes(adlevelData.gtin.toString())) {
        //   if (gtinsObj[adlevelDescUniq] !== adlevelDescUniq) {
        //     error = true;
        //     errorMessages = {
        //       ...errorMessages,
        //       gtin: "A specific Article Description must not be associated with multiple GTINs",
        //     };
        //   }
        // }

        // check if the adlevel number is already verified
        if (Object.keys(verifiedAdlevelNumber).includes(adlevelData.numberAdLevel.toString())) {
          // check the accuracy of the adlevel number and name
          if (verifiedAdlevelNumber[adlevelData.numberAdLevel] !== adlevelData.nameAdLevel) {
            error = true;
            errorMessages = {
              ...errorMessages,
              nameAdLevel: "Analysis group name already exists for another analysis group. Choose another one",
            };
          }
        } else if (Object.keys(verifiedAdlevelName).includes(adlevelNameKey)) {
          // check the accuracy of the adlevel number and name
          if (verifiedAdlevelName[adlevelNameKey] !== adlevelData.numberAdLevel) {
            error = true;
            errorMessages = {
              ...errorMessages,
              numberAdLevel: "Analysis group name already exists for another analysis group. Choose another one",
            };
          }
        }
      }
    }

    return { error, errorMessages };
  };

  //save records with adlevel details to the redux state
  const saveNewproduct = async (e) => {
    e.preventDefault();
    let error = false;
    let error_message = {};
    setFieldError({
      ...fieldError,
      error: false,
    });

    //validate description = it must be alphabets
    // const validateDesc = validateAlphabets(typeof adlevelData.description !== "undefined" ? adlevelData.description : "");
    // if (validateDesc.error === true) {
    //   error = true;
    //   error_message = {
    //     ...error_message,
    //     desc: "Article description must not be empty and may not contain numbers",
    //   };
    // }
    if (
      typeof adlevelData.description === "undefined" ||
      (typeof adlevelData.description !== "undefined" && adlevelData.description === "")
    ) {
      error = true;
      error_message = {
        ...error_message,
        desc: "Article description must not be empty",
      };
    }
    //validate gtin = it must be number
    const validateGtin = validateNumbers(typeof adlevelData.gtin !== "undefined" ? adlevelData.gtin : "");
    if (validateGtin.error === true) {
      error = true;
      error_message = {
        ...error_message,
        gtin: "GTIN must not be empty and may not contain letters",
      };
    }
    //validate adlevel name =  only alphabets
    const validateNameAdlevel = validateAlphabets(
      typeof adlevelData.nameAdLevel !== "undefined" ? adlevelData.nameAdLevel : ""
    );
    if (validateNameAdlevel.error === true) {
      error = true;
      error_message = {
        ...error_message,
        nameAdLevel: "Analysis group name must not be empty and may not contain numbers",
      };
    }
    //validate adlevel number = must be number and not greater than 20
    const validateNumberAdlevel = validateNumbers(
      typeof adlevelData.numberAdLevel !== "undefined" ? adlevelData.numberAdLevel : ""
    );
    if (validateNumberAdlevel.error === true) {
      error = true;
      error_message = {
        ...error_message,
        numberAdLevel: "Analysis group number must not be empty and may not contain letters",
      };
    } else {
      if (adlevelData.numberAdLevel > 20) {
        error = true;
        error_message = {
          ...error_message,
          numberAdLevel: "Analysis group number can not be greater than 20",
        };
      }
    }

    // validate bundle size
    if (typeof adlevelData.bundleSize !== "undefined" && adlevelData.bundleSize !== "") {
      const validateBundleSize = validateNumbers(adlevelData.bundleSize);
      if (validateBundleSize.error === true) {
        error = true;
        error_message = {
          ...error_message,
          bundleSize: "Bundle size may not contain letters",
        };
      } else {
        if (parseInt(adlevelData.bundleSize) === 0) {
          error = true;
          error_message = {
            ...error_message,
            bundleSize: "Bundle size cannot be 0",
          };
        }
      }
    }

    if (error === false) {
      const checkAcurracyObj = adlevelAccuracyObj(adlevelData);

      if (checkAcurracyObj.error === true) {
        setFieldError({
          error: checkAcurracyObj.error,
          ...checkAcurracyObj.errorMessages,
        });
        toast("Please fill in valid information", { type: "error" });
      } else {
        const newAdlevelParams = [
          {
            ...adlevelData,
            nameCompany: retailer,
            campaign: id,
            bundleSize:
              typeof adlevelData.bundleSize !== "undefined" && adlevelData.bundleSize !== "" ? adlevelData.bundleSize : 1,
          },
        ];
        const addNewRecord = await addNewProductAdlevels(id, newAdlevelParams);

        if (addNewRecord.success === true) {
          // check if any error returned from the api
          if (
            typeof addNewRecord.api_response !== "undefined" &&
            typeof addNewRecord.api_response.response.success !== "undefined" &&
            addNewRecord.api_response.response.success === false
          ) {
            toast(addNewRecord.api_response.response.error_detail, { type: "error" });
          } else {
            closeModal();
            toast("Analysis group added successfully.", { type: "success" });
          }
        } else {
          toast("Sorry, something went wrong.", { type: "error" });
        }
      }
    } else {
      setFieldError({
        error: error,
        ...error_message,
      });
      toast("Please fill in valid information", { type: "error" });
    }
  };

  React.useEffect(() => {
    fetchCampaign(campaignRecord.campaign_id, navigate);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle> Upload your analytics products here</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            {!manualAddition && (
              <>
                <Stack direction={"row"} spacing={4}>
                  {/* ------- file upload -------- */}
                  <UploadButton
                    uploadLabel={"Upload Products"}
                    uploadButtonId={"productsFile"}
                    onChangeCall={handleFileUpload}
                    acceptedFileType={".csv, text/csv"}
                  />
                  {/* ---------------- download csv button ----------- */}
                  <CSVLink
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      color: "#AF3241",
                    }}
                    data={csvData}
                    headers={csvHeaders}
                    filename={"product_template.csv"}
                  >
                    <i className="bi bi-download" title="download csv" /> {t("download_template_button_text")}
                  </CSVLink>
                </Stack>
                <Box height={40} />
                {/* --------- show campaign sheet content along with checkboxes --------- */}
                {typeof currentStateData.campaign_sheet_records !== "undefined" &&
                  currentStateData.campaign_sheet_records.length !== 0 && (
                    <CampaignSheetResults
                      id={id}
                      retailer={retailer}
                      campaign_name={campaign_name}
                      closeModal={closeModal}
                    />
                  )}
              </>
            )}
            <Stack direction={"row"} spacing={4}>
              {/* -------- manual adlevel checkbox ---------- */}
              <FormControlLabel
                control={
                  <StyledCheckbox
                    checked={manualAddition}
                    onChange={(e) => {
                      setManualAddition(!manualAddition);
                    }}
                  />
                }
                label="Add Manually"
              />
            </Stack>
          </Box>
          {/* ---------- manual fields for product --------- */}
          {manualAddition && (
            <Box>
              <Stack direction={"row"} spacing={4}>
                {/* ------- article description -------- */}
                <TextInputField
                  fieldRequired={true}
                  elemId="articleDescription"
                  label="Article Description"
                  fieldName="article_description"
                  showlabel={true}
                  defaultVal={typeof adlevelData.description !== "undefined" ? adlevelData.description : ""}
                  inputType={"text"}
                  onChangeCall={(e) => {
                    setAdlevelData({
                      ...adlevelData,
                      description: e.target.value,
                    });
                  }}
                  fieldError={
                    typeof fieldError.error !== "undefined" &&
                    fieldError.error === true &&
                    typeof fieldError.desc !== "undefined"
                      ? fieldError.desc
                      : ""
                  }
                />
                {/* ------ gtin -------- */}
                <TextInputField
                  fieldRequired={true}
                  elemId="gtin"
                  label="GTIN"
                  fieldName="gtin"
                  defaultVal={typeof adlevelData.gtin !== "undefined" ? adlevelData.gtin : ""}
                  showlabel={true}
                  inputType={"text"}
                  onChangeCall={(e) => {
                    setAdlevelData({
                      ...adlevelData,
                      gtin: e.target.value,
                    });
                  }}
                  fieldError={
                    typeof fieldError.error !== "undefined" &&
                    fieldError.error === true &&
                    typeof fieldError.gtin !== "undefined"
                      ? fieldError.gtin
                      : ""
                  }
                />
              </Stack>
              <Box height={40} />
              <Stack direction={"row"} spacing={4}>
                {/* ------ adlevel number -------- */}
                <TextInputField
                  fieldRequired={true}
                  elemId="adlevelNumber"
                  label="Analysis Group Number"
                  fieldName="adlevel_number"
                  defaultVal={typeof adlevelData.numberAdLevel !== "undefined" ? adlevelData.numberAdLevel : ""}
                  showlabel={true}
                  inputType={"text"}
                  onChangeCall={(e) => {
                    setAdlevelData({
                      ...adlevelData,
                      numberAdLevel: e.target.value,
                    });
                  }}
                  fieldError={
                    typeof fieldError.error !== "undefined" &&
                    fieldError.error === true &&
                    typeof fieldError.numberAdLevel !== "undefined"
                      ? fieldError.numberAdLevel
                      : ""
                  }
                />
                {/* -------- adlevel name ------- */}
                <TextInputField
                  fieldRequired={true}
                  elemId="adlevelName"
                  label="Analysis Group Name"
                  fieldName="adlevel_name"
                  defaultVal={typeof adlevelData.nameAdLevel !== "undefined" ? adlevelData.nameAdLevel : ""}
                  showlabel={true}
                  inputType={"text"}
                  onChangeCall={(e) => {
                    setAdlevelData({
                      ...adlevelData,
                      nameAdLevel: e.target.value,
                    });
                  }}
                  fieldError={
                    typeof fieldError.error !== "undefined" &&
                    fieldError.error === true &&
                    typeof fieldError.nameAdLevel !== "undefined"
                      ? fieldError.nameAdLevel
                      : ""
                  }
                />
              </Stack>
              <Box height={40} />
              {/* ----------- bundle size ----------- */}
              <Stack direction={"row"} spacing={4}>
                <TextInputField
                  fieldRequired={false}
                  elemId="bundleSize"
                  label="Bundle Size"
                  fieldName="bundle_size"
                  defaultVal={typeof adlevelData.bundleSize !== "undefined" ? adlevelData.bundleSize : ""}
                  showlabel={true}
                  inputType={"text"}
                  onChangeCall={(e) => {
                    setAdlevelData({
                      ...adlevelData,
                      bundleSize: e.target.value,
                    });
                  }}
                  fieldError={
                    typeof fieldError.error !== "undefined" &&
                    fieldError.error === true &&
                    typeof fieldError.bundleSize !== "undefined"
                      ? fieldError.bundleSize
                      : ""
                  }
                />
              </Stack>
            </Box>
          )}
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            label="Cancel"
            icon={<Close />}
          />
          {/* ------- this save button is only used for manual product addition ------ */}
          {manualAddition && <ActionButton onClick={saveNewproduct} label={"Save"} icon={<Save />} />}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddProducts;
