import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import PropTypes from "prop-types";
import { AdsClick, BarChart, Discount, QueryStats, VerticalSplit, Visibility } from "@mui/icons-material";
import TotalPerformanceFilters from "./filters/TotalPerformanceFilters";
import SinglePerformanceFilters from "./filters/SinglePerformanceFilter";
import TargetingFilters from "./filters/TargetingFilters";
import MarketingFilters from "./filters/MarketingFilters";
import ImpressionsFilter from "./filters/ImpressionsFilter";
import store from "../../Store/store";
import { checkUserRole } from "../../api_calls/userManagement";

const preDefinedTabNames = ["TotalPerformance", "SinglePerformance", "Targeting", "MarketingActivities", "ABTesting"];
// to load tab content
function CustomTabPanel(props) {
  const { children, dasboardname, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={false}
      id={`simple-tabpanel-${dasboardname}`}
      aria-labelledby={`simple-tab-${dasboardname}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

// specify mandatory props that custom tab panel will accept
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  dasboardname: PropTypes.number.isRequired,
};

// to render the tableau dashboards
function dashboardMarkup(vizId, vizUrl, applyFilter, filterConfig) {
  return (
    <Grid container spacing={2}>
      <TableauDash vizId={vizId} vizUrl={vizUrl} applyFilter={applyFilter} filterConfig={filterConfig} />
    </Grid>
  );
}

function checkFiltersInformation(filterForTab, viewMode) {
  const currentStateData = store.getState().userSelections;
  const filterKeyword = viewMode === "Brand" ? "brand_filters" : "retailer_filters";

  const filtersObj = {
    TotalPerformance: {
      store: {
        name: "name_store",
        values: currentStateData[filterKeyword]?.performance_filter?.stores,
      },
      analysis_group: {
        name: "name_adlevel",
        values: currentStateData[filterKeyword]?.performance_filter?.analysis_group,
      },
      week: {
        name: "week",
        values: currentStateData[filterKeyword]?.performance_filter?.weeks,
      },
    },
    SinglePerformance: {
      analysis_group: {
        name: "name_adlevel",
        values:
          currentStateData[filterKeyword]?.performance_filter?.analysis_group.length !== 0
            ? fetchFirstAnalysisGroup(currentStateData[filterKeyword]?.performance_filter?.analysis_group)
            : "",
      },
      store: {
        name: "name_store",
        values: currentStateData[filterKeyword]?.performance_filter?.stores,
      },
      week: {
        name: "week",
        values: currentStateData[filterKeyword]?.performance_filter?.weeks,
      },
    },
    Targeting: {
      analysis_group: {
        name: "name_adlevel",
        values:
          currentStateData[filterKeyword]?.performance_filter?.analysis_group.length !== 0
            ? fetchFirstAnalysisGroup(currentStateData[filterKeyword]?.performance_filter?.analysis_group)
            : "",
      },
      rank_by: {
        name: "Targeting_Rank By Parameter",
        values: "No Ranking",
      },
      store: {
        name: "name_store",
        values: currentStateData[filterKeyword]?.performance_filter?.stores,
      },
      week: {
        name: "week",
        values: currentStateData[filterKeyword]?.performance_filter?.weeks,
      },
    },
    MarketingActivities: {
      analysis_group: {
        name: "name_adlevel",
        values:
          currentStateData[filterKeyword]?.performance_filter?.analysis_group.length !== 0
            ? fetchFirstAnalysisGroup(currentStateData[filterKeyword]?.performance_filter?.analysis_group)
            : "",
      },
      activity: {
        name: "Activity type",
        values:
          typeof currentStateData.activityOptions !== "undefined" && currentStateData.activityOptions.length !== 0
            ? currentStateData.activityOptions[0]
            : "",
      },
      store: {
        name: "name_store",
        values: currentStateData[filterKeyword]?.performance_filter?.stores,
      },
      week: {
        name: "week",
        values: currentStateData[filterKeyword]?.performance_filter?.weeks,
      },
    },
    ABTesting: {
      analysis_group: {
        name: "name_adlevel",
        values:
          currentStateData[filterKeyword]?.performance_filter?.analysis_group.length !== 0
            ? fetchFirstAnalysisGroup(currentStateData[filterKeyword]?.performance_filter?.analysis_group)
            : "",
      },
      store: {
        name: "name_store",
        values: currentStateData[filterKeyword]?.performance_filter?.stores,
      },
      week: {
        name: "week",
        values: currentStateData[filterKeyword]?.performance_filter?.weeks,
      },
    },
  };

  return preDefinedTabNames.includes(filterForTab) ? filtersObj[filterForTab] : {};
}

function checkFiltersComponent(filterForTab, vizId) {
  const filtersObj = {
    TotalPerformance: <TotalPerformanceFilters vizId={vizId} />,
    SinglePerformance: <SinglePerformanceFilters vizId={vizId} />,
    Targeting: <TargetingFilters vizId={vizId} />,
    MarketingActivities: <MarketingFilters vizId={vizId} />,
    ABTesting: <SinglePerformanceFilters vizId={vizId} />,
  };

  return filtersObj[filterForTab];
}

function checkTabIcon(filterForTab) {
  const filtersObj = {
    TotalPerformance: <BarChart />,
    SinglePerformance: <QueryStats />,
    Targeting: <AdsClick />,
    MarketingActivities: <Discount />,
    ABTesting: <VerticalSplit />,
  };

  return filtersObj[filterForTab];
}

function fetchFirstAnalysisGroup(analysisGroups) {
  const filterGroups =
    typeof analysisGroups !== "undefined" && analysisGroups.length !== 0
      ? [...analysisGroups].sort().filter((item) => item !== "(All)")
      : [];
  return filterGroups.length !== 0 ? filterGroups[0] : "";
}

export const campaign_analytics_tabs_config_test = (viewMode) => {
  const currentStateData = store.getState().userSelections;

  let tabs = [];

  if (typeof currentStateData.campaign_detail !== "undefined") {
    const dirName =
      currentStateData.campaign_detail.retailer_company_id + "_" + currentStateData.campaign_detail.campaign_id;
    const filterKeyword = viewMode === "Brand" ? "brand_filters" : "retailer_filters";
    // add impressions dashboard
    if (currentStateData.campaign_detail.impression_report_status === 1) {
      tabs.push({
        label: "Impressions",
        icon: <Visibility />,
        unique_key: "impressions_1",
        dashboard: dashboardMarkup(
          "impressionsDash",
          "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "_impressions_report" + "/Impressions",
          true,
          {
            store: {
              name: "name_store",
              values: currentStateData[filterKeyword]?.impression_filter?.stores,
            },
            // date_range: {
            //   name: "DAY(Timestamp)",
            //   values: [
            //     currentStateData.campaign_detail.campaign_start_date,
            //     currentStateData.campaign_detail.campaign_end_date,
            //   ],
            // },
          }
        ),
        filter_component: <ImpressionsFilter vizId="impressionsDash" />,
      });
    }
    // add performance dashboards
    if (currentStateData.campaign_detail.campaign_status_code === 4) {
      tabs.push(
        // {
        //   label: "Total Performance",
        //   icon: <BarChart />,
        //   unique_key: "totalPerformance_1",
        //   dashboard: dashboardMarkup(
        //     "totalPerformanceDash",
        //     "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
        //       dirName +
        //       "_performance_report" +
        //       "/TotalPerformance",
        //     true,
        //     {
        //       store: {
        //         name: "name_store",
        //         values: currentStateData[filterKeyword]?.performance_filter?.stores,
        //       },
        //       analysis_group: {
        //         name: "name_adlevel",
        //         values: currentStateData[filterKeyword]?.performance_filter?.analysis_group,
        //       },
        //       week: {
        //         name: "week",
        //         values: currentStateData[filterKeyword]?.performance_filter?.weeks,
        //       },
        //     }
        //   ),
        //   filter_component: <TotalPerformanceFilters vizId={"totalPerformanceDash"} />,
        // },
        {
          label: "Performance",
          icon: <QueryStats />,
          unique_key: "singlePerformance_1",
          dashboard: dashboardMarkup(
            "singlePerformanceDash",
            "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
              dirName +
              "_performance_report" +
              "/SinglePerformance",
            true,
            {
              analysis_group: {
                name: "name_adlevel",
                values:
                  currentStateData[filterKeyword]?.performance_filter?.analysis_group.length !== 0
                    ? fetchFirstAnalysisGroup(currentStateData[filterKeyword]?.performance_filter?.analysis_group)
                    : "",
              },
              store: {
                name: "name_store",
                values: currentStateData[filterKeyword]?.performance_filter?.stores,
              },
              week: {
                name: "week",
                values: currentStateData[filterKeyword]?.performance_filter?.weeks,
              },
            }
          ),
          filter_component: <SinglePerformanceFilters vizId={"singlePerformanceDash"} />,
        },
        {
          label: "Optimization",
          icon: <AdsClick />,
          unique_key: "targeting_1",
          dashboard: dashboardMarkup(
            "targetingDash",
            "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "_performance_report" + "/Targeting",
            true,
            {
              analysis_group: {
                name: "name_adlevel",
                values:
                  currentStateData[filterKeyword]?.performance_filter?.analysis_group.length !== 0
                    ? fetchFirstAnalysisGroup(currentStateData[filterKeyword]?.performance_filter?.analysis_group)
                    : "",
              },
              rank_by: {
                name: "Targeting_Rank By Parameter",
                values: "Absolute Difference (in pp)",
              },
              store: {
                name: "name_store",
                values: currentStateData[filterKeyword]?.performance_filter?.stores,
              },
              week: {
                name: "week",
                values: currentStateData[filterKeyword]?.performance_filter?.weeks,
              },
            }
          ),
          filter_component: <TargetingFilters vizId={"targetingDash"} />,
        }
        // {
        //   label: "Marketing Activities",
        //   icon: <Discount />,
        //   unique_key: "marketingActivities_1",
        //   dashboard: dashboardMarkup(
        //     "marketingActivityDash",
        //     "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
        //       dirName +
        //       "_performance_report" +
        //       "/MarketingActivities",
        //     true,
        //     {
        //       analysis_group: {
        //         name: "name_adlevel",
        //         values:
        //           currentStateData[filterKeyword]?.performance_filter?.analysis_group.length !== 0
        //             ? fetchFirstAnalysisGroup(currentStateData[filterKeyword]?.performance_filter?.analysis_group)
        //             : "",
        //       },
        //       store: {
        //         name: "name_store",
        //         values: currentStateData[filterKeyword]?.performance_filter?.stores,
        //       },
        //       week: {
        //         name: "week",
        //         values: currentStateData[filterKeyword]?.performance_filter?.weeks,
        //       },
        //     }
        //   ),
        //   filter_component: <MarketingFilters vizId={"marketingActivityDash"} />,
        // },
        // {
        //   label: "A/B Testing",
        //   icon: <VerticalSplit />,
        //   unique_key: "abTest_1",
        //   dashboard: dashboardMarkup(
        //     "abTestCampaignDash",
        //     "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "_performance_report" + "/ABTesting",
        //     true,
        //     {
        //       analysis_group: {
        //         name: "name_adlevel",
        //         values:
        //           currentStateData[filterKeyword]?.performance_filter?.analysis_group.length !== 0
        //             ? fetchFirstAnalysisGroup(currentStateData[filterKeyword]?.performance_filter?.analysis_group)
        //             : "",
        //       },
        //       store: {
        //         name: "name_store",
        //         values: currentStateData[filterKeyword]?.performance_filter?.stores,
        //       },
        //       week: {
        //         name: "week",
        //         values: currentStateData[filterKeyword]?.performance_filter?.weeks,
        //       },
        //     }
        //   ),
        //   filter_component: <SinglePerformanceFilters vizId={"abTestCampaignDash"} />,
        // }
      );
    }
  }

  return tabs;
};

export const campaign_analytics_tabs_config = (viewMode) => {
  const currentStateData = store.getState().userSelections;

  let tabs = [];

  if (typeof currentStateData.campaign_detail !== "undefined") {
    const dirName =
      currentStateData.campaign_detail.retailer_company_id + "_" + currentStateData.campaign_detail.campaign_id;
    const filterKeyword = viewMode === "Brand" ? "brand_filters" : "retailer_filters";
    // add impressions dashboard
    if (currentStateData.campaign_detail.impression_report_status === 1) {
      tabs.push({
        label: "Impressions",
        icon: <Visibility />,
        unique_key: "impressions_1",
        dashboard: dashboardMarkup(
          "impressionsDash",
          "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "_impressions_report" + "/Impressions",
          true,
          {
            store: {
              name: "name_store",
              values: currentStateData[filterKeyword]?.impression_filter?.stores,
            },
            // metrics: {
            //   name: "Metric Swap",
            //   values: "Absolute",
            // },
            // date_range: {
            //   name: "DAY(Timestamp)",
            //   values: [
            //     currentStateData.campaign_detail.campaign_start_date,
            //     currentStateData.campaign_detail.campaign_end_date,
            //   ],
            // },
          }
        ),
        filter_component: <ImpressionsFilter vizId="impressionsDash" />,
      });
    }
    // add performance dashboards
    if (currentStateData.campaign_detail.campaign_status_code === 4) {
      currentStateData.campaign_detail.campaign_analytics_config.forEach((tabConfig) => {
        const vizId = tabConfig.tab_text.replace(" ", "_") + "_dash";
        const applyFilter = preDefinedTabNames.includes(tabConfig.tab_name) ? true : false;
        tabs.push({
          label: tabConfig.tab_text,
          icon: checkTabIcon(tabConfig.tab_name),
          unique_key: tabConfig.tab_text.replace(" ", "_"),
          dashboard: dashboardMarkup(
            vizId,
            "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
              dirName +
              "_performance_report" +
              "/" +
              tabConfig.tab_name,
            applyFilter,
            checkFiltersInformation(tabConfig.tab_name, viewMode)
          ),
          filter_component: applyFilter === true ? checkFiltersComponent(tabConfig.tab_name, vizId) : <></>,
        });
      });
    }
  }

  return tabs;
};
