import { TailSpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { StyledMainBox } from "./StyledComponents/styled";
import { Backdrop, CircularProgress } from "@mui/material";

const Loader = () => {
  const appState = useSelector((state) => state.userSelections);
  return (
    <>
      {typeof appState.loading !== "undefined" && appState.loading === true ? (
        <StyledMainBox component="main">
          {/* ---------- loader code -------- */}
          <Backdrop sx={{ color: "#fff", zIndex: "9999" }} open={appState.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* <TailSpin
            height="80"
            width="80"
            color="#2D2D37"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            wrapperClass=""
            visible={true}
          /> */}
        </StyledMainBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default Loader;
