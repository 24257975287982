import React from "react";
import { Box, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";

const StoreConfirmation = (props) => {
  const performAction = async (e) => {
    e.preventDefault();
    // check if user typed yes or not
    props.confirmationActionCall();
    props.closePopup();
  };

  return (
    <Dialog
      open={props.showConfirmationPopup}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body2">
            Your current store status is Inactive and you activated a device for this store location.
            This change will change the store status to Active.
          </Typography>
          <Box height={20} />
          <Typography variant="body2">
            <strong>Do you want to proceed and save your changes?</strong>
          </Typography>
        </Box>
      </DialogContent>
      <Box height={2} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              props.closePopup();
            }}
            label={"No"}
            icon={<Close />}
          />
          <ActionButton onClick={performAction} label={"Yes"} icon={<Check />} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default StoreConfirmation;
