import React from "react";
import { Box, FormControlLabel, Stack } from "@mui/material";
import { StyledCheckbox, StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";

const AddNewContact = () => {
  return (
    <Box sx={{ display: "flex" }}>
      {/* ========== main component body =========== */}
      <StyledMainBox component="main">
        <Box>
          <Stack direction={"row"} spacing={4}>
            {/* ------- contact name -------- */}
            <TextInputField
              fieldRequired={true}
              elemId="contactName"
              label="Contact Name"
              fieldName="contact_name"
              showlabel={true}
              defaultVal=""
              inputType={"text"}
              onChangeCall={(e) => {
                e.preventDefault();
              }}
            />
            {/* ------ company name -------- */}
            <TextInputField
              fieldRequired={true}
              elemId="companyType"
              label="Company"
              fieldName="company_type"
              defaultVal=""
              showlabel={true}
              inputType={"text"}
              onChangeCall={(e) => {
                e.preventDefault();
              }}
            />
          </Stack>
          <Box height={40} />
          <Stack direction={"row"} spacing={4}>
            {/* ------- brand -------- */}
            <TextInputField
              fieldRequired={true}
              elemId="brand"
              label="Brand"
              fieldName="brand"
              showlabel={true}
              defaultVal=""
              inputType={"text"}
              onChangeCall={(e) => {
                e.preventDefault();
              }}
            />
            {/* --------- email ------------ */}
            <TextInputField
              fieldRequired={true}
              elemId="email"
              label="Email"
              fieldName="email"
              showlabel={true}
              defaultVal=""
              inputType={"text"}
              onChangeCall={(e) => {
                e.preventDefault();
              }}
            />
          </Stack>
          <Box height={40} />
          <Stack direction={"row"} spacing={4}>
            <FormControlLabel control={<StyledCheckbox />} label="Active" />
          </Stack>
        </Box>
      </StyledMainBox>
    </Box>
  );
};

export default AddNewContact;
