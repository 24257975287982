import React from "react";
import { filterSingleValue } from "../../../api_calls/TableauVisuals";
import SelectBox from "../Selectors/SelectBox";
import { loadStores } from "../../../api_calls/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { dbFormatDate, formatDate, storeListOptionsForTableauFilter } from "../../../api_calls/utils";
import { updateStateData } from "../../../Store/actions";
import { checkUserRole } from "../../../api_calls/userManagement";
import MultiSelect from "../Selectors/MultiSelect";
import { filterClips } from "../../../api_calls/clipRequests";
import { loadClips, resetClipsData } from "../../../api_calls/clipRequests";
import { loadCampaigns } from "../../../api_calls/campaignRequests";
import { clearfilters } from "../../../api_calls/campaignRequests";
import { erase_duplicates, formatToStandardDate, standardFormatToday, getFirstDayTwoMonthsAgo } from "../../../api_calls/utils";


const CampaignNameFilter = ({ vizId, fieldName, usedFor }) => {
    const campaignDetails = useSelector((state) => state.userSelections);
    const [changesMade, setChangesMade] = React.useState(false);
    const [campaignSelection, setCampaignSelection] = React.useState([]);
    const [campaignNameOptionSelected, setCampaignNameOptionsSelected] = React.useState([]);

    const firstDayTwoMonthsAgo = formatToStandardDate(getFirstDayTwoMonthsAgo());
    firstDayTwoMonthsAgo.setDate(firstDayTwoMonthsAgo.getDate() + 1);
    const today = standardFormatToday();
    const yesterday = formatToStandardDate(today);
    yesterday.setDate(today.getDate() - 1);


    const campaign_names_in_range = () => {
        var campaign_name_obj = [{}];
        
        campaignDetails.campaign_list.campaign_name_obj.forEach(campaign_name => {
            campaignDetails.campaign_list.all_campaigns.forEach(element => {
                if (element.campaign_name === campaign_name.label) {
                    if(formatToStandardDate(element.campaign_end_date) > firstDayTwoMonthsAgo){
                        campaign_name_obj.push(campaign_name);
                    }
                }
            });
        });
        return erase_duplicates(campaign_name_obj);
    }

    return (
        <MultiSelect
            enableFullWidth={false}
            label={"Campaign Name"}
            options={campaign_names_in_range()}
            defaultVal={campaignNameOptionSelected}
            selectionCheck={(option, value) => option.label === value.label}
            usedFor="campaignNameFilter"
            onChangeCall={(selectedOption) => {
                let userChoices = [];
                setChangesMade(true);
                selectedOption.forEach((chosen) => {
                    if (chosen.label !== "(All)") {
                        userChoices.push(chosen.label);
                    } else {
                        userChoices.push([...new Set(campaignDetails.campaign_list.campaign_name_obj)]);
                    }
                });
                setCampaignSelection(userChoices);
                setCampaignNameOptionsSelected(selectedOption);
                // filterSingleValue(fieldName, userChoices, vizId);
            }}
            onBlurCall={(e) => {
                if (campaignSelection.length !== 0 && changesMade === true) {
                    filterSingleValue(fieldName, campaignSelection, vizId);
                    setChangesMade(false);
                }
            }}
        />
    );
}

export default CampaignNameFilter;