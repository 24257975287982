import * as React from "react";
import { CustomTextInput } from "./style";
import DatePicker from "react-datepicker";
import { dbFormatDate, formatDate, formatToStandardDate } from "../../../api_calls/utils";

export default function DateInputField({
  label,
  elemId,
  fieldName,
  defaultVal,
  fieldRequired,
  onChangeCall,
  fieldError,
  minSelectable,
  maxSelectable,
  enabledDates,
  disabled,
}) {
  const [selectedDate, setSelectedDate] = React.useState();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // const reFormatDate = formatDate(date);
    onChangeCall(date);
  };

  const handleFieldChange = (e) => {
    handleDateChange(e.target.value);
  };

  React.useEffect(() => {
    const checkDefaultVal = defaultVal ? defaultVal : null;
    setSelectedDate(checkDefaultVal);
  }, [defaultVal]);
  return (
    <DatePicker
      onChange={handleDateChange}
      // showIcon
      calendarStartDay={1}
      filterDate={enabledDates}
      minDate={minSelectable}
      maxDate={maxSelectable}
      dateFormat="dd/MM/yyyy"
      disabled={disabled}
      selected={selectedDate}
      showWeekNumbers
      customInput={
        <CustomTextInput
          margin="normal"
          required={fieldRequired}
          fullWidth
          id={elemId}
          label={label}
          value={selectedDate ? selectedDate : ""}
          name={fieldName}
          // onChange={handleFieldChange}
          variant="standard"
          autoFocus={false}
          autoComplete="off"
          type={"text"}
          error={fieldError !== "" ? true : false}
          helperText={fieldError !== "" ? fieldError : ""}
          // InputLabelProps={{
          //   shrink: true, // This is important for date fields
          // }}
        />
      }
    />
  );
}
