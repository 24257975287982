import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import { sendApiRequest } from "./requestConfig";
import { validateAlphabets, validateEmpty } from "./utils";
import { loadCampaigns } from "./campaignRequests";

// to check the available report sections wrt workspace and also the selected settings for a particular campaign
export const fetchReportSettings = async (campaignId, defaultSettingDeviation) => {
  // call api
  const fetchSettings = await sendApiRequest("report-setting/" + campaignId, "", "GET");
  //   evaluate api response
  if (typeof fetchSettings.success !== "undefined" && fetchSettings.success === true) {
    let availableOptions = [];
    let visibleOptions = [];
    let tabNameArray = {};
    let settingDeviations = fetchSettings.response.changed;

    if (
      typeof fetchSettings.response !== "undefined" &&
      typeof fetchSettings.response.campaign_settings !== "undefined" &&
      fetchSettings.response.campaign_settings !== null
    ) {
      fetchSettings.response.campaign_settings.forEach((campaignSetting) => {
        if (campaignSetting.tab_visible === true) {
          visibleOptions.push(campaignSetting.tab_text);
        }
        availableOptions.push(campaignSetting);
        tabNameArray = { ...tabNameArray, [campaignSetting.tab_text]: campaignSetting.tab_name };
      });
    } else {
      if (
        typeof fetchSettings.response !== "undefined" &&
        typeof fetchSettings.response.workspace_settings !== "undefined" &&
        fetchSettings.response.workspace_settings !== null
      ) {
        fetchSettings.response.workspace_settings.forEach((campaignSetting) => {
          if (campaignSetting.tab_visible === true) {
            visibleOptions.push(campaignSetting.tab_text);
          }
          availableOptions.push(campaignSetting);
          tabNameArray = { ...tabNameArray, [campaignSetting.tab_text]: campaignSetting.tab_name };
        });
      }
    }

    store.dispatch(
      updateStateData({
        report_settings: {
          available_options: availableOptions,
          visible_options: visibleOptions,
          tab_name_arr: tabNameArray,
          settingDeviations: typeof defaultSettingDeviation !== "undefined" ? defaultSettingDeviation : settingDeviations,
          workspace_options: fetchSettings.response.workspace_settings,
          campaign_settings_saved:
            typeof fetchSettings.response !== "undefined" &&
            typeof fetchSettings.response.campaign_settings !== "undefined" &&
            fetchSettings.response.campaign_settings !== null
              ? true
              : false,
        },
      })
    );
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
};

// to save visible options for campaign analytics report.
export const saveVisibleSectionsForCampaign = async (campaignId) => {
  const userStateData = store.getState().userSelections;
  let visibleSectionsRequest = [];

  userStateData.report_settings.visible_options.forEach((visibleOpt, index) => {
    visibleSectionsRequest.push({
      tab_name: userStateData.report_settings.tab_name_arr[visibleOpt],
      tab_text: visibleOpt,
      tab_visible: true,
      tab_position: index + 1,
    });
  });

  let optionCounter = 1;
  userStateData.report_settings.available_options.forEach((Opt) => {
    if (!userStateData.report_settings.visible_options.includes(Opt.tab_text)) {
      visibleSectionsRequest.push({
        tab_name: Opt.tab_name,
        tab_text: Opt.tab_text,
        tab_visible: false,
        tab_position: optionCounter + parseInt(userStateData.report_settings.visible_options.length),
      });
      optionCounter++;
    }
  });

  let requestBody = { sections: visibleSectionsRequest };

  if (typeof userStateData.report_settings.campaign_status !== "undefined") {
    requestBody = {
      ...requestBody,
      campaign_status: userStateData.report_settings.campaign_status,
    };
  }

  if (typeof userStateData.report_settings.marked_as_default !== "undefined") {
    requestBody = {
      ...requestBody,
      marked_as_default: userStateData.report_settings.marked_as_default,
    };
  }

  // call api
  const saveSettings = await sendApiRequest("report-setting/" + campaignId + "/save", requestBody, "POST");
  //   evaluate api response
  if (typeof saveSettings.success !== "undefined" && saveSettings.success === true) {
    if (typeof userStateData.report_settings.campaign_status !== "undefined") {
      loadCampaigns(); //campaign will only need to reload when the status is updated.
    }
    resetReportSettings();
    toast("Report settings saved successfully", { type: "success" });
    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
    return {
      success: false,
    };
  }
};

// to clear the options selected in visible list
export const clearSectionsList = () => {
  const userStateData = store.getState().userSelections;
  let tabNameArray = {};
  userStateData.report_settings.workspace_options.forEach((workSpaceOpt) => {
    tabNameArray = { ...tabNameArray, [workSpaceOpt.tab_text]: workSpaceOpt.tab_name };
  });
  store.dispatch(
    updateStateData({
      report_settings: {
        ...userStateData.report_settings,
        visible_options: [],
        available_options: userStateData.report_settings.workspace_options,
        tab_name_arr: tabNameArray,
      },
    })
  );
};

// reset report settings params in redux
export const resetReportSettings = () => {
  store.dispatch(deleteItems(["report_settings"]));
};

export const resetNewSectionDetails = () => {
  store.dispatch(deleteItems(["new_report_section"]));
};

export const resetEditSectionDetails = () => {
  store.dispatch(deleteItems(["edit_report_section"]));
};

// to save new section for the reports
export const saveNewSectionForCampaignReports = async (campaignId) => {
  const userStateData = store.getState().userSelections;

  // call api
  const saveSection = await sendApiRequest(
    "report-setting/add-section",
    { ...userStateData.new_report_section, tab_name: userStateData.new_report_section.tab_name.replace(/\s+/g, "") },
    "POST"
  );
  //   evaluate api response
  if (typeof saveSection.success !== "undefined" && saveSection.success === true) {
    resetNewSectionDetails();
    await fetchReportSettings(campaignId, false);
    toast("New section saved successfully", { type: "success" });
    return {
      success: true,
    };
  } else {
    toast("Sorry, something went wrong.", { type: "error" });
    return {
      success: false,
    };
  }
};

export const validateReportSections = () => {
  const userStateData = store.getState().userSelections;
  const sectionsData = userStateData.edit_report_section;
  let error = false;
  let errorDetails = {};

  sectionsData.forEach((section, index) => {
    const validateTabName = validateEmpty(section.tab_name);
    const validateTabText = validateAlphabets(section.tab_text, true, 25, true);
    if (validateTabName.error === true) {
      error = true;
      errorDetails = {
        ...errorDetails,
        error: true,
        ["error_" + index]: {
          ...errorDetails["error_" + index],
          tab_name: "Dashboard name must not be empty",
        },
      };
    }
    if (validateTabText.error === true) {
      error = true;
      errorDetails = {
        ...errorDetails,
        error: true,
        ["error_" + index]: {
          ...errorDetails["error_" + index],
          tab_text: "Section name must not be empty, may not contain numbers, and can not exceed 25 characters",
        },
      };
    }
  });

  return {
    error,
    errorDetails,
  };
};

// to update the section names
export const updateSectionsForCampaignReports = async (campaignId) => {
  const userStateData = store.getState().userSelections;
  const validateSectionData = validateReportSections();

  if (validateSectionData.error === true) {
    return {
      success: false,
      error_details: validateSectionData.errorDetails,
    };
  } else {
    // call api
    const saveSettings = await sendApiRequest(
      "report-setting/edit-section",
      {
        sections: userStateData.edit_report_section.map((reportSec) => {
          return {
            ...reportSec,
            tab_name: reportSec.tab_name.replace(/\s+/g, ""),
          };
        }),
      },
      "POST"
    );
    //   evaluate api response
    if (typeof saveSettings.success !== "undefined" && saveSettings.success === true) {
      resetEditSectionDetails();
      await fetchReportSettings(campaignId, false);
      toast("Sections updated successfully", { type: "success" });
      return {
        success: true,
      };
    } else {
      toast("Sorry, something went wrong.", { type: "error" });
      return {
        success: false,
      };
    }
  }
};
