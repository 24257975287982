import React from "react";
import { Box, Tab, Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { StyledTableCell } from "../../../../components/commonComponents/TabularLists/styles";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledMainBox,
  StyledNestedTabs,
  StyledTabs,
} from "../../../../components/commonComponents/StyledComponents/styled";
import CheckoutLayout from "./CheckoutLayout";
import CheckoutMapping from "./CheckoutMapping";
import Confirmation from "../../ModalContent/Confirmation";
import { discardChanges, trackChanges } from "../../../../api_calls/infrastructure";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

// specify mandatory props that custom tab panel will accept
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `checkout-tab-${index}`,
    "aria-controls": `checkout-tabpanel-${index}`,
  };
}

const Checkout = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const [nestedValue, setNestedValue] = React.useState(0);
  const dispatchAction = useDispatch();
  const [valueToSetAfterConfirmation, setValueToSetAfterConfirmation] = React.useState(0);
  const [showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);
  const { id } = useParams();
  let tabs = [
    {
      label: "Checkout Layout",
      // icon: <Visibility />,
      unique_key: "layout_1",
      dashboard: <CheckoutLayout />,
      filter_component: <></>,
    },
  ];

  if (
    typeof userStateData.store_overview !== "undefined" &&
    typeof userStateData.store_overview.automated !== "undefined" &&
    userStateData.store_overview.automated === true
  ) {
    tabs = [
      ...tabs,
      {
        label: "Checkout Mapping",
        // icon: <Devices />,
        unique_key: "mapping_1",
        dashboard: <CheckoutMapping />,
        filter_component: <></>,
      },
    ];
  }

  const handleChange = (event, newValue) => {
    const showTabChangeConfirmation =
      typeof userStateData.track_infra_changes !== "undefined" ? userStateData.track_infra_changes : false;
    if (showTabChangeConfirmation === true) {
      setValueToSetAfterConfirmation(newValue);
      setShowCancelConfirmation(true);
    } else {
      setNestedValue(newValue);
    }
  };

  return (
    <>
      <Box height={20} />
      <StyledNestedTabs value={nestedValue} onChange={handleChange} aria-label="brand analytics" textColor={"#F04B55"}>
        {tabs.map((tabDetail) => {
          return <Tab key={tabDetail.unique_key} label={tabDetail.label} {...a11yProps(0)} />;
        })}
      </StyledNestedTabs>

      {/* ============== tab contents =========== */}
      {tabs.map((tabDetail, index) => {
        return (
          <CustomTabPanel value={nestedValue} index={index}>
            {tabDetail.dashboard}
          </CustomTabPanel>
        );
      })}

      {/* ----------- track changes confirmation ----------- */}
      {showCancelConfirmation && (
        <Confirmation
          showConfirmationPopup={showCancelConfirmation}
          closePopup={(e) => {
            setShowCancelConfirmation(false);
          }}
          confirmationActionCall={() => {
            discardChanges(id);
            trackChanges(false);
            setNestedValue(valueToSetAfterConfirmation);
          }}
          confirmationMessage="Are you certain you want to discard all the changes you have made?"
        />
      )}
    </>
  );
};

export default Checkout;
