import React from "react";
import { Box, Stack } from "@mui/material";
import { StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";

const AddNewCompany = () => {
  return (
    <Box sx={{ display: "flex" }}>
      {/* ========== main component body =========== */}
      <StyledMainBox component="main">
        <Box>
          <Stack direction={"row"} spacing={4}>
            {/* ------- campaign name -------- */}
            <TextInputField
              fieldRequired={true}
              elemId="companyName"
              label="Company Name"
              fieldName="company_name"
              showlabel={true}
              defaultVal=""
              inputType={"text"}
              onChangeCall={(e) => {
                e.preventDefault();
              }}
            />
            {/* ------ brand name -------- */}
            <TextInputField
              fieldRequired={true}
              elemId="companyType"
              label="Company Type"
              fieldName="company_type"
              defaultVal=""
              showlabel={true}
              inputType={"text"}
              onChangeCall={(e) => {
                e.preventDefault();
              }}
            />
          </Stack>
          <Box height={40} />
          <Stack direction={"row"} spacing={4}>
            {/* ------- country -------- */}
            <TextInputField
              fieldRequired={true}
              elemId="country"
              label="Country"
              fieldName="country_name"
              showlabel={true}
              defaultVal=""
              inputType={"text"}
              onChangeCall={(e) => {
                e.preventDefault();
              }}
            />
          </Stack>
        </Box>
      </StyledMainBox>
    </Box>
  );
};

export default AddNewCompany;
