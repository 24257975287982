// AccessControlHOC.js
import React, { useEffect, useState } from "react";
import { pageAccessAllowed } from "../../../api_calls/userManagement";
import { Box, Grid, Typography } from "@mui/material";
import SideNav from "../Sidebar/SideNav";
import { StyledMainBox } from "../StyledComponents/styled";
import forbiddenImage from "../../../images/forbidden/accessForbidden.png";

const AccessControl = (WrappedComponent, pageName) => {
  return function AccessControl(props) {
    const [canAccess, setCanAccess] = useState("checking");

    useEffect(() => {
      const checkAccess = pageAccessAllowed(pageName);
      setCanAccess(checkAccess.success);
    }, [pageName]);

    return (
      <Box component="div" sx={{ display: "flex" }}>
        <SideNav />
        {canAccess ? (
          <WrappedComponent {...props} />
        ) : (
          <StyledMainBox component="main">
            <Box height={300} />
            <Box height={100} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <img src={forbiddenImage} height={500} width={700} />
              <Typography variant="h4" color="#AF3241" sx={{ marginBottom: 2 }}>
                No permission
              </Typography>
              {/* <Typography variant="h5" color="#AF3241">
                Access Denied: You do not have permission to view this page.
              </Typography> */}
            </Box>
          </StyledMainBox>
        )}
      </Box>
    );
  };
};

export default AccessControl;
