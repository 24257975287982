import React from "react";
import { loadStores } from "../../../api_calls/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import MultiSelect from "../Selectors/MultiSelect";

const BrandStoresFilter = ({ usedFor }) => {
  const storeDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  let storeList =
    typeof storeDetails.campaign_detail !== "undefined" && storeDetails.campaign_detail.campaign_stores.length !== 0
      ? [...storeDetails.campaign_detail.campaign_stores].sort()
      : [];

  React.useEffect(() => {
    loadStores();
  }, []);

  return (
    <MultiSelect
      enableFullWidth={true}
      label={"Store"}
      options={[...new Set(storeList)].map((storeName) => {
        return { value: storeName, label: storeName };
      })}
      defaultVal={
        typeof storeDetails.brand_filters !== "undefined" &&
        typeof storeDetails.brand_filters[usedFor] !== "undefined" &&
        typeof storeDetails.brand_filters[usedFor].stores !== "undefined"
          ? storeDetails.brand_filters[usedFor].stores.map((storeName) => {
              return { value: storeName, label: storeName };
            })
          : []
      }
      selectionCheck={(option, value) => option.label === value.label}
      usedFor="brandStore"
      onChangeCall={(selectedOption) => {
        let userChoices = [];
        const preselectedBrandFilters = typeof storeDetails.brand_filters !== "undefined" ? storeDetails.brand_filters : {};
        selectedOption.forEach((chosen) => {
          if (chosen.label !== "(All)") {
            userChoices.push(chosen.label);
          } else {
            userChoices.push([...new Set(storeList)]);
          }
        });
        dispatchAction(
          updateStateData({
            brand_filters: {
              ...preselectedBrandFilters,
              [usedFor]: {
                ...preselectedBrandFilters[usedFor],
                stores: userChoices,
              },
            },
            report_filter_saved: false,
          })
        );
      }}
    />
  );
};

export default BrandStoresFilter;
