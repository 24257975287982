import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { CustomTextInput } from "../TextInputs/style";
import { StyledCheckbox, StyledMenuItemContainer, StyledMenuListItemText } from "../StyledComponents/styled";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Typography } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function MultiSelect({
  label,
  defaultVal,
  elemId,
  options,
  onChangeCall,
  fieldError,
  enableFullWidth,
  selectionCheck,
  usedFor,
}) {
  const [selectedValues, setSelectedValues] = React.useState(defaultVal);

  const getDisplayValue = () => {
    const numSelected = selectedValues.length;
    if (numSelected === 1) {
      return options.find((option) => selectionCheck(option, selectedValues[0]))?.label || "";
    } else if (numSelected > 1) {
      return "Multiple Selections";
    } else {
      return label || "";
    }
  };

  // Use useEffect to update selectedValue when defaultVal changes
  React.useEffect(() => {
    setSelectedValues(defaultVal);
  }, [defaultVal]);
  
  return (
    <Autocomplete
      id={elemId}
      size="small"
      autoFocus={false}
      multiple
      limitTags={1}
      disableCloseOnSelect
      fullWidth={enableFullWidth}
      sx={enableFullWidth ? { maxWidth: "100%" } : { maxWidth: "220px", width: "100%" }}
      options={options}
      filterOptions={(options, params) => {
        if (usedFor !== "filter") {
          return [{ label: "(All)", all: true }, ...options];
        } else {
          return [...options];
        }
      }}
      value={selectedValues}
      getOptionLabel={(option) => option?.label || ""}
      isOptionEqualToValue={(option, value) => selectionCheck(option, value)}
      onChange={(e, selectedOptions) => {
        const hasSelectAll = selectedOptions.some((option) => option.all);
        // If "Select All" is selected, set all options except "Select All"
        if (hasSelectAll) {
          if (selectedValues.length === options.length) {
            setSelectedValues([]);
            onChangeCall([]);
          } else {
            setSelectedValues(options.filter((option) => !option.all));
            onChangeCall(options.filter((option) => !option.all));
          }
        } else {
          setSelectedValues(selectedOptions);
          onChangeCall(selectedOptions);
        }
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <StyledCheckbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={option.all ? !!(selectedValues.length === options.length) : selected}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <CustomTextInput
          {...params}
          autoFocus={false}
          autoComplete="off"
          id={elemId + "_cutomTextForDropdown"}
          fullWidth={enableFullWidth}
          sx={enableFullWidth ? { maxWidth: "100%" } : { maxWidth: "220px", width: "100%" }}
          label={label}
          variant="standard"
          error={fieldError !== "" ? true : false}
          helperText={fieldError !== "" ? fieldError : ""}
          value={getDisplayValue()}
        />
      )}
      renderTags={(value, getTagProps) => {
        const numSelected = value.length;

        if (numSelected === 1) {
          return (
            <Typography variant="body2" sx={{ color: "#AF3241" }}>
              1 Selected
            </Typography>
          );
        } else if (numSelected === options.length) {
          return (
            <Typography variant="body2" sx={{ color: "#AF3241" }}>
              All
            </Typography>
          );
        } else if (numSelected > 1) {
          return (
            <Typography variant="body2" sx={{ color: "#AF3241" }}>
              {numSelected + " Selected"}
            </Typography>
          );
        } else {
          return (
            (
              <Typography variant="body2" sx={{ color: "#AF3241" }}>
                {label}
              </Typography>
            ) || ""
          );
        }
      }}
    />
  );
}
