import reportWebVitals from "./reportWebVitals";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import "./App.css";
import "./theme.css";
import "react-datepicker/dist/react-datepicker.css";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import ReactDOM from "react-dom/client";
import WebRoutes from "./routes/route";
import store from "./Store/store";
import { Provider } from "react-redux";
import Loader from "./components/commonComponents/Loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";

import enTranslation from "./translations/en.json";
import deTranslation from "./translations/de.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    de: { translation: deTranslation },
  },
  lng: "en", // Default language
  fallbackLng: "en", // Fallback language
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <WebRoutes />
      <Loader />
      <ToastContainer position="top-right" autoClose={5000} theme="light" />
    </ThemeProvider>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
