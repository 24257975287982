import * as React from "react";
import { Switch } from '@mui/material';
import { StyledSwitch, StyledDifferentColorSwitch } from "../../../components/commonComponents/StyledComponents/styled";
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { Icon, FormControl, FormLabel } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { changeParameter } from "../../../api_calls/TableauVisuals";


const ShowHideSwitch = ({vizId}) => {
    const [checked, setChecked] = React.useState(false);
    checked ? 
    changeParameter("Clips", "Show", vizId) 
    : changeParameter("Clips", "Hide",  vizId);
    
    const handleChange = (event) => {
        setChecked(event.target.checked);
        checked ? 
        changeParameter("Clips", "Show", vizId) 
        : changeParameter("Clips", "Hide",  vizId);
    };
    return  (
        <FormControl component="fieldset" variant="standard" sx={{ display: "flex", alignItems: "center", flexDirection: "row"}} >
            <FormLabel component="legend">Clips</FormLabel>
            <Tooltip title="Show/Hide Clip Name" >
                <FormControlLabel
                    control={<StyledSwitch checked={checked} name="Metric Swap" onChange={handleChange} />}
                    labelPlacement="right"
                    label={checked ? "On" : "Off"}
                    sx={{ fontSize: "12px", minWidth: 100 }}
                />
            </Tooltip>
        </FormControl>
    )
};

export default ShowHideSwitch;