import * as React from "react";
import { CustomWhiteColoredInput } from "./style";

export default function WhiteInputField({
  label,
  labelName,
  elemId,
  classDiv,
  fieldName,
  placeholder,
  defaultVal,
  fieldRequired,
  onChangeCall,
  tabIndex,
}) {
  return (
    <CustomWhiteColoredInput
      margin="normal"
      tabIndex={tabIndex}
      required={fieldRequired}
      fullWidth
      id={elemId}
      label={labelName}
      name={fieldName}
      variant="standard"
      autoFocus={false}
      autoComplete="off"
      defaultValue={defaultVal}
      onChange={onChangeCall}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onChangeCall(e);
        }
      }}
    />
  );
}

export const WhiteInputPasswordField = ({
  label,
  labelName,
  elemId,
  classDiv,
  fieldName,
  placeholder,
  defaultVal,
  fieldRequired,
  onChangeCall,
  tabIndex,
}) => {
  return (
    <CustomWhiteColoredInput
      margin="normal"
      required={fieldRequired}
      fullWidth
      tabIndex={tabIndex}
      id={elemId}
      label={labelName}
      name={fieldName}
      variant="standard"
      type="password"
      autoFocus={false}
      autoComplete="off"
      defaultValue={defaultVal}
      onChange={onChangeCall}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onChangeCall(e);
        }
      }}
    />
  );
};
