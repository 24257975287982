import { toast } from "react-toastify";
import { updateStateData } from "../Store/actions";
import store from "../Store/store";
import { loadCampaigns } from "./campaignRequests";
import { validateEmailFormat } from "./utils";
import { checkWorkSpaceFromCookies } from "./userManagement";
import { sendApiRequest } from "./requestConfig";

// load regions list
export const loadRegions = async (setRegionOject = false) => {
  const checkRegions = await sendApiRequest("regions", "", "GET");
  let response = {};
  if (checkRegions.success === true) {
    let regionsList = [];
    /*need to restructure clips response as there's a lot of extra info returned in the response.*/
    if (checkRegions.response.length !== 0) {
      //   loop through the clips response
      checkRegions.response.forEach((regions) => {
        if (setRegionOject === true) {
          regionsList.push({ ...regions, label: regions.name });
        } else {
          regionsList.push({ label: regions.name, id: regions.id });
        }
      });
    }
    // save to the redux state
    store.dispatch(
      updateStateData({
        regions_list: regionsList,
      })
    );
    response = {
      success: true,
    };
  } else {
    response = {
      success: false,
    };
  }
  return response;
};

// load stores
export const loadStores = async () => {
  const checkStores = await sendApiRequest("stores", "", "GET");
  let response = {};
  if (checkStores.success === true) {
    let storesList = [];
    if (checkStores.response.length !== 0) {
      storesList = checkStores.response;
    }
    // save to the redux state
    store.dispatch(
      updateStateData({
        stores_list: storesList,
      })
    );
    response = {
      success: true,
    };
  } else {
    response = {
      success: false,
    };
  }
  return response;
};

export const filterRegionsAndStores = () => {
  const currentStateData = store.getState().userSelections;
  // let selectedCompanies =
  //   typeof currentStateData.selected_company_ids !== "undefined" ? currentStateData.selected_company_ids : [];
  let selectedCompanies = [];
  const checkWorkSpaces = checkWorkSpaceFromCookies();

  if (
    checkWorkSpaces.success === true &&
    typeof checkWorkSpaces.current_workspace !== "undefined" &&
    typeof checkWorkSpaces.current_workspace.id !== "undefined"
  ) {
    selectedCompanies = [checkWorkSpaces.current_workspace.id];
  }

  const selectedRegions =
    typeof currentStateData.selected_region_ids !== "undefined" ? currentStateData.selected_region_ids : [];
  const storesList = typeof currentStateData.stores_list !== "undefined" ? currentStateData.stores_list : [];
  const regionsList = typeof currentStateData.regions_list !== "undefined" ? currentStateData.regions_list : [];
  let filteredRegionIds = [];
  let filteredStores = [];
  let filteredRegionsList = [];

  // filter based on companies
  if (selectedCompanies.length !== 0 && selectedRegions.length === 0) {
    storesList.forEach((store) => {
      if (selectedCompanies.includes(store.retailer_id)) {
        if (!filteredRegionIds.includes(store.region.id)) {
          filteredRegionIds.push(store.region.id);
        }
        filteredStores.push({ label: store.name, id: store.id });
      }
    });
  }
  // filter based on companies and region both
  if (selectedCompanies.length !== 0 && selectedRegions.length !== 0) {
    storesList.forEach((store) => {
      if (selectedCompanies.includes(store.retailer_id)) {
        if (!filteredRegionIds.includes(store.region.id)) {
          filteredRegionIds.push(store.region.id);
        }
      }
      if (selectedCompanies.includes(store.retailer_id) && selectedRegions.includes(store.region.id)) {
        filteredStores.push({ label: store.name, id: store.id });
      }
    });
  }
  // regions list with region name and id

  if (filteredRegionIds.length !== 0) {
    regionsList.forEach((region) => {
      if (filteredRegionIds.includes(parseInt(region.id))) {
        filteredRegionsList.push(region);
      }
    });
  }

  store.dispatch(
    updateStateData({
      filtered_stores: filteredStores,
      filtered_regions: filteredRegionsList,
    })
  );
};

export const preSelectionOfStores = (selectionInfo, afterSelectionOperation) => {
  const currentStateData = store.getState().userSelections;
  const checkFilteredStores =
    typeof currentStateData.filtered_stores !== "undefined" ? currentStateData.filtered_stores : [];
  if (typeof selectionInfo !== "undefined") {
    let preSelectedStoreOptions = [];
    if (typeof checkFilteredStores !== "undefined") {
      checkFilteredStores.forEach((store_info) => {
        if (selectionInfo.selected_store_options.length !== 0) {
          selectionInfo.selected_store_options.forEach((selected_store_info) => {
            if (selected_store_info.id === store_info.id) {
              preSelectedStoreOptions.push(store_info);
            }
          });
        }
      });
    }
    afterSelectionOperation(preSelectedStoreOptions);
  }
};

const validateReportShareInfo = () => {
  const currentStateData = store.getState().userSelections;
  const emailList =
    typeof currentStateData.report_share_data !== "undefined" &&
    typeof currentStateData.report_share_data.email_list !== "undefined"
      ? currentStateData.report_share_data.email_list
      : "";
  let error = false;
  let errorMessages = {};

  // validate if the emails are provided or not
  if (emailList === "") {
    // toast("Please fill in valid information", { type: "error" });
    error = true;
    errorMessages = {
      ...errorMessages,
      email_list: "Please enter the emails",
    };
  } else {
    const emailsArr = emailList.split(",");
    emailsArr.forEach((email) => {
      const validateFormat = validateEmailFormat(email);
      if (validateFormat === false) {
        error = true;
        errorMessages = {
          ...errorMessages,
          email_list: "Please enter valid emails",
        };
      }
    });
  }

  if (error === true) {
    return {
      success: false,
      error: true,
      errorMessages: errorMessages,
    };
  } else {
    return {
      success: true,
      error: false,
      report_share_request: { ...currentStateData.report_share_data, email_list: emailList.split(",") },
    };
  }
};

// send report share email
export const sendReportShareInfo = async () => {
  const validateData = validateReportShareInfo();

  // validate if the emails are provided or not
  if (validateData.error === true) {
    toast("Please fill in valid information", { type: "error" });
    return {
      success: false,
      error: true,
      errorMessages: validateData.errorMessages,
    };
  } else {
    // send api request
    const sendInfo = await sendApiRequest("invite", validateData.report_share_request, "POST");

    if (typeof sendInfo.success !== "undefined" && sendInfo.success === true) {
      toast("Report shared successfully", { type: "success" });
      return {
        success: true,
        message: "Report shared successfully",
      };
    } else {
      toast("Sorry, something went wrong", { type: "error" });
      return {
        success: true,
        message: "Sorry, something went wrong",
      };
    }
  }
};

export const filterRetailerCampaigns = (retailer_id) => {
  const currentStateData = store.getState().userSelections;
  const campaignsList = currentStateData.campaign_list.all_campaigns;
  let retailerCampaignList = [];
  campaignsList.forEach((campaign) => {
    retailerCampaignList.push({ value: campaign.campaign_id, label: campaign.campaign_name });
  });
  store.dispatch(
    updateStateData({
      retailers_campaign_list: retailerCampaignList,
    })
  );
};

export const updateCampaignStatus = async (statusCode, campaign_id) => {
  //save project id and update campaign status to be 2 i.e., data processing
  const updateData = await sendApiRequest("campaigns/update", { campaign_status: statusCode, id: campaign_id }, "PUT");

  if (typeof updateData.success !== "undefined" && updateData.success === true) {
    /*
      fetch campaign details once the data is updated 
      (it will automatically update the redux state with latest data)
    */
    loadCampaigns();
    return {
      success: true,
      message: "Data updated successfully.",
    };
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong.",
    };
  }
};

// to send a request to get campaign analytics
export const requestAnalyticsReport = async (campaign_id, campaign_name, retailer_id) => {
  // send api request
  const sendRequest = await sendApiRequest(
    "email/send-request",
    {
      name: campaign_name,
      id: campaign_id,
      retailer: retailer_id,
    },
    "POST"
  );

  if (typeof sendRequest.success !== "undefined" && sendRequest.success === true) {
    const message = "Report successfully requested.";
    updateCampaignStatus(2, campaign_id); //update campaign status
    toast(message, { type: "success" });
    return {
      success: true,
      message: message,
    };
  } else {
    toast("Sorry, something went wrong", { type: "error" });
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  }
};
