import React from "react";
import { HtmlTooltip, StyledStack } from "../StyledComponents/styled";
import { ExpandCircleDown } from "@mui/icons-material";

const LoadMoreButton = ({ clickAction }) => {
  return (
    <StyledStack>
      <HtmlTooltip title="More results">
        <a
          href="javacript:void(0)"
          onClick={(e) => {
            e.preventDefault();
            clickAction();
          }}
        >
          <ExpandCircleDown sx={{ cursor: "pointer", fontSize: "4em", color: "#AF3241" }} />
        </a>
      </HtmlTooltip>
    </StyledStack>
  );
};

export default LoadMoreButton;
