import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import { tabs } from "./locationDashboardsConfig";
import {
  HtmlTooltip,
  IconContainer,
  StickyTopRowStack,
  StyledInformationSpan,
  StyledMainBox,
  StyledTabs,
} from "../../../components/commonComponents/StyledComponents/styled";
import SideNav from "../../../components/commonComponents/Sidebar/SideNav";
import { useParams } from "react-router-dom";
import { discardChanges, fetchCountriesList, fetchOpeningHours, trackChanges } from "../../../api_calls/infrastructure";
import { loadRegions } from "../../../api_calls/campaigns";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { ContactMail, Info } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Confirmation from "../ModalContent/Confirmation";
import AccessControl from "../../../components/commonComponents/Hoc/AccessControl";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConfigureLocation = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const [value, setValue] = React.useState(0);
  const [valueToSetAfterConfirmation, setValueToSetAfterConfirmation] = React.useState(0);

  const { id } = useParams();
  const [showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);

  const handleChange = (event, newValue) => {
    const showConfirmation =
      typeof userStateData.track_infra_changes !== "undefined" ? userStateData.track_infra_changes : false;
    if (showConfirmation === true) {
      setValueToSetAfterConfirmation(newValue);
      setShowCancelConfirmation(true);
    } else {
      setValue(newValue);
    }
  };

  React.useEffect(() => {
    fetchOpeningHours();
    loadRegions();
    fetchCountriesList();
  }, []);

  return (
    <StyledMainBox component="main">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box width={500}>
          <Stack direction={"row"} spacing={1}>
            <h3>Store: {userStateData?.store_overview?.name} </h3>
            {userStateData?.store_overview?.active === false && (
              <>
                <h3>
                  <strong>(Inactive)</strong>
                </h3>{" "}
                <HtmlTooltip
                  title={
                    <Box width={300}>
                      <Typography variant="body2">
                        This store is currently not active, thus no in-store events are collected. Any modifications made in
                        CAP Explorer are still being saved.
                      </Typography>
                    </Box>
                  }
                >
                  <Info sx={{ fontSize: "20px" }} />
                </HtmlTooltip>
              </>
            )}
          </Stack>
        </Box>
        <Box display="flex" alignItems="center">
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "mailto:support@cyreen.de";
            }}
            label={"Contact Technical Support"}
            icon={<ContactMail />}
          />
        </Box>
      </Box>

      <Box height={20} />
      <Box
        sx={{
          display: "flex", // Make the Box a flex container
          // borderBottom: 1,
          // borderColor: "divider",
          position: "sticky",
          top: "0",
          zIndex: 1,
          backgroundColor: "#fff",
        }}
      >
        <Box component={"div"}>
          <StyledTabs value={value} onChange={handleChange} aria-label="brand analytics" textColor={"#F04B55"}>
            {tabs.map((tabDetail) => {
              return <Tab key={tabDetail.unique_key} label={tabDetail.label} {...a11yProps(0)} />;
            })}
          </StyledTabs>
        </Box>
        {/* ----------- filters section ------------ */}
      </Box>

      {/* ============== tab contents =========== */}
      {tabs.map((tabDetail, index) => {
        return (
          <CustomTabPanel value={value} index={index}>
            {tabDetail.dashboard}
          </CustomTabPanel>
        );
      })}

      {/* ----------- track changes confirmation ----------- */}
      {showCancelConfirmation && (
        <Confirmation
          showConfirmationPopup={showCancelConfirmation}
          closePopup={(e) => {
            setShowCancelConfirmation(false);
          }}
          confirmationActionCall={() => {
            discardChanges(id);
            trackChanges(false);
            setValue(valueToSetAfterConfirmation);
          }}
          confirmationMessage="Are you certain you want to discard all the changes you have made?"
        />
      )}
    </StyledMainBox>
  );
};

export default AccessControl(ConfigureLocation, "infrastructure");
