import React from "react";
import { Box, Stack } from "@mui/material";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import TabularList from "../../components/commonComponents/TabularLists/TabularList";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { Add, Edit, Save } from "@mui/icons-material";
import {
  HtmlTooltip,
  IconContainer,
  StyledStack,
  StyledSwitch,
} from "../../components/commonComponents/StyledComponents/styled";
import SimpleModal from "../../components/commonComponents/Modals/SimpleModal";
import AddNewContact from "./ModalContent/AddContact";

// dummy data for companies. (It will be removed later when backend APIs will be integrated)
const rows = [
  {
    id: 1,
    name: "Meat Guy",
    company: "BBQ Company GmbH",
    brand: "Spareribs",
    mail: "meat.guy@bbq.de",
    active: true,
    last_edit_by: "2022-09-14",
  },
  {
    id: 2,
    name: "Pizza Frank",
    company: "Pizza Manufacturer GmbH",
    brand: "Margherita",
    mail: "pizza.frank@pizza.com",
    active: true,
    last_edit_by: "2022-11-14",
  },
  {
    id: 3,
    name: "Gin Tonic",
    company: "Drinks Maker GmbH",
    brand: "Champagne",
    mail: "gin@tonic.com",
    active: true,
    last_edit_by: "",
  },
  {
    id: 4,
    name: "Paul Pudding",
    company: "Pudding Producer GmbH",
    brand: "Pudding",
    mail: "	paul@pudding.com",
    active: true,
    last_edit_by: "2022-09-14",
  },
  {
    id: 5,
    name: "Choco Latte",
    company: "Chocolate Maker GmbH",
    brand: "Chocolate",
    mail: "	choco@latte.com",
    active: true,
    last_edit_by: "2022-09-14",
  },
];

const Contacts = () => {
  const fixedFieldNames = ["#", "Name", "Company", "Brand", "Mail", "Active", "Last Edit", "Action"];
  const [fieldNames, setFieldNames] = React.useState([]);
  const [fieldValues, setFieldValues] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalDetails, setModalDetails] = React.useState({});

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setModalDetails({
      title: "Add New Contact",
      content: <AddNewContact />,
      confirmAction: (e) => {
        e.preventDefault();
        closeModal();
      },
    });
    setIsModalOpen(true);
  };

  const update_field_values = () => {
    let fieldValuesToShow = [];
    rows.forEach((rowValue) => {
      let defineValues = {
        id: (
          <StyledStack direction={"row"} spacing={2}>
            {rowValue.id}
          </StyledStack>
        ),
        Name: <StyledStack direction={"row"}>{rowValue.name}</StyledStack>,
        Company: <StyledStack direction={"row"}>{rowValue.company}</StyledStack>,
        Brand: <StyledStack direction={"row"}>{rowValue.brand}</StyledStack>,
        Mail: <StyledStack direction={"row"}>{rowValue.mail}</StyledStack>,
        Active: (
          <StyledStack direction={"row"}>
            <StyledStack direction={"row"}>
              <StyledSwitch checked={rowValue.active} />
            </StyledStack>
          </StyledStack>
        ),
        "Last Edit": <StyledStack direction={"row"}>{rowValue.last_edit_by}</StyledStack>,
        Action: (
          <StyledStack direction={"row"} spacing={2}>
            <HtmlTooltip title="Edit contact">
              <IconContainer>
                <a href="javascript:void(0)">
                  <Edit sx={{ color: "#AF3241" }} />
                </a>
              </IconContainer>
            </HtmlTooltip>
          </StyledStack>
        ),
      };
      fieldValuesToShow.push(defineValues);
    });
    setFieldValues(fieldValuesToShow);
  };

  React.useEffect(() => {
    setFieldNames(fixedFieldNames);
    //set value for all the fields
    update_field_values([]);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <SideNav />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Box height={30} />
        <h1>Contacts</h1>
        {/* ============ chose data fields button =========== */}
        <Stack direction="row" spacing={2}>
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              openModal("newcompany");
            }}
            label={"New Contact"}
            icon={<Add />}
          />
        </Stack>
        {/* ============== campaign listing ============ */}
        <Box height={30} />
        <TabularList fieldNames={fieldNames} fieldValues={fieldValues} />
      </Box>
      {/* ============ modal popup for different actions ========== */}
      <SimpleModal
        open={isModalOpen}
        onClose={closeModal}
        title={modalDetails.title}
        content={modalDetails.content}
        confirmAction={modalDetails.confirmAction}
        confirmIcon={<Save />}
        confirmLabel={"Confirm"}
        size={"md"}
      />
    </Box>
  );
};

export default Contacts;
