import React from "react";
import {
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  DialogContentText,
  FormControlLabel,
  Tab,
} from "@mui/material";
import {
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowLeft,
  ArrowRight,
  ArrowUpward,
  Close,
  Info,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  KeyboardDoubleArrowUp,
  Save,
} from "@mui/icons-material";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import DualListBox from "react-dual-listbox";
import {
  HtmlTooltip,
  StyledBlackInformationTypography,
  StyledCheckbox,
  StyledDarkRedInformationTypography,
  StyledMainBox,
  StyledSwitch,
  StyledTabs,
} from "../../../components/commonComponents/StyledComponents/styled";
import PropTypes from "prop-types";
import NewReportSection from "./NewReportSection";
import EditReportSection from "./EditReportSection";
import {
  clearSectionsList,
  fetchReportSettings,
  resetEditSectionDetails,
  resetReportSettings,
  saveVisibleSectionsForCampaign,
} from "../../../api_calls/ReportSettings";
import { updateStateData } from "../../../Store/actions";
import DisabledButton, { DisabledGreyButton } from "../../../components/commonComponents/Buttons/DisabledButton";

// to load tab content
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReportSectionSettings = ({ open, size, closeModal, campaignRecord }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const [showNewSection, setShowNewSection] = React.useState(false);
  const [showEditSection, setShowEditSection] = React.useState(false);
  const dispatchAction = useDispatch();
  const [sectionsUpdate, setSectionsUpdate] = React.useState(false);
  const reportAvailableFlag = [4].includes(campaignRecord.campaign_status_code) ? true : false;
  const [reportAvailable, setReportAvailable] = React.useState(reportAvailableFlag);
  const [markDefault, setMarkDefault] = React.useState(false);

  const updateSections = () => {
    setSectionsUpdate(true);
    clearSectionsList();
  };

  const setSelectedOptions = (selectedOptions) => {
    dispatchAction(
      updateStateData({
        report_settings: {
          ...currentStateData.report_settings,
          visible_options: [...selectedOptions],
        },
      })
    );
  };

  React.useEffect(() => {
    fetchReportSettings(campaignRecord.campaign_id);
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={(e) => {
          e.preventDefault();
        }}
        fullWidth={true}
        maxWidth={size}
      >
        <DialogTitle>
          <strong>Report Settings</strong>
        </DialogTitle>
        <DialogContent>
          {/* ------- disclaimer text for brand report --------- */}
          <DialogContentText>
            <Stack direction={"row"} spacing={3}>
              {/* ------ cyreen store id ------ */}
              <Box>
                <Stack direction={"row"} spacing={1}>
                  <StyledDarkRedInformationTypography>Campaign Name :</StyledDarkRedInformationTypography>
                  <StyledBlackInformationTypography>{campaignRecord.campaign_name}</StyledBlackInformationTypography>
                </Stack>
              </Box>
              <Box>
                <Stack direction={"row"} spacing={1}>
                  <StyledDarkRedInformationTypography>Campaign ID :</StyledDarkRedInformationTypography>
                  <StyledBlackInformationTypography>{campaignRecord.campaign_id}</StyledBlackInformationTypography>
                </Stack>
              </Box>
            </Stack>
          </DialogContentText>
          <Box height={30} />
          <Box>
            <Typography variant="body3">
              <Stack direction={"row"} spacing={1}>
                <strong>Section Visibility </strong>
                <HtmlTooltip
                  title={
                    <Box width={400}>
                      <StyledMainBox>
                        <Box height={5} />
                        <Typography variant="body2">
                          Defines which report sections are to be visible to users for this campaign. The order of the
                          sections in the report is defined by the order of your selection within the “Visible Sections”
                          box. The first entry in the list will be the leftmost section, i.e., first section after
                          impressions section. The impressions section is visible by default.
                        </Typography>
                        <Box height={10} />
                        <Typography variant="body2">
                          All changes to the sections are saved on a workspace-level only. Changes to the sections do not
                          change settings for campaigns for which visible sections are already specified.
                        </Typography>
                      </StyledMainBox>
                    </Box>
                  }
                >
                  <Info sx={{ fontSize: "18px" }} />
                </HtmlTooltip>
              </Stack>
            </Typography>

            <Box height={20} />
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
              <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                <Typography variant="body2" sx={{ marginRight: "60px" }}>
                  <strong>Available Sections</strong>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                <Typography variant="body2" sx={{ marginRight: "60px" }}>
                  <strong>Visible Sections</strong>
                </Typography>
              </Box>
            </Box>
            <Box height={10} />
            <DualListBox
              preserveSelectOrder={true}
              showOrderButtons={true}
              options={
                typeof currentStateData.report_settings !== "undefined" &&
                typeof currentStateData.report_settings.available_options !== "undefined"
                  ? currentStateData.report_settings.available_options
                      .map((availableOption) => {
                        return {
                          value: availableOption.tab_text,
                          label: availableOption.tab_text + " - (" + availableOption.tab_name + ")",
                        };
                      })
                      .sort((next, prev) => {
                        return next.label.localeCompare(prev.label);
                      })
                  : []
              }
              //   showHeaderLabels={true}
              selected={
                typeof currentStateData.report_settings !== "undefined" &&
                typeof currentStateData.report_settings.visible_options !== "undefined"
                  ? currentStateData.report_settings.visible_options
                  : []
              }
              onChange={(newValue) => {
                setSelectedOptions(newValue);
              }}
              icons={{
                moveToAvailable: <KeyboardArrowLeft />,
                moveAllToAvailable: <KeyboardDoubleArrowLeft />,
                moveToSelected: <KeyboardArrowRight />,
                moveAllToSelected: <KeyboardDoubleArrowRight />,
                moveDown: <KeyboardArrowDown />,
                moveUp: <KeyboardArrowUp />,
                moveTop: <KeyboardDoubleArrowUp />,
                moveBottom: <KeyboardDoubleArrowDown />,
              }}
            />
          </Box>

          {typeof currentStateData.report_settings !== "undefined" &&
          typeof currentStateData.report_settings.settingDeviations !== "undefined" &&
          currentStateData.report_settings.settingDeviations === true ? (
            <Box>
              <Box height={15} />
              <Typography variant="body2">
                <strong>
                  Since last saving section settings for this campaign, the section settings for the workspace have changed.
                  If you require the newest workspace section settings, please press Update Sections. Normally, this action
                  is not recommended and only needed if Tableau dashboards with new settings are supposed to be re-published
                  for this campaign. Once saved, this action cannot be undone.
                </strong>
              </Typography>

              {sectionsUpdate === false ? (
                <ActionButton
                  label={"Update Sections"}
                  onClick={(e) => {
                    e.preventDefault();
                    updateSections();
                  }}
                  icon={<></>}
                />
              ) : (
                <>
                  <Box height={25} />
                  <DisabledGreyButton
                    label={"Update Sections"}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    icon={<></>}
                    toolTipContent=""
                  />
                  <Box height={15} />
                </>
              )}
            </Box>
          ) : (
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center">
                <ActionButton
                  label={"Add Section"}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowNewSection(!showNewSection);
                  }}
                  icon={<></>}
                />
                <Box ml={16}>
                  <ActionButton
                    label={"Edit Section(s)"}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowEditSection(!showNewSection);
                    }}
                    icon={<></>}
                  />
                </Box>
              </Box>

              <Box>
                <Box ml={12}>
                  {/* Add margin between the buttons */}
                  <FormControlLabel
                    control={
                      <StyledCheckbox
                        checked={markDefault}
                        onChange={(e) => {
                          setMarkDefault(!markDefault);
                          dispatchAction(
                            updateStateData({
                              report_settings: {
                                ...currentStateData.report_settings,
                                marked_as_default: !markDefault,
                              },
                            })
                          );
                        }}
                      />
                    }
                    label="Make Default"
                  />
                </Box>
              </Box>
            </Box>
          )}

          <Box height={20} />
          <Box>
            <Typography variant="body3">
              <strong>Report Availability</strong>
            </Typography>
            <Box height={20} />
            <FormControlLabel
              control={
                <StyledSwitch
                  checked={reportAvailable}
                  disabled={![2, 4].includes(campaignRecord.campaign_status_code) ? true : false}
                  onChange={(e) => {
                    setReportAvailable(!reportAvailable);
                    dispatchAction(
                      updateStateData({
                        report_settings: {
                          ...currentStateData.report_settings,
                          campaign_status: !reportAvailable === true ? 4 : 2,
                        },
                      })
                    );
                  }}
                />
              }
              label={"Campaign report available to users"}
              sx={{ minWidth: 100 }}
            />
          </Box>
        </DialogContent>

        <Box height={20} />
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <CloseButton
              onClick={(e) => {
                e.preventDefault();
                resetReportSettings();
                resetEditSectionDetails();
                closeModal();
              }}
              label="Cancel"
              icon={<Close />}
            />

            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                saveVisibleSectionsForCampaign(campaignRecord.campaign_id).then((info) => {
                  if (info.success === true) {
                    closeModal();
                    // if (sectionsUpdate === false) {
                    //   closeModal();
                    // } else {
                    //   fetchReportSettings(campaignRecord.campaign_id);
                    // }
                  }
                });
              }}
              label={"Save"}
              icon={<Save />}
            />
          </Stack>
        </DialogActions>
      </Dialog>
      {/* ------------- new section popup ----------- */}
      {showNewSection === true && (
        <NewReportSection
          showModal={showNewSection}
          closeModal={setShowNewSection}
          campaignId={campaignRecord.campaign_id}
        />
      )}

      {/* ------------ edit section popup ------------ */}
      {showEditSection === true && (
        <EditReportSection
          showModal={showEditSection}
          closeModal={setShowEditSection}
          campaignId={campaignRecord.campaign_id}
        />
      )}
    </>
  );
};

export default ReportSectionSettings;
