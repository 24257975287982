import platformConfig from "../platformConfig";

const initialState = {
  userSelections: {
    initial_campaign_load: 10,
    live_campaign_loaded: 10,
    recent_campaign_loaded: 10,
    scheduled_campaign_loaded: 10,
    initial_load_count_for_users: platformConfig.initial_load_count,
    initial_load_count_for_campaigns: platformConfig.initial_load_count,
    initial_load_count_for_clips: platformConfig.initial_load_count,
    initial_load_count_for_pictures: platformConfig.initial_load_count,
    initial_load_count_for_infra_store: platformConfig.initial_load_count,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_STATE":
      return { ...state, userSelections: { ...state.userSelections, ...action.updatedData } };
    case "DELETE_ITEMS":
      let newObjWithDeletedItems = {};
      Object.keys(state.userSelections).forEach((key) => {
        if (!action.itemsToBeDeleted.includes(key)) {
          newObjWithDeletedItems = {
            ...newObjWithDeletedItems,
            [key]: state.userSelections[key],
          };
        }
      });
      return { ...state, userSelections: newObjWithDeletedItems };
    default:
      return state;
  }
};

export default reducer;
