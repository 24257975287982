import React from "react";
import FormControl from "@mui/material/FormControl";
import {
  StyledInputLabel,
  StyledMenuItemContainer,
  StyledCheckbox,
  StyledMenuListItemText,
  StyledSelectBox,
} from "../StyledComponents/styled";
// import { ArrowDropDown } from "@mui/icons-material";
import { FormHelperText } from "@mui/material";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      fontSize: "12px",
    },
  },
};

const SelectBox = ({
  label,
  options,
  value,
  onChange,
  onBlur,
  renderValue,
  multiSelect,
  fieldError,
  readOnlyFlag,
  fieldInfo,
}) => {
  return (
    <FormControl variant="standard" fullWidth size="small" error={fieldError !== "" ? true : false}>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelectBox
        label={label}
        value={value}
        onChange={onChange}
        onClose={onBlur}
        renderValue={renderValue}
        MenuProps={MenuProps}
        multiple={multiSelect}
        readOnly={readOnlyFlag}
        endAdornment={fieldInfo}
      >
        {options.map((option) => (
          <StyledMenuItemContainer key={option} value={option}>
            {multiSelect ? (
              <>
                <StyledCheckbox checked={value.includes(option) ? true : false} />
                <StyledMenuListItemText primary={option} />
              </>
            ) : (
              <StyledMenuListItemText primary={option} />
            )}
          </StyledMenuItemContainer>
        ))}
      </StyledSelectBox>
      {/* --------- error message --------- */}
      {fieldError !== "" && <FormHelperText>{fieldError}</FormHelperText>}
    </FormControl>
  );
};

export default SelectBox;
