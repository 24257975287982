import React from "react";
import { Box } from "@mui/material";
import { filterRangeValue } from "../../../api_calls/TableauVisuals";
import StoreFilter from "../../../components/commonComponents/Filters/StoreFilter";
import DateRangeFilter from "../../../components/commonComponents/Filters/DateRangeFilter";
import { StickyTopRowStack } from "../../../components/commonComponents/StyledComponents/styled";
import { useSelector } from "react-redux";
import { dbFormatDate, formatToStandardDate, standardFormatToday } from "../../../api_calls/utils";
import RankByFilter from "../../../components/commonComponents/Filters/RankByFilter";
import MetricSwap from "./MetricSwap";

const ImpressionsFilter = ({ vizId }) => {
  const campaignDetails = useSelector((state) => state.userSelections);

  const campaignStartDate =
    typeof campaignDetails.campaign_detail !== "undefined" ? campaignDetails.campaign_detail.campaign_start_date : "";

  const today = standardFormatToday();
  const yesterday = formatToStandardDate(today);
  yesterday.setDate(today.getDate() - 1);

  const campaignEndDate =
    typeof campaignDetails.campaign_detail !== "undefined" &&
    campaignDetails.campaign_detail.campaign_active_status !== "Active"
      ? campaignDetails.campaign_detail.campaign_end_date
      : dbFormatDate(yesterday);

  return (
    <>
      <StickyTopRowStack direction={"row"} spacing={4}>
        {/* ---------- Metrics Filter ---------- */}
        <Box sx={{ width: 70, marginTop: "10px" }}>
          <MetricSwap vizId={vizId} />
        </Box>

        {/* ------- stores filter ----------- */}
        <Box sx={{ width: 220, marginTop: "10px" }}>
          <StoreFilter fieldName={"name_store"} usedFor={"campaign"} vizId={vizId} />
        </Box>

        {/* ------------ day timestamp filter ------------ */}
        <Box sx={{ width: 220 }}>
          <DateRangeFilter
            minSelectableDate={campaignStartDate}
            maxSelectableDate={campaignEndDate}
            fieldName={"DAY(timestamp)"}
            onValueChange={filterRangeValue}
            vizId={vizId}
          />
        </Box>
      </StickyTopRowStack>
    </>
  );
};

export default ImpressionsFilter;
