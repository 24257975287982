import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledCenterAlignedTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#62626E",
    color: theme.palette.common.white,
    textAlign: "center",
    fontWeight: "bold",
  },
  "&.text-wrapper": {
    maxWidth: "150px",
    // overflowWrap: "anywhere"
  },
  "&.wrapLargeText": {
    overflowWrap: "anywhere",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#62626E",
    color: theme.palette.common.white,
    textAlign: "center",
    fontWeight: "bold",
  },
  "&.text-wrapper": {
    maxWidth: "150px",
    // overflowWrap: "anywhere"
  },
  "&.wrapLargeText": {
    overflowWrap: "anywhere",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
  },
}));

export const LeftStyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#62626E",
    color: theme.palette.common.white,
    textAlign: "left",
    fontWeight: "bold",
  },
  "&.text-wrapper": {
    maxWidth: "150px",
    // overflowWrap: "anywhere"
  },
  "&.wrapLargeText": {
    overflowWrap: "anywhere",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
  },
}));

export const SmallWidthStyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#62626E",
    color: theme.palette.common.white,
    textAlign: "left",
    fontWeight: "bold",
  },
  "&.text-wrapper": {
    width: "65px",
    // overflowWrap: "anywhere"
  },
  "&.wrapLargeText": {
    overflowWrap: "anywhere",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
  },
}));
