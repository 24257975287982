import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { StyledMainBox, StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import { useSelector } from "react-redux";
import { Close, Save } from "@mui/icons-material";
import ActionButton, { ConditionalOutlinedButton } from "../../../components/commonComponents/Buttons/ActionButton";
import FormControl from "@mui/material/FormControl";
import { checkWorkSpaceFromCookies, switchWorkspace } from "../../../api_calls/userManagement";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetchWorkSpaceList } from "../../../api_calls/requestConfig";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";

const WorkSpace = ({ open, onClose, size, usedFor }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const [selectedWorkSpace, setSelectedWorkSpace] = React.useState("");
  const navigate = useNavigate();
  React.useEffect(() => {
    const checkWorkSpaces = checkWorkSpaceFromCookies();
    if (
      checkWorkSpaces.success === true &&
      typeof checkWorkSpaces.current_workspace !== "undefined" &&
      typeof checkWorkSpaces.current_workspace.id !== "undefined"
    ) {
      setSelectedWorkSpace(checkWorkSpaces.current_workspace.id);
    }

    if (typeof currentStateData.workspace_options === "undefined") {
      fetchWorkSpaceList(navigate);
    }
  }, []);

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>Your CAP Explorer Workspaces</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have access to workspaces of multiple retailers. Please choose which workspace you would like to enter.
        </DialogContentText>
        <StyledMainBox component="main">
          <Box height={20} />
          <FormControl>
            <StyledStack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
              {typeof currentStateData.workspace_options !== "undefined" &&
                currentStateData.workspace_options.length !== 0 &&
                currentStateData.workspace_options.map((workSpace) => {
                  return (
                    <ConditionalOutlinedButton
                      key={workSpace.id}
                      selected={selectedWorkSpace === workSpace.id ? true : false}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedWorkSpace(workSpace.id);
                      }}
                      label={workSpace.name}
                    />
                  );
                })}
            </StyledStack>
          </FormControl>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <StyledStack direction={"row"} spacing={2}>
          {usedFor === "update" && <CloseButton onClick={onClose} label="Cancel" icon={<Close />} />}

          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              if (selectedWorkSpace === "") {
                toast("Please select the preferred workspace", { type: "error" });
              } else {
                switchWorkspace(selectedWorkSpace, navigate, usedFor);
              }
            }}
            label={"Enter"}
            icon={<Save />}
          />
        </StyledStack>
      </DialogActions>
    </Dialog>
  );
};

export default WorkSpace;
