import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { checkUncheckAllCheckboxes, fetch_adlevel_names } from "../../../api_calls/campaignSheet";
import { validateAlphabets, validateNumbers } from "../../../api_calls/utils";
import { toast } from "react-toastify";
import { Box, Stack, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import { StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { Close, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { StyledMainBox } from "../../../components/commonComponents/StyledComponents/styled";

const AdlevelModal = (props) => {
  const [adlevelData, setAdlevelData] = React.useState({});
  const campaignDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldError, setFieldError] = React.useState({});

  //save records with adlevel details to the redux state
  const saveAdlevelDetails = () => {
    let error = false;
    let error_message = {};
    //validate adlevel name =  only alphabets
    const validateNameAdlevel = validateAlphabets(
      typeof adlevelData.adlevel_name !== "undefined" ? adlevelData.adlevel_name : ""
    );
    if (validateNameAdlevel.error === true) {
      error = true;
      error_message = {
        ...error_message,
        nameAdLevel: "Analysis group name must not be empty and may not contain numbers",
      };
    }
    //validate adlevel number = must be number and not greater than 20
    const validateNumberAdlevel = validateNumbers(
      typeof adlevelData.adlevel_number !== "undefined" ? adlevelData.adlevel_number : ""
    );
    if (validateNumberAdlevel.error === true) {
      error = true;
      error_message = {
        ...error_message,
        adlevel_number: "Analysis group number must not be empty and may not contain letters",
      };
    } else {
      if (adlevelData.adlevel_number > 20) {
        error = true;
        error_message = {
          ...error_message,
          adlevel_number: "Analysis group number can not be greater than 20",
        };
      }
    }

    if (error === false) {
      const campaignSheetdata =
        typeof campaignDetails.filter_applied !== "undefined" && campaignDetails.filter_applied === true
          ? campaignDetails.filtered_sheet_records
          : campaignDetails.campaign_sheet_records;

      //check if adlevels are already created
      let createAdlevels = typeof campaignDetails.created_adlevels !== "undefined" ? campaignDetails.created_adlevels : [];
      //selected products keys
      const selectedProducts = props.productSelection;
      selectedProducts.forEach((prodKey) => {
        if (typeof campaignSheetdata[prodKey] !== "undefined") {
          createAdlevels = [
            ...createAdlevels,
            {
              description: campaignSheetdata[prodKey].description,
              gtin: campaignSheetdata[prodKey].gtin,
              nameAdLevel: adlevelData.adlevel_name,
              numberAdLevel: adlevelData.adlevel_number,
              gtinBundle: "",
              bundleSize: 1,
              nameCompany: campaignSheetdata[prodKey].retailer,
              campaign: campaignSheetdata[prodKey].campaign,
            },
          ];
        }
      });

      dispatchAction(updateStateData({ created_adlevels: createAdlevels }));
      fetch_adlevel_names(createAdlevels);
      props.setShowAdlevelModal(false);
      // uncheck the checkboxes which were selected
      props.setProductSelection([]);
      checkUncheckAllCheckboxes(false);
    } else {
      setFieldError({
        error: error,
        ...error_message,
      });
      toast("Please fill all the required details for new adlevel", { type: "error" });
    }
  };

  return (
    <Dialog
      open={props.showAdlevelModal}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>Create Analysis Group</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            <StyledStack direction={"row"} spacing={4}>
              {/* ------- Adlevel number ------- */}
              <TextInputField
                fieldRequired={true}
                elemId="adlevel_number"
                label="Analysis Group Number"
                fieldName="adlevel_number"
                showlabel={true}
                defaultVal={typeof adlevelData.adlevel_number !== "undefined" ? adlevelData.adlevel_number : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  if (e.target.value.trim() !== "") {
                    setAdlevelData({ ...adlevelData, adlevel_number: e.target.value });
                  } else {
                    setAdlevelData({ ...adlevelData, adlevel_number: "" });
                  }
                }}
                fieldError={
                  typeof fieldError.error !== "undefined" &&
                  fieldError.error === true &&
                  typeof fieldError.adlevel_number !== "undefined"
                    ? fieldError.adlevel_number
                    : ""
                }
              />
              {/* ------- Adlevel name ------- */}
              <TextInputField
                fieldRequired={true}
                elemId="adlevel_name"
                label="Analysis Group Name"
                fieldName="adlevel_name"
                showlabel={true}
                defaultVal={typeof adlevelData.adlevel_name !== "undefined" ? adlevelData.adlevel_name : ""}
                inputType={"text"}
                onChangeCall={(e) => {
                  if (e.target.value.trim() !== "") {
                    setAdlevelData({ ...adlevelData, adlevel_name: e.target.value });
                  } else {
                    setAdlevelData({ ...adlevelData, adlevel_name: "" });
                  }
                }}
                fieldError={
                  typeof fieldError.error !== "undefined" &&
                  fieldError.error === true &&
                  typeof fieldError.nameAdLevel !== "undefined"
                    ? fieldError.nameAdLevel
                    : ""
                }
              />
            </StyledStack>
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              props.setShowAdlevelModal(false);
            }}
            label="Cancel"
            icon={<Close />}
          />
          <ActionButton onClick={saveAdlevelDetails} label={"Save"} icon={<Save />} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AdlevelModal;
