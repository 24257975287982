import * as React from "react";
import SelectBox from "../../../../components/commonComponents/Selectors/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../../Store/actions";
import { checkUserRole } from "../../../../api_calls/userManagement";
import MultiSelect from "../../../../components/commonComponents/Selectors/MultiSelect";

export default function BrandAnalysisGroupMultiple() {
  const userStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const userRole = checkUserRole();
  let adlevelNames =
    typeof userStateData.adlevel_names !== "undefined" && userStateData.adlevel_names.length !== 0
      ? [
          ...userStateData.adlevel_names.map((adlevelInfo) => {
            return adlevelInfo.label;
          }),
        ].sort()
      : [];

  // if the component is used for user role = brand, then we have to load selected groups only
  if (userRole.user_role === "Brand") {
    // check brand filter object to fetch stores for brand (selected by retailer)
    adlevelNames = userStateData?.brand_filters?.performance_filter?.analysis_group
      ? userStateData.brand_filters.performance_filter.analysis_group.sort()
      : [];
  }

  return (
    <MultiSelect
      enableFullWidth={true}
      label={"Analysis Groups"}
      options={adlevelNames.map((groupName) => {
        return { value: groupName, label: groupName };
      })}
      defaultVal={
        typeof userStateData.brand_filters !== "undefined" &&
        typeof userStateData.brand_filters.performance_filter !== "undefined" &&
        typeof userStateData.brand_filters.performance_filter.analysis_group !== "undefined"
          ? userStateData.brand_filters.performance_filter.analysis_group.map((groupName) => {
              return { value: groupName, label: groupName };
            })
          : []
      }
      selectionCheck={(option, value) => option.label === value.label}
      usedFor="brandAnalysisGroup"
      onChangeCall={(selectedOption) => {
        let userChoices = [];
        const preselectedBrandFilters =
          typeof userStateData.brand_filters !== "undefined" ? userStateData.brand_filters : {};
        selectedOption.forEach((chosen) => {
          if (chosen.label !== "(All)") {
            userChoices.push(chosen.label);
          } else {
            userChoices.push([...new Set(adlevelNames)]);
          }
        });
        dispatchAction(
          updateStateData({
            brand_filters: {
              ...preselectedBrandFilters,
              performance_filter: {
                ...preselectedBrandFilters.performance_filter,
                analysis_group: userChoices,
              },
            },
            report_filter_saved: false,
          })
        );
      }}
    />
  );
}
