import React from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import RetailAnalyticsFilter from "./Filters/RetailAnalyticsFilter";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import { resetBrandReportView, storeListOptionsForTableauFilter } from "../../api_calls/utils";
import { useSelector } from "react-redux";

const Optimization = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [workSpaceId, setWorkSpaceId] = React.useState("");
  React.useEffect(() => {
    const checkWorkSpaces = checkWorkSpaceFromCookies();

    if (
      checkWorkSpaces.success === true &&
      typeof checkWorkSpaces.current_workspace !== "undefined" &&
      typeof checkWorkSpaces.current_workspace.id !== "undefined"
    ) {
      setWorkSpaceId(checkWorkSpaces.current_workspace.id);
    }
    resetBrandReportView();
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ------------- filters -------------- */}
      <RetailAnalyticsFilter singleStore={false} tabVizId={"optimizationViz"} />
      <Box height={1} />
      {/* ----------- tableau dashboard ---------- */}
      <Grid container spacing={2}>
        {workSpaceId !== "" && typeof userStateDate.stores_list !== "undefined" && (
          <TableauDash
            vizId="optimizationViz"
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" + workSpaceId + "_Retail_Analytics/Optimization"
            }
            applyFilter={true}
            filterConfig={{
              store: {
                name: "Name Store",
                values: storeListOptionsForTableauFilter("retail"),
              },
            }}
          />
        )}
      </Grid>
    </StyledMainBox>
  );
};

export default AccessControl(Optimization, "retail_analytics");
