import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PatternImage from "./image/loginPatternImage.svg";
import { LogoImage } from "../../components/commonComponents/StyledComponents/styled";
import WhiteLogo from "../../images/cyreen_logo_white_sRGB _1200.png";
import { useNavigate } from "react-router-dom";
import { DarkGreyButton, LightGreyButton } from "../../components/commonComponents/Buttons/ActionButton";
import { ArrowBack, Backspace, Password } from "@mui/icons-material";
import { toast } from "react-toastify";
import WhiteInputField from "../../components/commonComponents/TextInputs/WhiteTextInput";
import { forgotUserPassword } from "../../api_calls/userManagement";
import { Stack } from "@mui/material";

function Copyright(props) {
  return (
    <Typography variant="body2" color="#fff" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://cyreen.de/">
        Cyreen
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignInSide() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = React.useState("");

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      {/* ---------- login section ------------- */}
      <Grid sx={{ backgroundColor: "rgba(240,75,85,1)" }} item xs={12} sm={8} md={5} component={Paper} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* ---------- cyreen logo --------- */}
          <LogoImage src={WhiteLogo} alt="Logo" width="80" />
          <Box height={10} />
          {/* ---------- login screen text --------- */}
          <Typography component="h1" variant="h4" sx={{ color: "#fff", fontWeight: "bold" }}>
            CAP Explorer
          </Typography>
          <Typography component="h1" variant="h5" sx={{ color: "#fff", fontWeight: "bold" }}>
            Reset your password
          </Typography>
          <Box height={10} />
          {/* -------- login inputs ---------- */}
          <Box component="div" sx={{ mt: 1, width: 300 }}>
            {/* ------ username ------ */}
            <WhiteInputField
              fieldRequired={true}
              elemId="email"
              labelName="Email"
              fieldName="email"
              defaultVal={userEmail}
              onChangeCall={(e) => {
                e.preventDefault();
                setUserEmail(e.target.value);
              }}
            />

            <Grid container alignItems="center" justifyContent="space-between">
              {/* ------ login button ----- */}
              <Grid item xs>
                <Box height={20} />
                <Stack direction={"row"} spacing={4}>
                  <DarkGreyButton
                    label={"Get Instructions"}
                    icon={<Password />}
                    onClick={(e) => {
                      e.preventDefault();
                      forgotUserPassword(userEmail);
                    }}
                  />
                  <a
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      color: "#fff",
                    }}
                    href="#backButton"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    <ArrowBack /> Go back
                  </a>
                </Stack>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
      {/* -------- pattern on right side of the login section ----------- */}
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${PatternImage})`,
          backgroundRepeat: "repeat",
          width: "50%",
          height: "100vh",
          backgroundColor: "rgba(240,75,85,1)",
          backgroundPosition: "0 0",
          backgroundSize: "17px",
          alignSelf: "flex-end",
          marginTop: "-32px",
          paddingTop: "0",
          paddingBottom: "0",
        }}
      />
    </Grid>
  );
}
