import React from "react";
import { Box } from "@mui/material";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import TableauDash from "../../components/commonComponents/TableauDashboard/TableauDash";
import RetailAnalyticsFilter from "./Filters/RetailAnalyticsFilter";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";
import { checkWorkSpaceFromCookies } from "../../api_calls/userManagement";
import { resetBrandReportView, storeListOptionsForTableauFilter, yesterdayDate } from "../../api_calls/utils";
import { useSelector } from "react-redux";

const GeneralKpi = () => {
  const userStateDate = useSelector((state) => state.userSelections);
  const [workSpaceId, setWorkSpaceId] = React.useState("");
  const startDate =
    typeof userStateDate.date_range_start_date !== "undefined"
      ? new Date(userStateDate.date_range_start_date)
      : new Date("2023-02-01");

  React.useEffect(() => {
    const checkWorkSpaces = checkWorkSpaceFromCookies();

    if (
      checkWorkSpaces.success === true &&
      typeof checkWorkSpaces.current_workspace !== "undefined" &&
      typeof checkWorkSpaces.current_workspace.id !== "undefined"
    ) {
      setWorkSpaceId(checkWorkSpaces.current_workspace.id);
    }
    resetBrandReportView();
  }, []);
  return (
    <StyledMainBox component="main">
      {/* ---------- filter section ------------- */}
      <RetailAnalyticsFilter tabVizId={"generalKpiViz"} />
      <Box height={1} />
      {/* ------------ dashboard from tableau ------------ */}
      {workSpaceId !== "" && typeof userStateDate.stores_list !== "undefined" && (
        <Box>
          <TableauDash
            vizId="generalKpiViz"
            vizUrl={
              "https://dub01.online.tableau.com/t/tableaucyreende/views/" +
              workSpaceId +
              "_Retail_Analytics/GeneralDashboard"
            }
            applyFilter={true}
            filterConfig={{
              store: {
                name: "Name Store",
                values: storeListOptionsForTableauFilter("retail"),
              },
              // date_range: {
              //   name: "DAY(Timestamp)",
              //   values: [startDate, yesterdayDate()],
              // },
            }}
          />
        </Box>
      )}
    </StyledMainBox>
  );
};

export default AccessControl(GeneralKpi, "retail_analytics");
