import React from "react";
import { filterSingleValue } from "../../../api_calls/TableauVisuals";
import SelectBox from "../Selectors/SelectBox";
import { loadStores } from "../../../api_calls/campaigns";
import { useDispatch, useSelector } from "react-redux";
import { dbFormatDate, formatDate, storeListOptionsForTableauFilter } from "../../../api_calls/utils";
import { updateStateData } from "../../../Store/actions";
import { checkUserRole } from "../../../api_calls/userManagement";
import MultiSelect from "../Selectors/MultiSelect";

const StoreFilter = ({ vizId, fieldName, usedFor }) => {
  const storeDetails = useSelector((state) => state.userSelections);
  const [changesMade, setChangesMade] = React.useState(false);
  const [storesSelection, setStoreSelection] = React.useState([]);
  const [storeNameOptionSelected, setStoreNameOptionsSelected] = React.useState([]);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [defaultValuesLoaded, setDefaultValuesLoaded] = React.useState(false);

  const userRole = storeDetails?.brand_report_view === true ? { user_role: "Brand" } : checkUserRole();
  const dispatchAction = useDispatch();
  let storeList = storeListOptionsForTableauFilter(usedFor);

  if (userRole.user_role === "Brand") {
    // check brand filter object to fetch stores for brand (selected by retailer)
    storeList = storeDetails?.brand_filters?.impression_filter?.stores
      ? [...["(All)"], ...storeDetails.brand_filters.impression_filter.stores].sort()
      : [];
  }

  // to find a min selectable date based on the selected stores
  const findMinSelectableDate = (selectedValues) => {
    let storeDates = [];
    storeDetails.stores_list.forEach((store) => {
      if (selectedValues.includes(store.name) && typeof store.start_date !== "undefined" && store.start_date !== null) {
        storeDates = [...storeDates, dbFormatDate(store.start_date)];
      }
    });

    storeDates = storeDates.sort();

    dispatchAction(
      updateStateData({
        date_range_start_date: storeDates.length !== 0 ? storeDates[0] : dbFormatDate(new Date()),
      })
    );
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await loadStores();
      setDataLoaded(true);
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (dataLoaded) {
      setStoreSelection(storeList);
      setStoreNameOptionsSelected(
        [...new Set(storeList)].map((storeName) => {
          return { value: storeName, label: storeName };
        })
      );
      findMinSelectableDate(storeList);

      setTimeout(() => {
        setDefaultValuesLoaded(true)
      }, 1000);
    }
  }, [dataLoaded]);

  return defaultValuesLoaded ? (
    <MultiSelect
      enableFullWidth={false}
      label={"Store"}
      options={[...new Set(storeList)].map((storeName) => {
        return { value: storeName, label: storeName };
      })}
      defaultVal={storeNameOptionSelected}
      selectionCheck={(option, value) => option.label === value.label}
      usedFor="storeFilter"
      onChangeCall={(selectedOption) => {
        let userChoices = [];
        setChangesMade(true);
        selectedOption.forEach((chosen) => {
          if (chosen.label !== "(All)") {
            userChoices.push(chosen.label);
          } else {
            userChoices.push([...new Set(storeList)]);
          }
        });
        setStoreSelection(userChoices);
        setStoreNameOptionsSelected(selectedOption);
      }}
      onBlurCall={(e) => {
        if (storesSelection.length !== 0 && changesMade === true) {
          findMinSelectableDate(storesSelection);
          filterSingleValue(fieldName, storesSelection, vizId);
          setChangesMade(false);
        }
      }}
    />
  ) : (
    <></>
  );
};

export default StoreFilter;
