import React from "react";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import {
  CheckBox,
  Devices,
  Receipt,
  SignalWifiStatusbar4Bar,
  SpaceDashboard,
  Store,
  Stream,
  Visibility,
} from "@mui/icons-material";
import TableauDash from "../../../components/commonComponents/TableauDashboard/TableauDash";
import store from "../../../Store/store";
import { checkWorkSpaceFromCookies } from "../../../api_calls/userManagement";
import TotalMonitoringFilter from "./filters/TotalMonitoringFilter";
import StoreMonitoringFilter from "./filters/StoreMonitoringFilter";
import StatusMonitoringFilter from "./filters/StatusMonitoringFilter";
import GraffanaDash from "./GraffanaDash";
import GraffanaFilter from "./filters/GraffanaFilter";
import { storeListOptionsForTableauFilter } from "../../../api_calls/utils";
import RecieptMonitoringFilter from "./filters/RecieptMonitoringFilter";

// to load tab content
function CustomTabPanel(props) {
  const { children, dasboardname, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={false}
      id={`simple-tabpanel-${dasboardname}`}
      aria-labelledby={`simple-tab-${dasboardname}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

// specify mandatory props that custom tab panel will accept
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  dasboardname: PropTypes.number.isRequired,
};

function dashboardMarkup(vizId, vizUrl, applyFilter, filterConfig) {
  return (
    <Grid container spacing={2}>
      <TableauDash vizId={vizId} vizUrl={vizUrl} applyFilter={applyFilter} filterConfig={filterConfig} />
    </Grid>
  );
}

// campaign analytics tabs detail >> label, icon, dashboard and filter
export const tabs = () => {
  const checkWorkSpaces = checkWorkSpaceFromCookies();
  let storeList = storeListOptionsForTableauFilter("monitoring");
  if (
    checkWorkSpaces.success === true &&
    typeof checkWorkSpaces.current_workspace !== "undefined" &&
    typeof checkWorkSpaces.current_workspace.id !== "undefined"
  ) {
    const dirName = checkWorkSpaces.current_workspace.id + "_";
    return [
      {
        label: "Status Monitoring",
        icon: <CheckBox />,
        unique_key: "status_monitor",
        dashboard: dashboardMarkup(
          "status_monitorDash",
          "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "status_monitoring/StatusMonitoring",
          false,
          {
            status_filter: {
              name: "AGG(Status Heartbeat)",
              values: ["(All)", "Online", "Reconnecting", "Offline", "Closed"],
            },
          }
        ),
        filter_component: <StatusMonitoringFilter vizId={"status_monitorDash"} />,
      },
      {
        label: "Live Monitoring",
        icon: <SpaceDashboard />,
        unique_key: "status_monitor",
        dashboard: <GraffanaDash />,
        filter_component: <GraffanaFilter />,
      },
      {
        label: "Total Monitoring",
        icon: <Visibility />,
        unique_key: "total_monitor",
        dashboard: dashboardMarkup(
          "total_monitorDash",
          "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "total_monitoring/TotalMonitoring",
          true,
          {
            store_filter: {
              name: "Store Name",
              values: storeList,
            },
          }
        ),
        filter_component: <TotalMonitoringFilter vizId={"total_monitorDash"} />,
      },
      {
        label: "Store Monitoring",
        icon: <Store />,
        unique_key: "store_monitor",
        dashboard: dashboardMarkup(
          "store_monitorDash",
          "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "store_monitoring/StoreMonitoring",
          true,
          {
            store_filter: {
              name: "Store Name",
              values: storeList[1],
            },
          }
        ),
        filter_component: <StoreMonitoringFilter vizId={"store_monitorDash"} />,
      },
      {
        label: "Receipt Monitoring",
        icon: <Receipt />,
        unique_key: "reciept_monitor",
        dashboard: dashboardMarkup(
          "reciept_monitorDash",
          "https://dub01.online.tableau.com/t/tableaucyreende/views/" + dirName + "Receipts_monitoring/Receipts_monitoring",
          true,
          {
            status_filter: {
              name: "Status",
              values: ["Receipts available but not yet processed", "Receipts not yet received", "Receipts processed"],
            },
          }
        ),
        filter_component: <RecieptMonitoringFilter vizId={"reciept_monitorDash"} />,
      },
    ];
  } else {
    return [];
  }
};
// export const tabs = [
//   {
//     label: "Total Monitoring",
//     icon: <Visibility />,
//     unique_key: "total_monitor",
//     dashboard: dashboardMarkup(
//       "total_monitorDash",
//       "https://dub01.online.tableau.com/t/tableaucyreende/views/TotalMonitoring/TotalMonitoring/5ba7b29e-a0e8-4bb6-ae07-fdd7401ed65d/c100a4e7-0c4b-4072-86df-66daa1fa3368"
//     ),
//     filter_component: <></>,
//   },
//   {
//     label: "Store Monitoring",
//     icon: <Store />,
//     unique_key: "store_monitor",
//     dashboard: dashboardMarkup(
//       "store_monitorDash",
//       "https://dub01.online.tableau.com/t/tableaucyreende/views/ImpressionsMonitoring/StoreMonitoring"
//     ),
//     filter_component: <></>,
//   },
//   {
//     label: "Status Monitoring",
//     icon: <CheckBox />,
//     unique_key: "status_monitor",
//     dashboard: dashboardMarkup(
//       "status_monitorDash",
//       "https://dub01.online.tableau.com/t/tableaucyreende/views/CAPMonitoringStores/StatusMonitoring"
//     ),
//     filter_component: <></>,
//   },
// ];
