import React from "react";
import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomBadge = styled(Chip)(() => ({
  backgroundColor: "#AF3241", // Customize the background color
  color: "#fff", // Customize the text color
  fontWeight: "bold", // Customize the font weight
  fontSize: "12px", // Customize the font size
  padding: "4px 8px", // Customize the padding
}));

export default CustomBadge;
