import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { toast } from "react-toastify";
import {
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from "@mui/material";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import { StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { Close, Delete, Lock, LockOpen, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import { StyledTableCell } from "../../../components/commonComponents/TabularLists/styles";
import {
  clearSectionsList,
  updateSectionsForCampaignReports,
  validateReportSections,
} from "../../../api_calls/ReportSettings";
import Confirmation from "../../../components/commonComponents/ConfirmationBox/Confirmation";

const EditReportSection = ({ showModal, closeModal, campaignId }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldError, setFieldError] = React.useState({});
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const handleFieldChange = (sectionData, index) => {
    const reportSectionData =
      typeof currentStateData.edit_report_section !== "undefined" ? currentStateData.edit_report_section : {};
    const updatedSectionData = [
      ...reportSectionData.slice(0, index), // Copy elements before the updated index
      sectionData, // Update the element at the specified index
      ...reportSectionData.slice(index + 1), // Copy elements after the updated index
    ];
    dispatchAction(
      updateStateData({
        edit_report_section: updatedSectionData,
      })
    );
  };

  const deleteRecord = (index) => {
    const reportSectionData =
      typeof currentStateData.edit_report_section !== "undefined" ? currentStateData.edit_report_section : {};
    const updatedSectionData = [
      ...reportSectionData.slice(0, index), // Copy elements before the updated index
      ...reportSectionData.slice(index + 1), // Copy elements after the updated index
    ];
    dispatchAction(
      updateStateData({
        edit_report_section: updatedSectionData,
      })
    );
  };

  const updateSections = () => {
    const validateData = validateReportSections();
    if (validateData.error === true) {
      setFieldError(validateData.errorDetails);
    } else {
      setShowConfirmation(true);
    }
  };

  React.useEffect(() => {
    if (
      typeof currentStateData.report_settings !== "undefined" &&
      typeof currentStateData.report_settings.available_options !== "undefined"
    ) {
      dispatchAction(
        updateStateData({
          edit_report_section: currentStateData.report_settings.available_options.map((options) => {
            return {
              ...options,
              disable_edit_record: true,
            };
          }),
        })
      );
    }
  }, []);

  return (
    <>
      <Dialog
        open={showModal}
        onClose={(e) => {
          e.preventDefault();
        }}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>Edit Report Sections</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              All changes to the sections are saved on a workspace-level only and apply for all future section settings. The
              removal of a section removes it permanently for the entire workspace.
              <Box height={5} />
              Changes to the sections do not change settings for campaigns for which report settings were already saved in
              the past.
              <Box height={10} />
              <strong>Note: Your changes are saved immediately for the workspace when saving this popup.</strong>
            </Typography>
          </DialogContentText>
          <Box height={20} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 400 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" className="text-wrapper"></StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Section Name (in CAP Explorer)
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Dashboard Name
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {typeof currentStateData.edit_report_section !== "undefined" &&
                  currentStateData.edit_report_section.map((option, index) => {
                    return (
                      <TableRow>
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            {option.disable_edit_record === true ? (
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFieldChange(
                                    {
                                      ...option,
                                      disable_edit_record: false,
                                    },
                                    index
                                  );
                                }}
                              >
                                <Lock sx={{ color: "#AF3241" }} />
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFieldChange(
                                    {
                                      ...option,
                                      disable_edit_record: true,
                                    },
                                    index
                                  );
                                }}
                              >
                                <LockOpen sx={{ color: "#AF3241" }} />
                              </a>
                            )}
                          </StyledStack>
                        </StyledTableCell>
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            <TextInputField
                              fieldRequired={true}
                              readOnlyFlag={option.disable_edit_record}
                              elemId={"sectionName_" + index}
                              label=""
                              fieldName={"sectionName_" + index}
                              showlabel={true}
                              defaultVal={option.tab_text}
                              inputType={"text"}
                              onChangeCall={(e) => {
                                handleFieldChange(
                                  {
                                    ...option,
                                    tab_text: e.target.value,
                                  },
                                  index
                                );
                              }}
                              fieldError={
                                typeof fieldError.error !== "undefined" &&
                                fieldError.error === true &&
                                typeof fieldError["error_" + index] !== "undefined" &&
                                typeof fieldError["error_" + index].tab_text !== "undefined"
                                  ? fieldError["error_" + index].tab_text
                                  : ""
                              }
                              errorStyle="tooltip"
                            />
                          </StyledStack>
                        </StyledTableCell>
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            <TextInputField
                              fieldRequired={true}
                              readOnlyFlag={option.disable_edit_record}
                              elemId={"dashboardName_" + index}
                              label=""
                              fieldName={"dashboardName_" + index}
                              showlabel={true}
                              defaultVal={option.tab_name}
                              inputType={"text"}
                              onChangeCall={(e) => {
                                handleFieldChange(
                                  {
                                    ...option,
                                    tab_name: e.target.value,
                                  },
                                  index
                                );
                              }}
                              fieldError={
                                typeof fieldError.error !== "undefined" &&
                                fieldError.error === true &&
                                typeof fieldError["error_" + index] !== "undefined" &&
                                typeof fieldError["error_" + index].tab_name !== "undefined"
                                  ? fieldError["error_" + index].tab_name
                                  : ""
                              }
                              errorStyle="tooltip"
                            />
                          </StyledStack>
                        </StyledTableCell>
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"row"}>
                            {option.disable_edit_record === true ? (
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Delete sx={{ color: "#62626E" }} />
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  deleteRecord(index);
                                }}
                              >
                                <Delete sx={{ color: "#AF3241" }} />
                              </a>
                            )}
                          </StyledStack>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Box height={20} />
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <CloseButton
              onClick={(e) => {
                e.preventDefault();
                closeModal(false);
              }}
              label="Cancel"
              icon={<Close />}
            />
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                updateSections();
              }}
              label={"Save"}
              icon={<Save />}
            />
          </Stack>
        </DialogActions>
      </Dialog>
      {/* ------- confirmation popup (on click of save button) -------- */}
      {showConfirmation && (
        <Confirmation
          showConfirmationPopup={showConfirmation}
          closePopup={(e) => {
            setShowConfirmation(false);
          }}
          confirmationActionCall={() => {
            updateSectionsForCampaignReports(campaignId).then((info) => {
              if (info.success === true) {
                clearSectionsList();
                closeModal();
              }
            });
          }}
          confirmationMessage={
            typeof currentStateData.report_settings !== "undefined" &&
            typeof currentStateData.report_settings.campaign_settings_saved !== "undefined" &&
            currentStateData.report_settings.campaign_settings_saved === true ? (
              <>
                Saving will update your campaign's section details to the current workspace details. The campaign's
                visibility settings are only adjusted if you save the Report Settings. <Box height={10} /> Are you sure you
                want to proceed?
              </>
            ) : (
              "Are you sure you want to confirm and apply all the changes you have made?"
            )
          }
        />
      )}
    </>
  );
};

export default EditReportSection;
