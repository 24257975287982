import { toast } from "react-toastify";
import { deleteItems, updateStateData } from "../Store/actions";
import store from "../Store/store";
import {
  allNumbersBetweenRange,
  checkStatus,
  checkTodayDate,
  dateInCorrectTimezone,
  dbFormatDate,
  formatToStandardDate,
  isValidFileName,
  standardFormatToday,
  weekDayNames,
  weekDayNum,
} from "./utils";
import platformConfig from "../platformConfig";
import { sendApiRequest, sendApiRequestWithFile } from "./requestConfig";

// clips listing
export const loadClips = async () => {
  const currentStateData = store.getState().userSelections;
  // currentStateData.loading = true;

  const checkClips = await sendApiRequest("clips", "", "GET");

  let response = {};

  if (checkClips.success === true) {
    let clipsList = [];
    let clipNamesList = [];
    /*need to restructure clips response as there's a lot of extra info returned in the response.*/
    if (checkClips.response.length !== 0) {
      //   loop through the clips response
      checkClips.response.forEach((clips) => {
        //decide campaign status by comparing current date with start and end date of campaign.
        let clipStatus = checkStatus(clips.date_start, clips.date_end);

        clipsList.push({
          id: clips.id,
          name: clips.content,
          campaign: clips.campaign.name,
          campaign_id: clips.campaign.id,
          stores: clips.count_stores,
          start_date: clips.date_start,
          end_date: clips.date_end,
          campaign_contacts: clips.contacts,
          unlimited: clips.unlimited,
          last_edit_by: clips.last_edit,
          clip_volume: clips.volume,
          clip_unlimited: clips.unlimited,
          selected_weeks: clips.weeks,
          status: clipStatus,
          selected_days: clips.weekdays.map((day) => {
            return weekDayNames[day];
          }),
          selected_store_options: clips.stores.map((storeInfo) => {
            return { label: storeInfo.name, id: storeInfo.id };
          }),
        });
        clipNamesList.push({ id: clips.id, label: clips.content });
      });
    }
    // save to the redux state
    store.dispatch(
      updateStateData({
        clips_list: clipsList.sort((next, prev) => {
          return formatToStandardDate(prev.start_date) - formatToStandardDate(next.start_date);
        }),
        clip_names_list: clipNamesList,
      })
    );
    // check if filters are already applied, then we need to refresh the filtered list as well.
    if (
      typeof currentStateData.clips_list_filter_applied !== "undefined" &&
      typeof currentStateData.clip_list_filters !== "undefined" &&
      currentStateData.clips_list_filter_applied === true
    ) {
      filterClips();
    }
    response = {
      success: true,
    };
  } else {
    response = {
      success: false,
    };
  }
  // currentStateData.loading = false;
  return response;
};

//to match campaign list records with the filters applied.
const matchClipRecords = (item) => {
  const currentStateData = store.getState().userSelections;
  const appliedFilters = currentStateData.clip_list_filters;

  let itemMatch = false;

  //campaign name filter
  if (appliedFilters.campaign_name.length !== 0) {
    appliedFilters.campaign_name.forEach((filterWord) => {
      if (item.campaign_id === filterWord.id) {
        itemMatch = true;
      }
    });
  }
  // clip name filter
  if (appliedFilters.clip_name.length !== 0) {
    appliedFilters.clip_name.forEach((filterWord) => {
      if (item.name === filterWord.label) {
        itemMatch = true;
      }
    });
  }

  // when duration/progress filter is applied
  if (appliedFilters.status_filter !== "" && appliedFilters.status_filter !== "") {
    appliedFilters.status_filter.forEach((filterWord) => {
      if (item.status === filterWord.label) {
        itemMatch = true;
      }
    });
  }

  return itemMatch;
};

export const filterClips = () => {
  const currentStateData = store.getState().userSelections;
  let filteredData = [];

  //filter live campaigns
  if (typeof currentStateData.clips_list !== "undefined" && currentStateData.clips_list.length !== 0) {
    currentStateData.clips_list.forEach((item) => {
      const checkMatch = matchClipRecords(item);
      if (checkMatch === true) {
        filteredData.push(item);
      }
    });
  }

  store.dispatch(
    updateStateData({
      filtered_clips_list: {
        clips_list: filteredData,
      },
      clips_list_filter_applied: true,
    })
  );

  return true;
};

// validate clips info
export const validateClipsInfo = (validateFor) => {
  const currentStateData = store.getState().userSelections;
  let error = false;
  let errorMessages = {};
  // clip file
  const clipFile =
    typeof document.getElementById("uploadClipFile").files !== "undefined" &&
    document.getElementById("uploadClipFile").files.length !== 0
      ? document.getElementById("uploadClipFile").files[0]
      : "";

  // campaign id
  const campaign =
    typeof currentStateData.new_clip_data !== "undefined" &&
    typeof currentStateData.new_clip_data.campaign_id !== "undefined"
      ? currentStateData.new_clip_data.campaign_id
      : "";
  // start date
  const clipStartDate =
    typeof currentStateData.new_clip_data !== "undefined" &&
    typeof currentStateData.new_clip_data.clip_start_date !== "undefined"
      ? currentStateData.new_clip_data.clip_start_date
      : "";
  // end date
  const clipEndDate =
    typeof currentStateData.new_clip_data !== "undefined" &&
    typeof currentStateData.new_clip_data.clip_end_date !== "undefined"
      ? currentStateData.new_clip_data.clip_end_date
      : "";
  // weeks number (it will be an array)
  let clipWeeks =
    currentStateData.new_clip_data?.clip_weeks || currentStateData.campaign_weeks?.map((week) => week.value) || [];
  // week days (it will also be an array)
  let clipWeekDays = currentStateData.new_clip_data?.clip_week_days || [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // time slots
  const slot_oneStart =
    typeof currentStateData.new_clip_data !== "undefined" &&
    typeof currentStateData.new_clip_data.slot_1_start_time !== "undefined"
      ? currentStateData.new_clip_data.slot_1_start_time
      : 0;
  const slot_oneEnd =
    typeof currentStateData.new_clip_data !== "undefined" &&
    typeof currentStateData.new_clip_data.slot_1_end_time !== "undefined"
      ? currentStateData.new_clip_data.slot_1_end_time
      : 23;

  // contacts
  const clipContacts =
    typeof currentStateData.new_clip_data !== "undefined" &&
    typeof currentStateData.new_clip_data.clip_contacts !== "undefined"
      ? currentStateData.new_clip_data.clip_contacts
      : "";
  const clipVolume =
    typeof currentStateData.new_clip_data !== "undefined" &&
    typeof currentStateData.new_clip_data.clip_volume !== "undefined"
      ? currentStateData.new_clip_data.clip_volume
      : "";
  // unlimited option
  const clipUnlimited =
    typeof currentStateData.new_clip_data !== "undefined" &&
    typeof currentStateData.new_clip_data.clip_unlimited !== "undefined"
      ? currentStateData.new_clip_data.clip_unlimited
      : false;
  // stores
  const clipStores = typeof currentStateData.clip_stores !== "undefined" ? currentStateData.clip_stores : [];

  //check if file is selected or not

  if (!(clipFile instanceof File) && validateFor !== "update") {
    error = true;
    errorMessages = {
      ...errorMessages,
      clip_file: "Please select the clip file",
    };
  } else {
    if (clipFile instanceof File) {
      //validate the file name
      const validateFileName = isValidFileName(clipFile.name);
      const checkFileType = clipFile.type;

      if (!validateFileName) {
        error = true;
        errorMessages = {
          ...errorMessages,
          clip_file: "Invalid file name. It should consist only of alphanumeric characters separated by underscores.",
        };
      } else {
        if (!platformConfig.valid_file_types.includes(checkFileType)) {
          error = true;
          errorMessages = {
            ...errorMessages,
            clip_file: `Invalid file type. Allowed types are: ${platformConfig.valid_file_types.join(", ")}.`,
          };
        }
      }
    }
  }
  // check campaign
  if (campaign === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      clip_campaign: "Please select the campaign",
    };
  }
  // check start date
  if (clipStartDate === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      clip_start_date: "Please select the start date for the clip",
    };
  }
  // check end date
  if (clipEndDate === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      clip_end_date: "Please select the end date for the clip",
    };
  }
  // validate that start should not be after end date and vice versa
  if (clipStartDate !== "" && clipEndDate !== "") {
    const formattedStartDate = clipStartDate; // dateInCorrectTimezone();
    const formattedEndDate = clipEndDate; //dateInCorrectTimezone(clipEndDate);
    //start/end should not be after end date
    if (formattedEndDate < formattedStartDate) {
      error = true;
      errorMessages = {
        ...errorMessages,
        clip_end_date: "End date must be after the start date",
      };
    }
  }
  if (clipStartDate !== "" && clipEndDate !== "" && campaign !== "") {
    let clipStart = clipStartDate;
    // clipStart.setHours(0, 0, 0, 0);
    let clipEnd = clipEndDate;
    // clipEnd.setHours(0, 0, 0, 0);

    // start and end date should lie in the campaign's date range
    let campaignStartDate =
      typeof currentStateData.new_clip_data !== "undefined" &&
      typeof currentStateData.new_clip_data.campaign_start_date !== "undefined"
        ? formatToStandardDate(currentStateData.new_clip_data.campaign_start_date)
        : false;
    let campaignEndDate =
      typeof currentStateData.new_clip_data !== "undefined" &&
      typeof currentStateData.new_clip_data.campaign_end_date !== "undefined"
        ? formatToStandardDate(currentStateData.new_clip_data.campaign_end_date)
        : false;

    if (clipStart < campaignStartDate) {
      error = true;
      errorMessages = {
        ...errorMessages,
        clip_start_date: "Clip start date should be within the campaign start and end date range.",
      };
    }

    if (clipStart > campaignEndDate) {
      error = true;
      errorMessages = {
        ...errorMessages,
        clip_end_date: "Clip end date should be within the campaign start and end date range.",
      };
    }

    if (clipEnd < campaignStartDate) {
      error = true;
      errorMessages = {
        ...errorMessages,
        clip_start_date: "Clip start date should be within the campaign start and end date range.",
      };
    }

    if (clipEnd > campaignEndDate) {
      error = true;
      errorMessages = {
        ...errorMessages,
        clip_end_date: "Clip end date should be within the campaign start and end date range.",
      };
    }
  }
  // check end date
  if (clipWeeks.length === 0 || clipUnlimited === true) {
    // error = true;
    // errorMessages = {
    //   ...errorMessages,
    //   clip_weeks: "Please select the weeks",
    // };
    clipWeeks = currentStateData.campaign_weeks?.map((week) => week.value);
  }
  // week days
  if (clipWeekDays.length === 0) {
    // error = true;
    // errorMessages = {
    //   ...errorMessages,
    //   clip_week_days: "Please select the days",
    // };
    clipWeekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  }
  // check slots
  if (slot_oneStart === "" && slot_oneEnd === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      clip_time_slot: "Please select the time slot",
    };
  }
  // check contacts
  if (clipUnlimited === false && clipContacts === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      clip_contacts: "Please select the contacts",
    };
  }
  // check volume
  if (clipVolume === "") {
    error = true;
    errorMessages = {
      ...errorMessages,
      clip_volume: "Please select the volume",
    };
  }
  // check stores
  if (clipStores.length === 0) {
    error = true;
    errorMessages = {
      ...errorMessages,
      clip_stores: "Please select the stores",
    };
  }

  // show error message (if error === true)
  if (error === true) {
    toast("Please fill in valid information", { type: "error" });
    return {
      error: true,
      errorMessages,
    };
  } else {
    const formData = new FormData();
    const storesArr = currentStateData.stores_list.filter((store) => {
      return clipStores.includes(store.name) ? store : false;
    });

    let weekNums =
      typeof currentStateData.campaign_weeks !== "undefined"
        ? clipWeeks.filter((clipWeekNum) => {
            const campaignWeekNumArr = currentStateData.campaign_weeks.map((campaignWeekArr) => {
              return campaignWeekArr.value;
            });

            if (campaignWeekNumArr.includes(clipWeekNum)) {
              return true;
            } else {
              return false;
            }
          })
        : clipWeeks;

    let dataObject = {
      id_campaign: parseInt(campaign),
      date_start: dbFormatDate(new Date(clipStartDate)),
      date_end: dbFormatDate(new Date(clipEndDate)),
      hours: allNumbersBetweenRange(slot_oneStart, slot_oneEnd),
      weeks: weekNums.length !== 0 ? weekNums : currentStateData.campaign_weeks?.map((week) => week.value),
      weekdays: clipWeekDays.map((weekDay) => {
        return weekDayNum[weekDay];
      }),
      contacts: clipUnlimited === false ? parseInt(clipContacts) : 0,
      volume: parseInt(clipVolume),
      unlimited: clipUnlimited,
      stores: storesArr,
    };

    if (validateFor === "update") {
      dataObject = {
        ...dataObject,
        id: currentStateData.new_clip_data.id,
      };
    }

    // Append file field
    if (clipFile instanceof File) {
      formData.append("file", clipFile);
      dataObject = {
        ...dataObject,
        content: clipFile.name,
      };
    } else {
      if (validateFor === "update") {
        dataObject = {
          ...dataObject,
          content: currentStateData.new_clip_data.content_name,
        };
      }
    }

    // Append non-file fields
    // formData.append("data", JSON.stringify(dataObject));

    // return validated request body for the clips add/update operation
    return {
      error: false,
      clips_file_request: clipFile instanceof File ? formData : "",
      clips_request: dataObject,
    };
  }
};

// to save clips information at backend
export const saveClipsInformation = async (usedfor) => {
  const validateInfo = validateClipsInfo(usedfor);
  let successFlag = false;
  let clipsResponse = {};
  if (validateInfo.error === true) {
    clipsResponse = {
      ...clipsResponse,
      success: false,
      errorMessages: validateInfo.errorMessages,
    };
  } else {
    const apiEndpoint = usedfor === "update" ? "clips/update" : "clips/add";
    const apiMethod = usedfor === "update" ? "PUT" : "POST";
    const apiRequestParam = validateInfo.clips_request;

    // we will upload the file first (if file request obj is returned from validate funtion)
    if (validateInfo.clips_file_request !== "") {
      // upload file to s3
      const saveClipFile = await sendApiRequestWithFile("api/bucket/clip/upload", validateInfo.clips_file_request, "POST");
      // file upload successful, then save other clip information i.e., campaign, stores etc.
      if (typeof saveClipFile.success !== "undefined" && saveClipFile.success === true) {
        // send another request to save clips information, previous api call will only upload the clip
        const saveClipInfo = await sendApiRequest(apiEndpoint, apiRequestParam, apiMethod);
        // validate whether clips info api succeeded or failed
        if (typeof saveClipInfo.success !== "undefined" && saveClipInfo.success === true) {
          successFlag = true;
        } else {
          successFlag = false;
        }
      } else {
        successFlag = false;
      }
    } else {
      // send request to save clips information, file upload api is not needed in this case because file request obj is sent as empty from validate function
      const saveClipInfo = await sendApiRequest(apiEndpoint, apiRequestParam, apiMethod);
      // validate api succeeded or not
      if (typeof saveClipInfo.success !== "undefined" && saveClipInfo.success === true) {
        successFlag = true;
      } else {
        successFlag = false;
      }
    }

    if (successFlag === true) {
      const successMessage = usedfor === "update" ? "Clip updated successfully" : "Clip added successfully";
      clipsResponse = {
        ...clipsResponse,
        success: true,
        errorMessages: "",
      };
      loadClips();
      toast(successMessage, { type: "success" });
    } else {
      toast("Sorry, Something went wrong", { type: "error" });
      clipsResponse = {
        ...clipsResponse,
        success: false,
        errorMessages: "Sorry, Something went wrong",
      };
    }
  }

  return clipsResponse;
};

export const resetClipsData = () => {
  store.dispatch(
    deleteItems([
      "new_clip_data",
      "campaign_weeks",
      "clip_stores",
      "filtered_stores",
      "filtered_regions",
      "selected_region_ids",
      "selected_region_names",
      "selected_region_options",
      "selected_store_options",
    ])
  );
};

// fetch s3 url for the clip file
export const fetchClipUrl = async (clipName) => {
  const sendRequest = await sendApiRequest("api/bucket/clip/generate-url/" + clipName, {}, "GET");
  if (typeof sendRequest.success !== "undefined" && sendRequest.success === true) {
    return {
      success: true,
      clip_file_url: sendRequest.response,
    };
  } else {
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  }
};
