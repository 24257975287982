import React from "react";
import { Box, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { LeftStyledTableCell, StyledTableCell } from "../../../../components/commonComponents/TabularLists/styles";
import {
  HtmlTooltip,
  IconContainer,
  StyledInformationSpan,
  StyledLeftAlignedStack,
  StyledMainBox,
  StyledStack,
  StyledSwitch,
  StyledWhiteSwitch,
} from "../../../../components/commonComponents/StyledComponents/styled";
import MaskedInput from "../../../../components/commonComponents/TextInputs/MaskedInput";
import NumericInputField from "../../../../components/commonComponents/TextInputs/NumericInputField";
import ActionButton, { DarkGreyButton } from "../../../../components/commonComponents/Buttons/ActionButton";
import { Add, Close, Download, Error, Info, Save, SubdirectoryArrowRight } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../../Store/actions";
import {
  defineDevicesList,
  discardChanges,
  saveDevicesInfo,
  trackChanges,
  validateDeviceList,
  verifyStoreIP,
} from "../../../../api_calls/infrastructure";
import * as xlsx from "xlsx";
import { CustomTextInputCenterAligned } from "../../../../components/commonComponents/TextInputs/style";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Confirmation from "../../ModalContent/Confirmation";
import StoreConfirmation from "../../ModalContent/StoreConfirmation";
import CloseButton from "../../../../components/commonComponents/Buttons/CloseButton";

import moment from "moment-timezone";
import { validateNumbers } from "../../../../api_calls/utils";

const Devices = () => {
  const userStateDetails = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldError, setFieldError] = React.useState({});
  const [storeIPError, setStoreIpError] = React.useState({});
  const { id } = useParams();

  const [isError, setIsError] = React.useState(false);
  const [allDeviceStatus, setAllDeviceStatus] = React.useState(false);

  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [showStoreConfirmation, setShowStoreConfirmation] = React.useState(false);
  const [deviceStatusConfirmation, setDevicestatusConfirmation] = React.useState(false);
  const [statusToUpdate, setStatusToUpdate] = React.useState(false);

  const [showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);
  const trackInfraChanges =
    typeof userStateDetails.track_infra_changes !== "undefined" ? userStateDetails.track_infra_changes : false;
  const storeName = typeof userStateDetails.store_overview !== "undefined" ? userStateDetails.store_overview?.name : "";
  const automatedCheckouts =
    typeof userStateDetails.store_overview !== "undefined" &&
    typeof userStateDetails.store_overview.automated_checkout !== "undefined"
      ? userStateDetails.store_overview?.automated_checkout
      : true;

  const validateDeviceCounts = () => {
    setFieldError({});
    setIsError(false);
    const selectedDeviceCounts =
      typeof userStateDetails.device_count_info !== "undefined" ? userStateDetails.device_count_info : {};

    const fixedDeviceConstraints =
      typeof userStateDetails.db_device_count !== "undefined"
        ? userStateDetails.db_device_count
        : {
            screen_area_reader: 0,
            checkout_reader: 0,
            checkin_reader: 0,
            self_checkout_reader: 0,
            instore_reader_without_screen: 0,
          };
    let error = false;
    let errorMessages = {};

    if (typeof selectedDeviceCounts.screen_area_reader !== "undefined") {
      const validateScreenNumber = validateNumbers(selectedDeviceCounts.screen_area_reader);
      if (validateScreenNumber.error === true) {
        error = true;
        errorMessages = {
          ...errorMessages,
          screen_area_reader: "The count can not be empty and may not contain letters",
        };
      } else {
        if (selectedDeviceCounts?.screen_area_reader < fixedDeviceConstraints.screen_area_reader) {
          error = true;
          errorMessages = {
            ...errorMessages,
            screen_area_reader:
              "The count can not be empty and lower than " +
              fixedDeviceConstraints.screen_area_reader +
              " for this device type",
          };
        }
      }
    }

    if (typeof selectedDeviceCounts.checkout_reader !== "undefined") {
      const validateCheckoutNumber = validateNumbers(selectedDeviceCounts.checkout_reader);
      if (validateCheckoutNumber.error === true) {
        error = true;
        errorMessages = {
          ...errorMessages,
          checkout_reader: "The count can not be empty and may not contain letters",
        };
      } else {
        if (selectedDeviceCounts?.checkout_reader < fixedDeviceConstraints.checkout_reader) {
          error = true;
          errorMessages = {
            ...errorMessages,
            checkout_reader:
              "The count can not be empty and lower than " +
              fixedDeviceConstraints.checkout_reader +
              " for this device type",
          };
        }
      }
    }
    if (typeof selectedDeviceCounts.checkin_reader !== "undefined") {
      const validateCheckInNumber = validateNumbers(selectedDeviceCounts.checkin_reader);
      if (validateCheckInNumber.error === true) {
        error = true;
        errorMessages = {
          ...errorMessages,
          checkin_reader: "The count can not be empty and may not contain letters",
        };
      } else {
        if (selectedDeviceCounts?.checkin_reader < fixedDeviceConstraints.checkin_reader) {
          error = true;
          errorMessages = {
            ...errorMessages,
            checkin_reader:
              "The count can not be empty and lower than " +
              fixedDeviceConstraints.checkin_reader +
              " for this device type",
          };
        }
      }
    }
    if (typeof selectedDeviceCounts.self_checkout_reader !== "undefined") {
      const validateSelfCheckoutNumber = validateNumbers(selectedDeviceCounts.self_checkout_reader);
      if (validateSelfCheckoutNumber.error === true) {
        error = true;
        errorMessages = {
          ...errorMessages,
          self_checkout_reader: "The count can not be empty and may not contain letters",
        };
      } else {
        if (selectedDeviceCounts?.self_checkout_reader < fixedDeviceConstraints.self_checkout_reader) {
          error = true;
          errorMessages = {
            ...errorMessages,
            self_checkout_reader:
              "The count can not be empty and lower than " +
              fixedDeviceConstraints.self_checkout_reader +
              " for this device type",
          };
        }
      }
    }
    if (typeof selectedDeviceCounts.instore_reader_without_screen !== "undefined") {
      const validateInstoreNumber = validateNumbers(selectedDeviceCounts.instore_reader_without_screen);
      if (validateInstoreNumber.error === true) {
        error = true;
        errorMessages = {
          ...errorMessages,
          instore_reader_without_screen: "The count can not be empty and may not contain letters",
        };
      } else {
        if (selectedDeviceCounts?.instore_reader_without_screen < fixedDeviceConstraints.instore_reader_without_screen) {
          error = true;
          errorMessages = {
            ...errorMessages,
            instore_reader_without_screen:
              "The count can not be empty and lower than " +
              fixedDeviceConstraints.instore_reader_without_screen +
              " for this device type",
          };
        }
      }
    }

    setFieldError(errorMessages);
    setIsError(error);
    return {
      error,
      errorMessages,
    };
  };

  const handleFieldChange = (deviceData) => {
    setFieldError({});
    setIsError(false);
    const selectedDeviceCounts =
      typeof userStateDetails.device_count_info !== "undefined" ? userStateDetails.device_count_info : {};
    dispatchAction(
      updateStateData({
        device_count_info: { ...selectedDeviceCounts, ...deviceData },
      })
    );
  };

  const duplicateOctetCheck = () => {
    setFieldError({});
    // validate for duplicateValue
    const validateInfo = validateDeviceList();
    if (validateInfo.error === true) {
      const errorIndexArr = Object.keys(validateInfo.errorDetails).map((key) => {
        return parseInt(key);
      });
      setFieldError({
        index: errorIndexArr,
        error_message: validateInfo.errorDetails[errorIndexArr[0]],
        area_reader_error_indexes: validateInfo.areaReaderErrorIndexes,
      });
    }
  };

  const handleLastOctetChange = (octetInfo, index) => {
    let addedDeviceList = typeof userStateDetails.device_list !== "undefined" ? userStateDetails.device_list : [];
    const updatedDeviceList = [
      ...addedDeviceList.slice(0, index), // Copy elements before the updated index
      octetInfo, // Update the element at the specified index
      ...addedDeviceList.slice(index + 1), // Copy elements after the updated index
    ];
    trackChanges(true);
    dispatchAction(
      updateStateData({
        device_list: updatedDeviceList,
      })
    );
    setDefaultValForDeviceStatusSwitch(updatedDeviceList);
  };

  const addDevices = async () => {
    setStoreIpError({});
    setIsError(false);
    // ensure that store ip is defined before we actually define devices list
    const storeIpInfo = await verifyStoreIP(id);
    const validateCounts = validateDeviceCounts();

    if (storeIpInfo.error === true) {
      setStoreIpError(storeIpInfo);
      setIsError(true);
    } else {
      if (validateCounts.error === false) {
        trackChanges(true);
        defineDevicesList();
      } else {
        toast("Please fix the errors", { type: "error" });
        setFieldError(validateCounts.errorMessages);
        setIsError(validateCounts.error);
      }
    }
  };

  const saveDevices = (e) => {
    e.preventDefault();
    setFieldError({});
    const validateInfo = validateDeviceList();

    if (validateInfo.error === true) {
      toast("Saving not successful. Please verify your input and try again.", { type: "error" });
      setFieldError({
        index: Object.keys(validateInfo.errorDetails).map((key) => {
          return parseInt(key);
        }),
        error_message: "Last octet can't be empty or have duplicate octet value",
        area_reader_error_indexes: validateInfo.areaReaderErrorIndexes,
      });
    } else {
      // check store and device status
      if (userStateDetails?.store_overview?.active === false && validateInfo.deviceStatusArray.includes(true)) {
        // need to show a different confirmation if store is inactive and any of the device is set to be true
        setShowStoreConfirmation(true);
      } else {
        setShowConfirmation(true);
      }
    }
  };

  const exportToExcel = (deviceList) => {
    // Convert JSON data to an array of arrays
    const data = [];

    data.push(["#", "Device Type", "IP Address", "Device Active"]); // Add headers

    deviceList.forEach((device, index) => {
      const row = [];
      row.push(index + 1, device.type, device.ip_address, device.active);
      data.push(row);
      if (typeof device.area_reader !== "undefined") {
        const rowArea = [];
        rowArea.push("", device.area_reader.type, device.area_reader.ip_address, device.area_reader.active);
        data.push(rowArea);
      }
    });

    // Create a new workbook and add a worksheet
    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    let exportFileName = moment().startOf("day").format("YYYYMMDD") + "_" + id + "_" + storeName + "_Devices.xlsx";

    // Save the workbook to an Excel file
    xlsx.writeFile(workbook, exportFileName);
  };

  const setDeviceStatusForAll = (statusVal) => {
    if (typeof userStateDetails.device_list !== "undefined" && userStateDetails.device_list.length !== 0) {
      trackChanges(true);
      let updatedDeviceList = [];
      setAllDeviceStatus(statusVal);
      userStateDetails.device_list.forEach((deviceObj) => {
        // set stauts for area reader
        if (typeof deviceObj.area_reader !== "undefined") {
          updatedDeviceList = [
            ...updatedDeviceList,
            {
              ...deviceObj,
              active: statusVal,
              area_reader: {
                ...deviceObj.area_reader,
                active: statusVal,
              },
            },
          ];
        } else {
          updatedDeviceList = [
            ...updatedDeviceList,
            {
              ...deviceObj,
              active: statusVal,
            },
          ];
        }
      });

      dispatchAction(
        updateStateData({
          device_list: [...updatedDeviceList],
        })
      );
    }
  };

  const cancelModifications = (e) => {
    e.preventDefault();
    if (trackInfraChanges === true) {
      setShowCancelConfirmation(true);
    }
  };

  const setDefaultValForDeviceStatusSwitch = (devicesList) => {
    if (typeof devicesList !== "undefined" && devicesList.length !== 0) {
      let statusVal = true;
      let checkDeviceStatus = [];
      devicesList.forEach((deviceObj) => {
        checkDeviceStatus.push(deviceObj.active);
        // set stauts for area reader
        if (typeof deviceObj.area_reader !== "undefined") {
          checkDeviceStatus.push(deviceObj.area_reader.active);
        }
      });
      if (checkDeviceStatus.includes(false)) {
        statusVal = false;
      }
      setAllDeviceStatus(statusVal);
    }
  };

  React.useEffect(() => {
    if (typeof userStateDetails.device_list !== "undefined" && userStateDetails.device_list.length !== 0) {
      setDefaultValForDeviceStatusSwitch(userStateDetails.device_list);
    }
  }, []);

  return (
    <StyledMainBox component="main">
      {/* ----------- store ip ------------ */}
      <h3>Network:</h3>
      <Box>
        <MaskedInput
          parentError={typeof storeIPError.error !== "undefined" ? storeIPError.error : false}
          errorMessage={storeIPError?.error_message}
        />
      </Box>
      {/* ------------- device counts --------- */}
      <Box height={30} />
      <h3>Device Count:</h3>
      <Box>
        <StyledStack direction={"row"} spacing={2}>
          {/* ----- area reader including screen ------- */}
          <NumericInputField
            fieldRequired={true}
            elemId="screen_area_reader"
            label="Screen incl. Area Reader"
            fieldName="screen_area_reader"
            errorStyle="tooltip"
            defaultVal={
              typeof userStateDetails.device_count_info !== "undefined" &&
              typeof userStateDetails.device_count_info.screen_area_reader !== "undefined"
                ? userStateDetails.device_count_info.screen_area_reader
                : 0
            }
            onChangeCall={(e) => {
              handleFieldChange({
                screen_area_reader: e.target.value,
              });
            }}
            onBlurCall={(e) => {
              if (e.target.value.trim() === "") {
                handleFieldChange({
                  screen_area_reader:
                    typeof userStateDetails.db_device_count !== "undefined" &&
                    typeof userStateDetails.db_device_count.screen_area_reader !== "undefined"
                      ? userStateDetails.db_device_count.screen_area_reader
                      : 0,
                });
              } else {
                validateDeviceCounts();
              }
            }}
            fieldError={fieldError?.screen_area_reader}
          />

          {/* ----- checkout reader ------- */}
          {automatedCheckouts === true && (
            <NumericInputField
              fieldRequired={true}
              elemId="checkout_reader"
              label="Checkout Reader"
              fieldName="checkout_reader"
              errorStyle="tooltip"
              defaultVal={
                typeof userStateDetails.device_count_info !== "undefined" &&
                typeof userStateDetails.device_count_info.checkout_reader !== "undefined"
                  ? userStateDetails.device_count_info.checkout_reader
                  : 0
              }
              onChangeCall={(e) => {
                handleFieldChange({
                  checkout_reader: e.target.value,
                });
              }}
              onBlurCall={(e) => {
                if (e.target.value.trim() === "") {
                  handleFieldChange({
                    checkout_reader:
                      typeof userStateDetails.db_device_count !== "undefined" &&
                      typeof userStateDetails.db_device_count.checkout_reader !== "undefined"
                        ? userStateDetails.db_device_count.checkout_reader
                        : 0,
                  });
                } else {
                  validateDeviceCounts();
                }
              }}
              fieldError={fieldError?.checkout_reader}
            />
          )}

          {/* ----- checkin reader ------- */}
          <NumericInputField
            fieldRequired={true}
            elemId="checkin_reader"
            label="Checkin Reader"
            errorStyle="tooltip"
            fieldName="checkin_reader"
            defaultVal={
              typeof userStateDetails.device_count_info !== "undefined" &&
              typeof userStateDetails.device_count_info.checkin_reader !== "undefined"
                ? userStateDetails.device_count_info.checkin_reader
                : 0
            }
            onChangeCall={(e) => {
              handleFieldChange({
                checkin_reader: e.target.value,
              });
            }}
            onBlurCall={(e) => {
              if (e.target.value.trim() === "") {
                handleFieldChange({
                  checkin_reader:
                    typeof userStateDetails.db_device_count !== "undefined" &&
                    typeof userStateDetails.db_device_count.checkin_reader !== "undefined"
                      ? userStateDetails.db_device_count.checkin_reader
                      : 0,
                });
              } else {
                validateDeviceCounts();
              }
            }}
            fieldError={fieldError?.checkin_reader}
          />
          {/* ----- self checkout reader ------- */}
          {automatedCheckouts === true && (
            <NumericInputField
              fieldRequired={true}
              elemId="self_checkout_reader"
              label="Self-Checkout Reader"
              errorStyle="tooltip"
              fieldName="self_checkout_reader"
              defaultVal={
                typeof userStateDetails.device_count_info !== "undefined" &&
                typeof userStateDetails.device_count_info.self_checkout_reader !== "undefined"
                  ? userStateDetails.device_count_info.self_checkout_reader
                  : 0
              }
              onChangeCall={(e) => {
                handleFieldChange({
                  self_checkout_reader: e.target.value,
                });
              }}
              onBlurCall={(e) => {
                if (e.target.value.trim() === "") {
                  handleFieldChange({
                    self_checkout_reader:
                      typeof userStateDetails.db_device_count !== "undefined" &&
                      typeof userStateDetails.db_device_count.self_checkout_reader !== "undefined"
                        ? userStateDetails.db_device_count.self_checkout_reader
                        : 0,
                  });
                } else {
                  validateDeviceCounts();
                }
              }}
              fieldError={fieldError?.self_checkout_reader}
            />
          )}

          {/* ------- instore reader without screen --------- */}
          <NumericInputField
            fieldRequired={true}
            elemId="instore_reader_without_screen"
            label="In-Store Reader without Screen"
            fieldName="instore_reader_without_screen"
            errorStyle="tooltip"
            defaultVal={
              typeof userStateDetails.device_count_info !== "undefined" &&
              typeof userStateDetails.device_count_info.instore_reader_without_screen !== "undefined"
                ? userStateDetails.device_count_info.instore_reader_without_screen
                : 0
            }
            onChangeCall={(e) => {
              handleFieldChange({
                instore_reader_without_screen: e.target.value,
              });
            }}
            onBlurCall={(e) => {
              if (e.target.value.trim() === "") {
                handleFieldChange({
                  instore_reader_without_screen:
                    typeof userStateDetails.db_device_count !== "undefined" &&
                    typeof userStateDetails.db_device_count.instore_reader_without_screen !== "undefined"
                      ? userStateDetails.db_device_count.instore_reader_without_screen
                      : 0,
                });
              } else {
                validateDeviceCounts();
              }
            }}
            fieldError={fieldError?.instore_reader_without_screen}
          />
        </StyledStack>
        <Box height={30} />
        <Stack direction={"row"} spacing={2}>
          {/* ------------ Add device button ---------- */}
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              addDevices();
            }}
            label={userStateDetails?.db_device_count ? "Update Device(s)" : "Add Device(s)"}
            icon={<Add />}
          />
        </Stack>
      </Box>

      {typeof userStateDetails.device_list !== "undefined" && userStateDetails.device_list.length !== 0 && (
        <>
          <Box height={30} />
          <h3>Device Mapping:</h3>
          <Box height={2} />
          <StyledInformationSpan sx={{ fontSize: "15px" }}>
            Please exercise careful consideration when altering the following information.
          </StyledInformationSpan>
          <StyledInformationSpan sx={{ fontSize: "15px" }}>
            Any modifications made without proper understanding could potentially disrupt the system's functionality.
          </StyledInformationSpan>
          <Box height={25} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <LeftStyledTableCell align="left" className="text-wrapper">
                    #
                  </LeftStyledTableCell>
                  <LeftStyledTableCell align="left" className="text-wrapper">
                    Device Type
                  </LeftStyledTableCell>
                  <LeftStyledTableCell align="left" className="text-wrapper">
                    IP Address
                  </LeftStyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    <StyledStack spacing={2} direction={"row"}>
                      <StyledWhiteSwitch
                        checked={allDeviceStatus}
                        onChange={(e) => {
                          setDevicestatusConfirmation(true);
                          setStatusToUpdate(e.target.checked);
                        }}
                      />
                      <div>Device Active </div>
                      <HtmlTooltip
                        title={
                          <Box width={300}>
                            <StyledMainBox>
                              <h3>Inactive Devices</h3>
                              <Box height={5} />
                              <Typography variant="body2">
                                <strong>Screen & Area Reader:</strong> No clip playouts are triggered and no advertising
                                impression data is collected.
                              </Typography>

                              <Box height={10} />
                              <Typography variant="body2">
                                <strong>Checkout Reader:</strong> No analysis of purchase behavior connected to advertising
                                exposure possible.
                              </Typography>
                              <Box height={10} />
                              <Typography variant="body2">
                                <strong>Checkin Reader:</strong> No information about shoppers entering the store is
                                collected.
                              </Typography>
                              <Box height={10} />
                              <Typography variant="body2">
                                <strong>Self-Checkout Reader:</strong> No shopper events in the self-checkout area are
                                collected.
                              </Typography>
                              <Box height={10} />
                              <Typography variant="body2">
                                <strong>In-Store Reader without Screen:</strong> No shopper events at additional tracking
                                points in the store are collected.
                              </Typography>
                            </StyledMainBox>
                          </Box>
                        }
                      >
                        <Info sx={{ fontSize: "18px" }} />
                      </HtmlTooltip>
                    </StyledStack>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {typeof userStateDetails.device_list !== "undefined" &&
                  userStateDetails.device_list.map((deviceInfo, index) => {
                    return (
                      <TableRow
                        key={index + "_device_row"}
                        sx={{ backgroundColor: deviceInfo.color_code, borderBottomColor: "#2D2D37" }}
                      >
                        <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                          <div
                            style={{
                              paddingTop: "8px",
                              fontSize: "15px",
                            }}
                          >
                            {index + 1}
                          </div>
                        </LeftStyledTableCell>
                        <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledLeftAlignedStack direction={"column"} spacing={2}>
                            <Box width={250}>
                              <div
                                style={{
                                  paddingTop: "8px",
                                  fontSize: "15px",
                                }}
                              >
                                {deviceInfo.type}
                              </div>
                            </Box>

                            {typeof deviceInfo.area_reader !== "undefined" && (
                              <Box width={250}>
                                <SubdirectoryArrowRight /> Area Reader
                              </Box>
                            )}
                          </StyledLeftAlignedStack>
                        </LeftStyledTableCell>
                        <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledLeftAlignedStack direction={"column"} spacing={2}>
                            <Box width={150}>
                              <StyledLeftAlignedStack direction={"row"} spacing={1}>
                                <div
                                  style={{
                                    paddingTop: "8px",
                                    fontSize: "15px",
                                  }}
                                >
                                  {deviceInfo.type === "Master Screen" ? (
                                    <strong>{deviceInfo.ip_address}</strong>
                                  ) : (
                                    <strong>{deviceInfo.store_ip + "."}</strong>
                                  )}
                                </div>

                                {deviceInfo.type !== "Master Screen" && (
                                  <CustomTextInputCenterAligned
                                    variant="standard"
                                    margin="none"
                                    sx={{ minWidth: "50px" }}
                                    type={"text"}
                                    value={deviceInfo.last_octet}
                                    onChange={(e) => {
                                      handleLastOctetChange(
                                        {
                                          ...deviceInfo,
                                          last_octet: e.target.value,
                                          ip_address: deviceInfo.store_ip + "." + e.target.value,
                                        },
                                        index
                                      );
                                    }}
                                    onBlur={(e) => {
                                      duplicateOctetCheck(
                                        {
                                          ...deviceInfo,
                                          last_octet: e.target.value,
                                          ip_address: deviceInfo.store_ip + "." + e.target.value,
                                        },
                                        index
                                      );
                                    }}
                                  />
                                )}

                                {/* ------ error message ------ */}
                                {typeof fieldError.index !== "undefined" &&
                                  fieldError.index.includes(index) &&
                                  !fieldError.area_reader_error_indexes.includes(index) && (
                                    <HtmlTooltip title={fieldError.error_message}>
                                      <IconContainer>
                                        <Error sx={{ color: "#AF3241" }} />
                                      </IconContainer>
                                    </HtmlTooltip>
                                  )}
                              </StyledLeftAlignedStack>
                            </Box>
                            {typeof deviceInfo.area_reader !== "undefined" && (
                              <>
                                {/* <Box width={5} /> */}
                                <Box width={150}>
                                  <StyledLeftAlignedStack direction={"row"} spacing={1}>
                                    <div
                                      style={{
                                        paddingTop: "8px",
                                        fontSize: "15px",
                                      }}
                                    >
                                      <strong>{deviceInfo.area_reader.store_ip + "."}</strong>
                                    </div>

                                    <CustomTextInputCenterAligned
                                      variant="standard"
                                      type={"text"}
                                      sx={{ minWidth: "50px" }}
                                      value={deviceInfo.area_reader.last_octet}
                                      onChange={(e) => {
                                        handleLastOctetChange(
                                          {
                                            ...deviceInfo,
                                            area_reader: {
                                              ...deviceInfo.area_reader,
                                              last_octet: e.target.value,
                                              ip_address: deviceInfo.store_ip + "." + e.target.value,
                                            },
                                          },
                                          index
                                        );
                                      }}
                                      onBlur={(e) => {
                                        duplicateOctetCheck(
                                          {
                                            ...deviceInfo,
                                            last_octet: e.target.value,
                                            ip_address: deviceInfo.store_ip + "." + e.target.value,
                                          },
                                          index
                                        );
                                      }}
                                    />

                                    {typeof fieldError.index !== "undefined" &&
                                      fieldError.index.includes(index) &&
                                      fieldError.area_reader_error_indexes.includes(index) && (
                                        <HtmlTooltip title={fieldError.error_message}>
                                          <IconContainer>
                                            <Error sx={{ color: "#AF3241" }} />
                                          </IconContainer>
                                        </HtmlTooltip>
                                      )}
                                  </StyledLeftAlignedStack>
                                </Box>
                              </>
                            )}
                          </StyledLeftAlignedStack>
                        </LeftStyledTableCell>
                        <StyledTableCell component="td" scope="row" className="text-wrapper">
                          <StyledStack direction={"column"} spacing={1}>
                            <Box width={120}>
                              <StyledSwitch
                                checked={deviceInfo.active}
                                onChange={(e) => {
                                  if (typeof deviceInfo.area_reader !== "undefined") {
                                    handleLastOctetChange(
                                      {
                                        ...deviceInfo,
                                        active: e.target.checked,
                                        area_reader: {
                                          ...deviceInfo.area_reader,
                                          active: e.target.checked,
                                        },
                                      },
                                      index
                                    );
                                  } else {
                                    handleLastOctetChange(
                                      {
                                        ...deviceInfo,
                                        active: e.target.checked,
                                      },
                                      index
                                    );
                                  }
                                }}
                              />
                            </Box>
                            {/* {typeof deviceInfo.area_reader !== "undefined" && (
                              <>
                               
                                <Box width={120}>
                                  <StyledSwitch
                                    checked={deviceInfo.area_reader.active}
                                    onChange={(e) => {
                                      handleLastOctetChange(
                                        {
                                          ...deviceInfo,
                                          area_reader: {
                                            ...deviceInfo.area_reader,
                                            active: e.target.checked,
                                          },
                                        },
                                        index
                                      );
                                    }}
                                  />
                                </Box>
                              </>
                            )} */}
                          </StyledStack>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box height={40} />
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box>
              <CloseButton onClick={cancelModifications} label="Cancel" icon={<Close />} />
            </Box>
            <Box display="flex" alignItems="center">
              {userStateDetails?.db_device_list && (
                <DarkGreyButton
                  onClick={(e) => {
                    e.preventDefault();
                    exportToExcel(userStateDetails?.db_device_list);
                  }}
                  label="Download XLSX"
                  icon={<Download />}
                />
              )}

              <Box ml={2}>
                {/* Add margin between the buttons */}
                <ActionButton onClick={saveDevices} label="Save Devices" icon={<Save />} />
              </Box>
            </Box>
          </Box>

          {/* ------- confirmation popup (on click of save button) -------- */}
          {showConfirmation && (
            <Confirmation
              showConfirmationPopup={showConfirmation}
              closePopup={(e) => {
                setShowConfirmation(false);
              }}
              confirmationActionCall={() => {
                trackChanges(false);
                setFieldError({});
                saveDevicesInfo(id);
              }}
              confirmationMessage="Are you sure you want to confirm and apply all the changes you have made?"
            />
          )}
          {showStoreConfirmation && (
            <StoreConfirmation
              showConfirmationPopup={showStoreConfirmation}
              closePopup={(e) => {
                setShowStoreConfirmation(false);
              }}
              confirmationActionCall={() => {
                trackChanges(false);
                setFieldError({});
                saveDevicesInfo(id, true);
              }}
            />
          )}
          {/* -------- on click of cancel button --------- */}
          {showCancelConfirmation && (
            <Confirmation
              showConfirmationPopup={showCancelConfirmation}
              closePopup={(e) => {
                setShowCancelConfirmation(false);
              }}
              confirmationActionCall={() => {
                trackChanges(false);
                setFieldError({});
                discardChanges(id);
              }}
              confirmationMessage="Are you certain you want to discard all the changes you have made?"
            />
          )}
          {/* ----------- device status confirmation ----------- */}
          {deviceStatusConfirmation && (
            <Confirmation
              showConfirmationPopup={deviceStatusConfirmation}
              closePopup={(e) => {
                setDevicestatusConfirmation(false);
              }}
              confirmationActionCall={() => {
                trackChanges(true);
                setDeviceStatusForAll(statusToUpdate);
              }}
              confirmationMessage={
                statusToUpdate === true ? (
                  <>
                    All devices for this store will be activated. <br />
                    <br />
                    Do you want to continue?
                  </>
                ) : (
                  <>
                    All devices for this store will be deactivated. No more data will be collected. <br />
                    <br />
                    Do you want to continue?
                  </>
                )
              }
            />
          )}
        </>
      )}
    </StyledMainBox>
  );
};

export default Devices;
