import React from "react";
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "./styles";
import { generateRandomNumber } from "../../../api_calls/utils";

const TabularList = ({ fieldNames, fieldValues }) => {
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {fieldNames.map((field) => {
                return (
                  <StyledTableCell key={field} align="left" className="text-wrapper">
                    {field}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {fieldValues.map((row) => {
              return (
                <TableRow key={row.id}>
                  {fieldNames.map((name) => {
                    return (
                      <StyledTableCell key={"field_name_" + name} component="td" scope="row" className="text-wrapper">
                        {name === "#" ? row.id : row[name]}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TabularList;
