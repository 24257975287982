import React from "react";

const ErrorMessageText = ({ message }) => {
  return (
    <p className="cyreen_input_errors" style={{ fontSize: "15px", marginLeft: "10px", fontWeight: "bold" }}>
      {message}
    </p>
  );
};

export default ErrorMessageText;
