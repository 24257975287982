import React from "react";
import { changeParameter } from "../../../api_calls/TableauVisuals";
import { FormControl, FormLabel, FormGroup, FormControlLabel, Box, Stack, FormHelperText } from "@mui/material";
import { StyledDifferentColorSwitch, StyledSwitch } from "../../../components/commonComponents/StyledComponents/styled";

const MetricSwap = ({ vizId }) => {
  const [labelSelection, setLabelSelection] = React.useState(false);

  const handleChange = (event) => {
    setLabelSelection(event.target.checked);
    const labelsFilterValue = event.target.checked ? "Percentage" : "Absolute";
    changeParameter("Metric Swap", labelsFilterValue, vizId);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      {/* <FormLabel component="legend">Metric</FormLabel> */}
      {/* <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px", fontSize: "12px" }}>
        <span>Metric</span>
      </Box> */}
      <FormGroup>
        <FormControlLabel
          control={
            <StyledDifferentColorSwitch checked={labelSelection} onChange={handleChange} name="label_filter_selection" size="small" />
          }
          label={labelSelection ? "Percentage" : "Absolute"}
          labelPlacement="bottom"
          sx={{ minWidth: 60, fontSize: "0.875rem", fontWeight:400, marginTop:"10px" }}
          disableTypography={true}
        />
      </FormGroup>
      {/* <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "2px", fontSize: "12px" }}>
        <span>{labelSelection ? "Percentage" : "Absolute"}</span>
      </Box> */}
      {/* <FormHelperText></FormHelperText> */}
    </FormControl>
  );
};

export default MetricSwap;
