// AccessControlHOC.js
import React from "react";
import { Box,  Typography } from "@mui/material";
import { StyledMainBox } from "../../components/commonComponents/StyledComponents/styled";
import notFoundImg from "../../images/forbidden/notFound.png";

const NotFound = () => {
  return (
    <Box component="div" sx={{ display: "flex" }}>
      <StyledMainBox component="main">
        <Box height={200} />
        <Box height={100} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <img src={notFoundImg} height={500} width={700} />
          {/* <Typography variant="h4" color="#AF3241" sx={{ marginBottom: 2 }}>
            404 Not Found
          </Typography> */}
        </Box>
      </StyledMainBox>
    </Box>
  );
};

export default NotFound;
