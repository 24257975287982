import React from "react";
import { Box, Stack } from "@mui/material";
import SideNav from "../../components/commonComponents/Sidebar/SideNav";
import TabularList from "../../components/commonComponents/TabularLists/TabularList";
import ActionButton from "../../components/commonComponents/Buttons/ActionButton";
import { Add, Edit, Save } from "@mui/icons-material";
import {
  HtmlTooltip,
  IconContainer,
  StyledMainBox,
  StyledStack,
} from "../../components/commonComponents/StyledComponents/styled";
import SimpleModal from "../../components/commonComponents/Modals/SimpleModal";
import AddNewCompany from "./ModalContent/AddCompany";
import AccessControl from "../../components/commonComponents/Hoc/AccessControl";

// dummy data for companies. (It will be removed later when backend APIs will be integrated)
const rows = [
  {
    id: 1,
    company_name: "BBQ Company GmbH",
    company_type: "	Brand",
    country: "DE",
    last_edit_by: "",
  },
  {
    id: 2,
    company_name: "Pizza Manufacturer GmbH",
    company_type: "	Brand",
    country: "DE",
    last_edit_by: "2021-06-22",
  },
  {
    id: 3,
    company_name: "Drinks Maker GmbH",
    company_type: "	Brand",
    country: "DE",
    last_edit_by: "2021-11-18",
  },
  {
    id: 4,
    company_name: "Pudding Producer GmbH",
    company_type: "	Brand",
    country: "DE",
    last_edit_by: "2022-02-15",
  },
  {
    id: 5,
    company_name: "Chocolate Maker GmbH",
    company_type: "	Brand",
    country: "DE",
    last_edit_by: "2023-01-11",
  },
];

const Companies = () => {
  const fixedFieldNames = ["#", "Company Name", "Company Type", "Country", "Last Edit", "Action"];
  const [fieldNames, setFieldNames] = React.useState([]);
  const [fieldValues, setFieldValues] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalDetails, setModalDetails] = React.useState({});

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setModalDetails({
      title: "Add New Company",
      content: <AddNewCompany />,
      confirmAction: (e) => {
        e.preventDefault();
        closeModal();
      },
    });
    setIsModalOpen(true);
  };

  const update_field_values = (selectedFields = []) => {
    let fieldValuesToShow = [];
    rows.forEach((rowValue) => {
      let defineValues = {
        id: (
          <StyledStack direction={"row"} spacing={2}>
            {rowValue.id}
          </StyledStack>
        ),
        "Company Name": <StyledStack direction={"row"}>{rowValue.company_name}</StyledStack>,
        "Company Type": <StyledStack direction={"row"}>{rowValue.company_type}</StyledStack>,
        Country: <StyledStack direction={"row"}>{rowValue.country}</StyledStack>,
        "Last Edit": <StyledStack direction={"row"}>{rowValue.last_edit_by}</StyledStack>,
        Action: (
          <StyledStack direction={"row"} spacing={2}>
            <HtmlTooltip title="Edit company">
              <IconContainer>
                <a href="javascript:void(0)">
                  <Edit sx={{ color: "#AF3241" }} />
                </a>
              </IconContainer>
            </HtmlTooltip>
          </StyledStack>
        ),
      };
      fieldValuesToShow.push(defineValues);
    });
    setFieldValues(fieldValuesToShow);
  };

  React.useEffect(() => {
    setFieldNames(fixedFieldNames);
    //set value for all the fields
    update_field_values([]);
  }, []);

  return (
    <>
      <StyledMainBox component="main">
        <Box height={30} />
        <h1>Companies</h1>
        {/* ============ chose data fields button =========== */}
        <Stack direction="row" spacing={2}>
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              openModal("newcompany");
            }}
            label={"New Company"}
            icon={<Add />}
          />
        </Stack>
        {/* ============== campaign listing ============ */}
        <Box height={30} />
        <TabularList fieldNames={fieldNames} fieldValues={fieldValues} />
      </StyledMainBox>
      {/* ============ modal popup for different actions ========== */}
      <SimpleModal
        open={isModalOpen}
        onClose={closeModal}
        title={modalDetails.title}
        content={modalDetails.content}
        confirmAction={modalDetails.confirmAction}
        confirmIcon={<Save />}
        confirmLabel={"Confirm"}
        size={"md"}
      />
    </>
  );
};

export default AccessControl(Companies, "campaigns");
