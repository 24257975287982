import React from "react";
import { changeParameter } from "../../../api_calls/TableauVisuals";
import { FormControl, FormLabel, FormGroup, FormControlLabel } from "@mui/material";
import { StyledSwitch } from "../../../components/commonComponents/StyledComponents/styled";

const LabelsFilter = ({ vizId }) => {
  const [labelSelection, setLabelSelection] = React.useState(false);

  const handleChange = (event) => {
    setLabelSelection(event.target.checked);
    const labelsFilterValue = event.target.checked ? "On" : "Off";
    changeParameter("Label_ON/Off", labelsFilterValue, vizId);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend" sx={{fontSize:"0.775rem"}}>Labels</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<StyledSwitch checked={labelSelection} size="small" onChange={handleChange} name="label_filter_selection" />}
          label={labelSelection ? "On" : "Off"}
          sx={{ minWidth: 60 }}
        />
      </FormGroup>
      {/* <FormHelperText>Be careful</FormHelperText> */}
    </FormControl>
  );
};

export default LabelsFilter;
