export const updateStateData = (updatedData) => {
  return {
    type: "UPDATE_STATE",
    updatedData,
  };
};

export const deleteItems = (itemsToBeDeleted) => {
  return {
    type : "DELETE_ITEMS",
    itemsToBeDeleted
  }
}
