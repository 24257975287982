/*
    Functions in this file can be used to access tableau api's using python apis
*/

import { updateStateData } from "../Store/actions";
import store from "../Store/store";
import platformConfig from "../platformConfig";
import { updateCampaignData } from "./campaignRequests";
import { replaceUmlaouts } from "./campaignSheet";
import { getFileExtension } from "./imageUpload";
import { setLoadingStatus } from "./requestConfig";

//send request to the lambda function
export const hitTableauApi = async (apiPath, requestBody, requestMethod) => {
  setLoadingStatus(true);
  const tableauResponse = await fetch(process.env.REACT_APP_PYTHON_API_URL + "/" + apiPath, {
    method: requestMethod,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: requestMethod === "GET" ? null : JSON.stringify(requestBody),
  });

  if (tableauResponse.status !== 200) {
    setLoadingStatus(false);
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  } else {
    setLoadingStatus(false);
    const response = await tableauResponse.json();
    return {
      success: true,
      response: response.body,
    };
  }
};

// Generate tableau data source.
/*
  it will create a new project by using campaign name as a reference.
  will create a new datasource inside newly created project.
  **** if project is already created, then it will use the same project id to update
  the existing datasource.
*/
export const generateDataSource = async (campaign_id, campaign_name, tableau_project_id = "") => {
  const createDataSource = await hitTableauApi(
    "generate_data",
    {
      id: campaign_id,
      name: replaceUmlaouts(campaign_name.replace(":", " ")),
      generate_datasource: "True",
      project_id: tableau_project_id,
    },
    "POST"
  );

  if (typeof createDataSource.success !== "undefined" && createDataSource.success === true) {
    //save tableau project id for the campaign.
    updateCampaignData(
      {
        tableauId: createDataSource.response.project_id,
        status: 2, //data source published i.e., data processing done
      },
      campaign_id
    );
  }

  return createDataSource;
};

//publish tableau workbook for a campaign
export const publishTableauWorkbook = async (campaign_id, campaign_name, project_id) => {
  const publishWorkbook = await hitTableauApi(
    "publish-workbook",
    {
      id: campaign_id,
      name: replaceUmlaouts(campaign_name.replace(":", " ")),
      generate_datasource: "True",
      project_id: project_id,
    },
    "POST"
  );

  if (typeof publishWorkbook.success !== "undefined" && publishWorkbook.success === true) {
    //save tableau project id for the campaign.
    updateCampaignData(
      {
        workbookPath: publishWorkbook.response.workbook_path,
        workbookId: publishWorkbook.response.workbook_id,
        status: 3, //workbook published i.e., Data visualization done
      },
      campaign_id
    );
  }

  return publishWorkbook;
};

// Generate token to load tableau workbook through connected apps method.
export const connectedApptoken = async () => {
  const generateTokenRequest = await hitTableauApi(
    "workbook-token",
    {
      id: 120,
      name: "ADAC Motorwelt",
      generate_datasource: "True",
      project_id: "63fe6902-d16d-4a69-b829-b3f249dc7b24",
    },
    "POST"
  );

  if (typeof generateTokenRequest.success !== "undefined" && generateTokenRequest.success === true) {
    // save tableau project id in the database using backend api
    store.dispatch(
      updateStateData({
        connected_app_token: generateTokenRequest.response.token,
      })
    );
  } else {
    //reset connected app token
    store.dispatch(
      updateStateData({
        connected_app_token: "",
      })
    );
  }

  return generateTokenRequest;
};

//generate powerpoint
export const createPowerPoint = async (powerPointPayload) => {
  setLoadingStatus(true);
  /*
    1. There's a common function to hit all the tableau apis i.e., hitTableauApi.
    2. to create powerpoint slides, we have directly defined fetch request here because we need to
    exclude headers from the request. 
    3. we could have managed that in the common function but it would have been little tricky 
    for future developers to understand. 
    4. So to keep it simple, I have directly used fetch method here instead of making any adjustments
    in the common function. 
    5. Future developers can change it as per their requirements and understandings.
  */
  const powerpointResponse = await fetch(process.env.REACT_APP_PYTHON_API_URL + "/generate_chart", {
    method: "POST",
    mode: "cors",
    body: powerPointPayload,
  });

  if (powerpointResponse.status !== 200) {
    setLoadingStatus(false);
    return {
      success: false,
      message: "Sorry, something went wrong",
    };
  } else {
    setLoadingStatus(false);
    const response = await powerpointResponse.json();
    return {
      success: true,
      response: response.body,
    };
  }
};

//download file
export const downloadReportPowerpoint = async () => {
  const downloadRequest = await hitTableauApi("download_link", "", "GET");

  if (typeof downloadRequest.success !== "undefined" && downloadRequest.success === true) {
    window.location.href = downloadRequest.response.download_link;
    return {
      success: true,
      message: "check file if downloaded",
    };
  } else {
    return {
      success: false,
      message: "something went wrong",
    };
  }
};

// validate data extraction request
export const validateDataExtractionRequest = (logoCounter, logoCounterArr, clipCounter, clipCounterArr, clipSelection) => {
  const currentStateData = store.getState().userSelections;
  let error = false;
  let error_message = "";
  let campaignData = new FormData();
  campaignData.append("w_id", currentStateData.campaign_detail.workbook_id); //
  campaignData.append("campaign_id", currentStateData.campaign_detail.campaign_id);
  //
  campaignData.append(
    "company_retailer",
    currentStateData.campaign_detail.retailer === "REWE Markt GmbH" ? "Rewe" : "EDEKA"
  );
  campaignData.append("logos_count", logoCounter);
  campaignData.append("clip_count", clipCounter);
  campaignData.append("ad_lvl_count", currentStateData.visual_filters.adlevels.length);
  campaignData.append("discount_flag", currentStateData.visual_filters.discount_applied);
  campaignData.append("a_b_test_flag", currentStateData.visual_filters.abtest_applied);
  // images will be uploaded separately to s3 bucket,
  let imageFiles = [];
  //Discount Adlevels
  if (currentStateData.visual_filters.discount_applied === true) {
    currentStateData.visual_filters.discount_adlevels.forEach((discountAdlevel) => {
      campaignData.append("discount_adlevel", discountAdlevel);
    });
  }
  //discount checkout
  if (typeof currentStateData.visual_filters.discount_checkout !== "undefined") {
    currentStateData.visual_filters.discount_checkout.forEach((discountCheckout) => {
      if (platformConfig.finalized_checkout_vals.includes(discountCheckout)) {
        campaignData.append("discount_checkout_type", discountCheckout);
      } else {
        campaignData.append("discount_tableau_name_checkout", discountCheckout);
      }
    });
  }
  //AB Adlevels
  if (currentStateData.visual_filters.abtest_applied === true) {
    currentStateData.visual_filters.abtest_adlevels.forEach((abAdlevel) => {
      campaignData.append("ab_adlevel", abAdlevel);
    });
  }
  //AB checkout
  if (
    typeof currentStateData.visual_filters.abtest_checkout !== "undefined" &&
    currentStateData.visual_filters.abtest_checkout.length !== 0
  ) {
    currentStateData.visual_filters.abtest_checkout.forEach((abCheckout) => {
      if (platformConfig.finalized_checkout_vals.includes(abCheckout)) {
        campaignData.append("abtest_checkout_type", abCheckout);
      } else {
        campaignData.append("abtest_tableau_name_checkout", abCheckout);
      }
    });
  }
  //optimization options adlevel
  if (
    typeof currentStateData.visual_filters.optimization !== "undefined" &&
    currentStateData.visual_filters.optimization.length !== 0
  ) {
    currentStateData.visual_filters.optimization.forEach((optOption) => {
      campaignData.append("optimization_option", optOption);
      campaignData.append("optimization_flag", true);
      //check adlevel selected for each optimization option
      if (
        typeof currentStateData.visual_filters.optimize_option_adlevels !== "undefined" &&
        typeof currentStateData.visual_filters.optimize_option_adlevels[optOption] !== "undefined" &&
        currentStateData.visual_filters.optimize_option_adlevels[optOption].length !== 0
      ) {
        currentStateData.visual_filters.optimize_option_adlevels[optOption].forEach((optionAdlevel) => {
          campaignData.append(optOption + "_adlevel", optionAdlevel);
        });
      }
    });
  } else {
    campaignData.append("optimization_flag", false);
  }
  //optimization checkout
  if (
    typeof currentStateData.visual_filters.optimization_checkout !== "undefined" &&
    currentStateData.visual_filters.optimization_checkout.length !== 0
  ) {
    currentStateData.visual_filters.optimization_checkout.forEach((optCheckout) => {
      if (platformConfig.finalized_checkout_vals.includes(optCheckout)) {
        campaignData.append("optimization_checkout_type", optCheckout);
      } else {
        campaignData.append("optimization_tableau_name_checkout", optCheckout);
      }
    });
  }

  //analytics checkout
  if (
    typeof currentStateData.visual_filters.checkout !== "undefined" &&
    currentStateData.visual_filters.checkout.length !== 0
  ) {
    currentStateData.visual_filters.checkout.forEach((checkName) => {
      if (platformConfig.finalized_checkout_vals.includes(checkName)) {
        campaignData.append("analytics_cat_type", checkName);
      } else {
        campaignData.append("analytics_cat_type_tableau_name_checkout", checkName);
      }
    });
  }
  //market type checkout
  if (
    typeof currentStateData.visual_filters.market_checkout !== "undefined" &&
    currentStateData.visual_filters.market_checkout.length !== 0
  ) {
    currentStateData.visual_filters.market_checkout.forEach((marketCheckout) => {
      if (platformConfig.finalized_checkout_vals.includes(marketCheckout)) {
        campaignData.append("market_type_name_checkout", marketCheckout);
      } else {
        campaignData.append("market_type_tableau_name_checkout", marketCheckout);
      }
    });
  }
  //market type adlevel

  if (
    typeof currentStateData.visual_filters.market_adlevel !== "undefined" &&
    currentStateData.visual_filters.market_adlevel.length !== 0
  ) {
    currentStateData.visual_filters.market_adlevel.forEach((marktAdlevel) => {
      campaignData.append("market_type_adlevel", marktAdlevel);
    });
  }
  //selected stores
  if (
    typeof currentStateData.visual_filters.stores !== "undefined" &&
    currentStateData.visual_filters.stores.length !== 0
  ) {
    currentStateData.visual_filters.stores.forEach((stores) => {
      campaignData.append("store", stores);
    });
  }
  //referencing
  if (
    typeof currentStateData.visual_filters.referencing !== "undefined" &&
    currentStateData.visual_filters.referencing.length !== 0
  ) {
    currentStateData.visual_filters.referencing.forEach((ref) => {
      campaignData.append("referencing", ref);
    });
  }
  //referencing adlevel
  if (
    typeof currentStateData.visual_filters.referencing_adlevel !== "undefined" &&
    currentStateData.visual_filters.referencing_adlevel.length !== 0
  ) {
    currentStateData.visual_filters.referencing_adlevel.forEach((refAdlevel) => {
      campaignData.append("referencing_adlevel", refAdlevel);
    });
  }
  //contacts
  if (
    typeof currentStateData.visual_filters.contacts !== "undefined" &&
    currentStateData.visual_filters.contacts.length !== 0
  ) {
    currentStateData.visual_filters.contacts.forEach((contact) => {
      campaignData.append("contacts", contact);
    });
  }
  //contact checkout option
  if (
    typeof currentStateData.visual_filters.contact_checkout_name !== "undefined" &&
    currentStateData.visual_filters.market_checkout.length !== 0
  ) {
    currentStateData.visual_filters.contact_checkout_name.forEach((cntctCheckout) => {
      if (platformConfig.finalized_checkout_vals.includes(cntctCheckout)) {
        campaignData.append("contact_checkout_name", cntctCheckout);
      } else {
        campaignData.append("contact_tableau_name_checkout", cntctCheckout);
      }
    });
  }
  //loop through selected adlevels and prepare request data accordingly
  currentStateData.visual_filters.adlevels.forEach((adlevel, index) => {
    //validate if clip/prod pic and position has been added or not
    const validateProdPicture = document.getElementById("product_pic_for_" + adlevel.replace(" ", "_")).value;
    const validateAdlevelPosition = document.getElementById("position_number_for_" + adlevel.replace(" ", "_")).value;

    if (validateProdPicture !== "" && validateAdlevelPosition !== "") {
      const prodPicture = document.getElementById("product_pic_for_" + adlevel.replace(" ", "_")).files[0];
      const adlevelPosition = document.getElementById("position_number_for_" + adlevel.replace(" ", "_")).value;
      const editedAdlevelName = document.getElementById("edited_adlevel_name_for_" + adlevel.replace(" ", "_")).value;
      //add details to adlevelinfo variable
      campaignData.append("ad_level_name_list", adlevel);
      campaignData.append("edited_adlevel_names", editedAdlevelName);
      campaignData.append("adlevel_position_numbers", adlevelPosition);
      //push img object to images array, it will be used to upload pictures to s3 bucket later
      imageFiles.push({
        img_file: prodPicture,
        img_name: "pic" + parseInt(index + 1) + "." + getFileExtension(prodPicture),
        img_dir: "ad_level_images",
      });
    } else {
      error = true;
      error_message = "Please select Clip/Product/Logos picture and mention the position for each adlevel.";
    }
  });

  //add logos to the formdata
  logoCounterArr.forEach((counter) => {
    const validateLogoFile = document.getElementById("logo_" + counter).value;
    if (validateLogoFile !== "") {
      const logoFile = document.getElementById("logo_" + counter).files[0];
      imageFiles.push({
        img_file: logoFile,
        img_name: "logo" + parseInt(counter + 1) + "." + getFileExtension(logoFile),
        img_dir: "logos",
      });
      //campaignData.append("logos", logoFile);
    } else {
      error = true;
      error_message = "Please select clip/product/logos picture and mention the position for each adlevel.";
    }
  });
  //add clip pictures to the formdata
  clipCounterArr.forEach((counter) => {
    const validateClipFile = document.getElementById("clip_pictures_" + counter).value;
    if (validateClipFile !== "") {
      const clipFile = document.getElementById("clip_pictures_" + counter).files[0];
      //push img object to images array, it will be used to upload pictures to s3 bucket later
      imageFiles.push({
        img_file: clipFile,
        img_name: "clip" + parseInt(counter + 1) + "." + getFileExtension(clipFile),
        img_dir: "clip_images",
      });
    } else {
      error = true;
      error_message = "Please select clip/product/logos picture and mention the position for each adlevel.";
    }
  });

  //validate ab test clips
  if (
    typeof currentStateData.visual_filters !== "undefined" &&
    typeof currentStateData.visual_filters.abtest_applied !== "undefined" &&
    currentStateData.visual_filters.abtest_applied === true
  ) {
    //validate if clip A is selected or not
    if (typeof clipSelection.clip_a !== "undefined" && clipSelection.clip_a !== "") {
      campaignData.append("clip_a", clipSelection.clip_a);
    } else {
      error = true;
      error_message = "Please select clip/product/logos picture and mention the position for each adlevel.";
    }

    // validate clip B
    if (typeof clipSelection.clip_b !== "undefined" && clipSelection.clip_b !== "") {
      campaignData.append("clip_b", clipSelection.clip_b);
    } else {
      error = true;
      error_message = "Please select clip/product/logos picture and mention the position for each adlevel.";
    }
  }

  if (error === false) {
    var formdata = new FormData();

    formdata.append("company_retailer", "Rewe");
    formdata.append("ad_lvl_count", "1");
    formdata.append("ad_level_name_list", "Beworbenes Produkt");
    formdata.append("analytics_cat_type", "only-trolley");
    formdata.append("discount_flag", "False");
    formdata.append("discount_adlevel", "Beworbenes Produkt");
    formdata.append("discount_checkout_type", "only-trolley");
    formdata.append("optimization_checkout_type", "only-trolley");
    formdata.append("market_type_name_checkout", "only-trolley");
    formdata.append("a_b_test_flag", "True");
    formdata.append("ab_adlevel", "Beworbenes Produkt");
    formdata.append("clip_count", "2");
    formdata.append("w_id", currentStateData.campaign_detail.workbook_id);
    formdata.append("campaign_id", currentStateData.campaign_detail.campaign_id);
    formdata.append("store", "REWE Kall");
    formdata.append("store", "REWE Neuwied");
    formdata.append("store", "REWE Herne");
    formdata.append("store", "REWE Saarburg");
    formdata.append("store", "REWE Bonn Beuel");
    formdata.append("store", "REWE Koeln Bickendorf");
    formdata.append("store", "REWE Alfter-Oedekoven");
    formdata.append("store", "REWE Hachenburg");
    formdata.append("store", "REWE Duisburg-Rheinhausen");
    formdata.append("logos_count", "1");
    formdata.append("optimization_flag", "True");
    formdata.append("contact_checkout_name", "Trolley + All");
    formdata.append("contacts", "unfiltered");
    formdata.append("optimization_option", "dow");
    formdata.append("optimization_option", "tod");
    formdata.append("dow_adlevel", "Beworbenes Produkt");
    formdata.append("tod_adlevel", "Beworbenes Produkt");
    formdata.append("clip_a", "1");
    formdata.append("clip_b", "2");
    formdata.append("abtest_checkout_type", "only-trolley");
    formdata.append("adlevel_position_numbers", "1");
    formdata.append("edited_adlevel_names", "REWE Bio Milch");
    formdata.append("market_type_adlevel", "Beworbenes Produkt");
    formdata.append("campaign_type", "Food");

    return {
      success: true,
      error: false,
      request_data: formdata,
      uploaded_files_detail: imageFiles,
    };
  } else {
    return {
      success: false,
      error,
      error_message,
    };
  }
};
