import * as React from "react";
import { CloseButtonStyle } from "./Styles";

export default function CloseButton({ label, onClick, icon }) {
  return (
    <CloseButtonStyle
      component={"a"}
      variant="outlined"
      onClick={onClick}
      startIcon={icon}
    >
      {label}
    </CloseButtonStyle>
  );
}
