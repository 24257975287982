import React from "react";
import { Box, Paper, Stack, Tab, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import {
  LeftStyledTableCell,
  StyledCenterAlignedTableCell,
  StyledTableCell,
} from "../../../../components/commonComponents/TabularLists/styles";
import * as xlsx from "xlsx";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HtmlTooltip,
  HtmlTooltipDisabled,
  IconContainer,
  StyledBlackInformationTypography,
  StyledInformationSpan,
  StyledLeftAlignedStack,
  StyledMainBox,
  StyledStack,
  StyledSwitch,
} from "../../../../components/commonComponents/StyledComponents/styled";
import { CustomTextInput } from "../../../../components/commonComponents/TextInputs/style";
import ActionButton, { DarkGreyButton } from "../../../../components/commonComponents/Buttons/ActionButton";
import {
  Add,
  ArrowDropDown,
  ArrowDropUp,
  Cancel,
  CheckCircle,
  Close,
  Delete,
  Download,
  Error,
  Info,
  Save,
} from "@mui/icons-material";
import CloseButton from "../../../../components/commonComponents/Buttons/CloseButton";
import { updateStateData } from "../../../../Store/actions";
import {
  checkCounterTextToShow,
  checkoutTypeId,
  defineCheckoutEntries,
  discardCheckoutChanges,
  saveCheckoutLayoutInfo,
  sortCheckoutEntries,
  trackChanges,
  validateCheckoutEntries,
} from "../../../../api_calls/infrastructure";
import SelectBox from "../../../../components/commonComponents/Selectors/SelectBox";
import Confirmation from "../../ModalContent/Confirmation";
import { validateNumbers } from "../../../../api_calls/utils";
import moment from "moment-timezone";
import SingleSelect from "../../../../components/commonComponents/Selectors/SingleSelect";

const CheckoutLayout = () => {
  const userStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const { id } = useParams();
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [fieldError, setFieldError] = React.useState({});
  const [countError, setCountError] = React.useState({});
  const [countErrorMessage, setCountErrorMessage] = React.useState({});
  const storeName = typeof userStateData.store_overview !== "undefined" ? userStateData.store_overview?.name : "";
  const trackInfraChanges =
    typeof userStateData.track_infra_changes !== "undefined" ? userStateData.track_infra_changes : false;
  const checkoutTypeList =
    typeof userStateData.checkout_type_list !== "undefined"
      ? userStateData.checkout_type_list.map((type) => {
          return type.name;
        })
      : [];
  const checkoutCounterObj =
    typeof userStateData.checkout_layout_list !== "undefined" ? userStateData.checkout_layout_list : [];

  const [showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);
  const [showCounterText, setShowCounterText] = React.useState(false);

  const [sortingMeasure, setSortingMeasure] = React.useState({
    instore_num: "asc",
    system_num: "asc",
  });

  const sortByInstoreNum = () => {
    setSortingMeasure((prevState) => {
      return {
        ...prevState,
        instore_num: prevState.instore_num === "asc" ? "desc" : "asc",
      };
    });
    sortCheckoutEntries(
      { instore_num: true, system_num: false },
      {
        ...sortingMeasure,
        instore_num: sortingMeasure.instore_num === "asc" ? "desc" : "asc",
      }
    );
  };

  const sortBySystemNum = () => {
    setSortingMeasure((prevState) => {
      return {
        ...prevState,
        system_num: prevState.system_num === "asc" ? "desc" : "asc",
      };
    });
    //sort checkout entries
    sortCheckoutEntries(
      { instore_num: false, system_num: true },
      {
        ...sortingMeasure,
        system_num: sortingMeasure.system_num === "asc" ? "desc" : "asc",
      }
    );
  };

  const saveCheckoutCount = (checkoutCount) => {
    dispatchAction(
      updateStateData({
        temporary_checkout_info: checkoutCount,
      })
    );
  };

  const validateCheckoutCount = () => {
    setCountError(false);
    let error = false;
    const checkoutCount =
      typeof userStateData.temporary_checkout_info !== "undefined" ? userStateData.temporary_checkout_info : "";

    const validateCount = validateNumbers(checkoutCount);
    if (validateCount.error === true) {
      error = true;
      setCountError(true);
      setCountErrorMessage("Checkout counter(s) can't be empty, may not have alphabets and negative values");
    }

    return {
      error,
    };
  };

  const addCheckoutEntries = () => {
    setCountError(false);
    trackChanges(true);
    const validateCount = validateCheckoutCount();

    if (validateCount.error === true) {
      setCountError(true);
    } else {
      defineCheckoutEntries();
    }
  };

  const handleFieldChange = (checkoutInfo, index) => {
    trackChanges(true);
    let addedCheckoutList =
      typeof userStateData.checkout_layout_list !== "undefined" ? userStateData.checkout_layout_list : [];
    const updatedCheckoutList = [
      ...addedCheckoutList.slice(0, index), // Copy elements before the updated index
      checkoutInfo, // Update the element at the specified index
      ...addedCheckoutList.slice(index + 1), // Copy elements after the updated index
    ];

    dispatchAction(
      updateStateData({
        checkout_layout_list: updatedCheckoutList,
      })
    );
  };

  const cancelModifications = (e) => {
    e.preventDefault();
    if (trackInfraChanges === true) {
      setShowCancelConfirmation(true);
    }
  };

  const saveCheckoutValues = (e) => {
    e.preventDefault();
    setFieldError({});
    const validateEntries = validateCheckoutEntries();
    if (validateEntries.error === true) {
      setFieldError(validateEntries.errorDetails);
    } else {
      setShowConfirmation(true);
    }
  };

  const deleteCheckoutEntry = (index) => {
    let checkoutEntries =
      typeof userStateData.checkout_layout_list !== "undefined" ? userStateData.checkout_layout_list : [];
    const listWithDeletedEntry = [
      ...checkoutEntries.slice(0, index), // Copy elements before the updated index
      ...checkoutEntries.slice(index + 1), // Copy elements after the updated index
    ];
    // subtract 1 from checkout count info
    let checkoutCount =
      typeof userStateData.checkout_count_info !== "undefined" ? parseInt(userStateData.checkout_count_info) - 1 : 0;
    trackChanges(true);
    dispatchAction(
      updateStateData({
        checkout_layout_list: listWithDeletedEntry,
        checkout_count_info: checkoutCount,
      })
    );
  };

  const exportToExcel = (checkoutCounterObj) => {
    // Convert JSON data to an array of arrays
    const data = [];

    data.push(["Instore CO Number", "System CO Number", "Checkout Type", "Description", "CAP Enabled"]); // Add headers

    checkoutCounterObj.forEach((checkout, index) => {
      const row = [];
      row.push(
        checkout.instore_co_number,
        checkout.system_co_number,
        checkout.checkout_type,
        checkout.description,
        checkout.cap_enabled
      );
      data.push(row);
    });

    // Create a new workbook and add a worksheet
    const workbook = xlsx.utils.book_new();
    const worksheet = xlsx.utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    let exportFileName = moment().startOf("day").format("YYYYMMDD") + "_" + id + "_" + storeName + "_CheckoutLayout.xlsx";

    // Save the workbook to an Excel file
    xlsx.writeFile(workbook, exportFileName);
  };

  const checkCounterTextStatus = () => {
    const checkStatus = checkCounterTextToShow();
    setShowCounterText(checkStatus);
  };

  React.useEffect(() => {
    checkCounterTextStatus();
  }, []);

  return (
    <StyledMainBox component="main">
      {/* ------- warning text --------- */}
      {showCounterText === true && (
        <>
          <StyledInformationSpan sx={{ fontSize: "15px" }}>
            Your number of checkouts marked as CAP Enabled is different than the number of checkouts added to the Checkout
            Mapping of this store. Please check your settings to ensure a correct processing of this store's data.
          </StyledInformationSpan>
          <Box height={30} />
        </>
      )}

      <Stack direction={"row"} spacing={4}>
        <StyledBlackInformationTypography>Add Checkout Counter(s):</StyledBlackInformationTypography>
        <Box width={countError === true ? 120 : 80}>
          <Stack direction={"row"} spacing={4}>
            <CustomTextInput
              variant="standard"
              type={"text"}
              value={
                typeof userStateData.temporary_checkout_info !== "undefined" ? userStateData.temporary_checkout_info : ""
              }
              onChange={(e) => {
                saveCheckoutCount(e.target.value);
              }}
              onBlur={(e) => {
                const validateCount = validateCheckoutCount();
                if (validateCount.error === true) {
                  setCountError(true);
                }
              }}
            />
            {countError === true && (
              <HtmlTooltip title={countErrorMessage}>
                <IconContainer>
                  <Error sx={{ color: "#AF3241" }} />
                </IconContainer>
              </HtmlTooltip>
            )}
          </Stack>
        </Box>
        <ActionButton
          onClick={(e) => {
            e.preventDefault();
            addCheckoutEntries();
          }}
          label={"Add"}
          icon={<Add />}
        />
      </Stack>
      <Box height={30} />
      {checkoutCounterObj.length !== 0 && (
        <>
          <h3>
            Checkout Layout{" "}
            <HtmlTooltip
              title={
                <Box width={450}>
                  <StyledMainBox>
                    <Box height={5} />
                    <Typography variant="body2">
                      <strong>In-Store Checkout Number:</strong> Checkout number which is visible at the checkout counter
                      inside the store.
                    </Typography>
                    <Box height={10} />
                    <Typography variant="body2">
                      <strong>System Checkout Number:</strong> Checkout number used by the retailer’s internal system and is
                      included in the receipt data. This value may or may not differ from the In-Store Checkout Number.
                      <Box height={10} />
                      The System Checkout Number is essential for a correct processing of this store’s data.
                    </Typography>
                    <Box height={10} />
                    <Typography variant="body2">
                      <strong>Checkout Type:</strong> Classifies the type of the checkout counter. The classification is
                      important for a correct analysis of the store's data.
                    </Typography>
                    <Box height={10} />
                    <Typography variant="body2">
                      <strong>C.A.P. Enabled:</strong> Classifies if a checkout counter is equipped with C.A.P. tracking
                      technology. Also activate a checkout counter, if shoppers are recorded manually at the counter.
                    </Typography>
                    <Box height={10} />
                    <Typography variant="body2">
                      <strong>Status:</strong> Specifies if a checkout counter with the saved specifications is still
                      present at the store location.
                      <Box height={10} />
                    </Typography>
                    <Box sx={{ marginLeft: "10px" }}>
                      <Typography variant="body2">
                        <strong>Active:</strong> Counter is still installed at the store location and generates receipt
                        data.
                        <Box height={10} /> <strong>Inactive:</strong> Counter is no longer installed at the store location
                        or checkout numbers changed. No new receipt data is generated for the saved checkout details.
                      </Typography>
                    </Box>
                  </StyledMainBox>
                </Box>
              }
            >
              <Info sx={{ fontSize: "18px" }} />
            </HtmlTooltip>
          </h3>

          <StyledInformationSpan sx={{ fontSize: "15px" }}>
            Important: The In-Store and System Checkout Numbers cannot be adjusted after saving. Saved entries cannot be
            removed. Saved entries can be set to inactive to be ignored during data processing and data analysis.
          </StyledInformationSpan>
          <Box height={30} />
          {/* --------- checkout layout table ---------- */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <LeftStyledTableCell align="left" className="text-wrapper">
                    In-Store Checkout Number*{" "}
                    {sortingMeasure.instore_num === "asc" ? (
                      <ArrowDropDown sx={{ cursor: "pointer" }} onClick={sortByInstoreNum} />
                    ) : (
                      <ArrowDropUp sx={{ cursor: "pointer" }} onClick={sortByInstoreNum} />
                    )}
                  </LeftStyledTableCell>
                  <LeftStyledTableCell align="left" className="text-wrapper">
                    System Checkout Number*{" "}
                    {sortingMeasure.system_num === "asc" ? (
                      <ArrowDropDown sx={{ cursor: "pointer" }} onClick={sortBySystemNum} />
                    ) : (
                      <ArrowDropUp sx={{ cursor: "pointer" }} onClick={sortBySystemNum} />
                    )}
                  </LeftStyledTableCell>
                  <LeftStyledTableCell align="left" className="text-wrapper">
                    Checkout Type*
                  </LeftStyledTableCell>
                  <LeftStyledTableCell align="left" className="text-wrapper">
                    Description
                  </LeftStyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    C.A.P. Enabled
                  </StyledTableCell>
                  <StyledTableCell align="left" className="text-wrapper">
                    Status
                  </StyledTableCell>
                  <LeftStyledTableCell align="left" className="text-wrapper"></LeftStyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkoutCounterObj.map((checkoutObj, index) => {
                  return (
                    <TableRow key={index + "_device_row"}>
                      {/* ---------- instore checkout number ----------- */}
                      <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledLeftAlignedStack direction={"row"}>
                          <CustomTextInput
                            variant="standard"
                            fullWidth
                            disabled={typeof checkoutObj.edit_disabled !== "undefined" ? checkoutObj.edit_disabled : false}
                            type={"text"}
                            value={typeof checkoutObj.instore_co_number !== "undefined" ? checkoutObj.instore_co_number : 0}
                            onChange={(e) => {
                              handleFieldChange(
                                {
                                  ...checkoutObj,
                                  instore_co_number: e.target.value,
                                },
                                index
                              );
                            }}
                            onBlur={(e) => {}}
                          />
                          {/* ------ error message ------ */}
                          {typeof fieldError[index] !== "undefined" &&
                            typeof fieldError[index].instore_co_number !== "undefined" && (
                              <HtmlTooltip title={fieldError[index].instore_co_number}>
                                <IconContainer>
                                  <Error sx={{ color: "#AF3241" }} />
                                </IconContainer>
                              </HtmlTooltip>
                            )}
                        </StyledLeftAlignedStack>
                      </LeftStyledTableCell>
                      {/* ---------- system checkout number ------------- */}
                      <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledLeftAlignedStack direction={"row"} spacing={2}>
                          <CustomTextInput
                            variant="standard"
                            disabled={typeof checkoutObj.edit_disabled !== "undefined" ? checkoutObj.edit_disabled : false}
                            fullWidth
                            type={"text"}
                            value={typeof checkoutObj.system_co_number !== "undefined" ? checkoutObj.system_co_number : 0}
                            onChange={(e) => {
                              handleFieldChange(
                                {
                                  ...checkoutObj,
                                  system_co_number: e.target.value,
                                },
                                index
                              );
                            }}
                            onBlur={(e) => {}}
                          />
                          {/* ------ error message ------ */}
                          {typeof fieldError[index] !== "undefined" &&
                            typeof fieldError[index].system_co_number !== "undefined" && (
                              <HtmlTooltip title={fieldError[index].system_co_number}>
                                <IconContainer>
                                  <Error sx={{ color: "#AF3241" }} />
                                </IconContainer>
                              </HtmlTooltip>
                            )}
                        </StyledLeftAlignedStack>
                      </LeftStyledTableCell>
                      {/* ----------- checkout type ------------- */}
                      <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledLeftAlignedStack direction={"row"} spacing={2}>
                          <SingleSelect
                            enableFullWidth={false}
                            label=""
                            readOnlyFlag={
                              typeof checkoutObj.active !== "undefined" && checkoutObj.active === false ? true : false
                            }
                            options={checkoutTypeList.map((checkoutVal) => {
                              return { label: checkoutVal, value: checkoutVal };
                            })}
                            defaultVal={
                              typeof checkoutObj.checkout_type !== "undefined"
                                ? { label: checkoutObj.checkout_type, value: checkoutObj.checkout_type }
                                : {}
                            }
                            selectionCheck={(option, value) => option.value === value.value}
                            onChangeCall={(selectedOption) => {
                              handleFieldChange(
                                {
                                  ...checkoutObj,
                                  checkout_type: selectedOption.value,
                                  checkout_type_id: checkoutTypeId(selectedOption.value),
                                },
                                index
                              );
                            }}
                          />
                          {typeof fieldError[index] !== "undefined" &&
                            typeof fieldError[index].checkout_type !== "undefined" && (
                              <HtmlTooltip title={fieldError[index].checkout_type}>
                                <IconContainer>
                                  <Error sx={{ color: "#AF3241" }} />
                                </IconContainer>
                              </HtmlTooltip>
                            )}
                        </StyledLeftAlignedStack>
                      </LeftStyledTableCell>
                      {/* ------------- description ------------- */}
                      <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledLeftAlignedStack direction={"row"}>
                          <CustomTextInput
                            variant="standard"
                            fullWidth
                            disabled={
                              typeof checkoutObj.active !== "undefined" && checkoutObj.active === false ? true : false
                            }
                            type={"text"}
                            value={typeof checkoutObj.description !== "undefined" ? checkoutObj.description : ""}
                            onChange={(e) => {
                              handleFieldChange(
                                {
                                  ...checkoutObj,
                                  description: e.target.value,
                                },
                                index
                              );
                            }}
                            onBlur={(e) => {}}
                          />

                          {typeof fieldError[index] !== "undefined" &&
                            typeof fieldError[index].description !== "undefined" && (
                              <HtmlTooltip title={fieldError[index].description}>
                                <IconContainer>
                                  <Error sx={{ color: "#AF3241" }} />
                                </IconContainer>
                              </HtmlTooltip>
                            )}
                        </StyledLeftAlignedStack>
                      </LeftStyledTableCell>
                      {/* ---------- CAP enabled ------------ */}
                      <StyledCenterAlignedTableCell component="td" scope="row" className="text-wrapper">
                        <StyledSwitch
                          checked={checkoutObj.cap_enabled}
                          disabled={
                            typeof checkoutObj.active !== "undefined" && checkoutObj.active === false ? true : false
                          }
                          onChange={(e) => {
                            handleFieldChange(
                              {
                                ...checkoutObj,
                                cap_enabled: e.target.checked,
                              },
                              index
                            );
                          }}
                        />
                      </StyledCenterAlignedTableCell>
                      {/* ------------- status ---------------- */}
                      <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledStack direction={"row"}>
                          {checkoutObj.active === true ? (
                            <HtmlTooltip title={"Active"}>
                              <IconContainer sx={{ marginTop: "10px" }}>
                                <CheckCircle
                                  sx={{ color: "#AF3241", cursor: "pointer" }}
                                  onClick={() => {
                                    handleFieldChange(
                                      {
                                        ...checkoutObj,
                                        active: false,
                                      },
                                      index
                                    );
                                  }}
                                />
                              </IconContainer>
                            </HtmlTooltip>
                          ) : (
                            <HtmlTooltip title={"Inactive"}>
                              <IconContainer sx={{ marginTop: "10px" }}>
                                <Cancel
                                  sx={{ color: "#62626E", cursor: "pointer" }}
                                  onClick={() => {
                                    handleFieldChange(
                                      {
                                        ...checkoutObj,
                                        active: true,
                                      },
                                      index
                                    );
                                  }}
                                />
                              </IconContainer>
                            </HtmlTooltip>
                          )}
                        </StyledStack>
                      </LeftStyledTableCell>
                      {/* --------- delete button --------- */}
                      <LeftStyledTableCell component="td" scope="row" className="text-wrapper">
                        <StyledLeftAlignedStack direction={"row"}>
                          {typeof checkoutObj.edit_disabled !== "undefined" && checkoutObj.edit_disabled === false ? (
                            <HtmlTooltip title={"Delete"}>
                              <IconContainer>
                                <a
                                  href="#deleteRow"
                                  className="cyreen_icons"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteCheckoutEntry(index);
                                  }}
                                >
                                  <Delete sx={{ color: "#AF3241" }} />
                                </a>
                              </IconContainer>
                            </HtmlTooltip>
                          ) : (
                            <HtmlTooltipDisabled title={"Entry cannot be deleted"}>
                              <IconContainer>
                                <a
                                  href="#deleteRow"
                                  className="cyreen_icons"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Delete sx={{ color: "#E6E6E6" }} />
                                </a>
                              </IconContainer>
                            </HtmlTooltipDisabled>
                          )}
                        </StyledLeftAlignedStack>
                      </LeftStyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box height={40} />
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box>
              <CloseButton onClick={cancelModifications} label="Cancel" icon={<Close />} />
            </Box>
            <Box display="flex" alignItems="center">
              {userStateData?.db_checkout_layout_list && (
                <DarkGreyButton
                  onClick={(e) => {
                    e.preventDefault();
                    exportToExcel(userStateData?.db_checkout_layout_list);
                  }}
                  label="Download XLSX"
                  icon={<Download />}
                />
              )}

              <Box ml={2}>
                {/* Add margin between the buttons */}
                <ActionButton onClick={saveCheckoutValues} label="Save" icon={<Save />} />
              </Box>
            </Box>
          </Box>
        </>
      )}

      {/* ------- confirmation popup (on click of save button) -------- */}
      {showConfirmation && (
        <Confirmation
          showConfirmationPopup={showConfirmation}
          closePopup={(e) => {
            setShowConfirmation(false);
          }}
          confirmationActionCall={() => {
            saveCheckoutLayoutInfo(id).then((info) => {
              checkCounterTextToShow();
            });
            trackChanges(false);
          }}
          confirmationMessage="Are you sure you want to confirm and apply all the changes you have made?
          If you adjusted C.A.P. Enabled checkouts, remember to also adjust the Checkout Mapping."
        />
      )}
      {/* -------- on click of cancel button --------- */}
      {showCancelConfirmation && (
        <Confirmation
          showConfirmationPopup={showCancelConfirmation}
          closePopup={(e) => {
            setShowCancelConfirmation(false);
          }}
          confirmationActionCall={() => {
            trackChanges(false);
            setFieldError({});
            discardCheckoutChanges(id);
          }}
          confirmationMessage="Are you certain you want to discard all the changes you have made?"
        />
      )}
    </StyledMainBox>
  );
};

export default CheckoutLayout;
