import * as React from "react";
import SelectBox from "../../../../components/commonComponents/Selectors/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../../Store/actions";
import MultiSelect from "../../../../components/commonComponents/Selectors/MultiSelect";

export default function BrandWeeks() {
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const callendarWeeks =
    typeof currentStateData.campaign_weeks !== "undefined"
      ? [
          ...currentStateData.campaign_weeks
            .map((option) => {
              return parseInt(option.value);
            })
            .sort((next, prev) => {
              return next - prev;
            }),
        ]
      : [];

  return (
    <MultiSelect
      enableFullWidth={true}
      label={"Calendar Weeks"}
      options={callendarWeeks.map((weekNum) => {
        return { value: weekNum, label: weekNum };
      })}
      defaultVal={
        typeof currentStateData.brand_filters !== "undefined" &&
        typeof currentStateData.brand_filters.performance_filter !== "undefined" &&
        typeof currentStateData.brand_filters.performance_filter.weeks !== "undefined"
          ? currentStateData.brand_filters.performance_filter.weeks.map((weekNum) => {
              return { value: weekNum, label: weekNum };
            })
          : []
      }
      selectionCheck={(option, value) => option.label === value.label}
      usedFor="brandWeek"
      onChangeCall={(selectedOption) => {
        let userChoices = [];
        const preselectedBrandFilters =
          typeof currentStateData.brand_filters !== "undefined" ? currentStateData.brand_filters : {};
        selectedOption.forEach((chosen) => {
          if (chosen.label !== "(All)") {
            userChoices.push(chosen.label);
          } else {
            userChoices.push([...new Set(callendarWeeks)]);
          }
        });
        dispatchAction(
          updateStateData({
            brand_filters: {
              ...preselectedBrandFilters,
              performance_filter: {
                ...preselectedBrandFilters.performance_filter,
                weeks: userChoices,
              },
            },
            report_filter_saved: false,
          })
        );
      }}
    />
  );
}
