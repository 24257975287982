import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { validateAlphabets, validateEmpty } from "../../../api_calls/utils";
import {
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
} from "@mui/material";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import ActionButton from "../../../components/commonComponents/Buttons/ActionButton";
import { Close, Save } from "@mui/icons-material";
import CloseButton from "../../../components/commonComponents/Buttons/CloseButton";
import {
  clearSectionsList,
  resetNewSectionDetails,
  saveNewSectionForCampaignReports,
} from "../../../api_calls/ReportSettings";
import { StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import Confirmation from "../../../components/commonComponents/ConfirmationBox/Confirmation";

const NewReportSection = ({ showModal, closeModal, campaignId }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const dispatchAction = useDispatch();
  const [fieldError, setFieldError] = React.useState({});
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const handleFieldChange = (sectionData) => {
    const reportSectionData =
      typeof currentStateData.new_report_section !== "undefined" ? currentStateData.new_report_section : {};
    dispatchAction(
      updateStateData({
        new_report_section: { ...reportSectionData, ...sectionData },
      })
    );
  };

  const saveNewSection = () => {
    setFieldError({});
    let error = false;
    let errorDetails = {};

    const tabName =
      typeof currentStateData.new_report_section !== "undefined" &&
      typeof currentStateData.new_report_section.tab_name !== "undefined"
        ? currentStateData.new_report_section.tab_name
        : "";
    const tabText =
      typeof currentStateData.new_report_section !== "undefined" &&
      typeof currentStateData.new_report_section.tab_text !== "undefined"
        ? currentStateData.new_report_section.tab_text
        : "";
    const validateTabName = validateEmpty(tabName);
    const validateTabText = validateAlphabets(tabText, true, 25, true);

    if (validateTabName.error === true) {
      error = true;
      errorDetails = {
        ...errorDetails,
        error: true,
        tab_name: "Dashboard name must not be empty",
      };
    }
    if (validateTabText.error === true) {
      error = true;
      errorDetails = {
        ...errorDetails,
        error: true,
        tab_text: "Section name must not be empty, may not contain numbers, and can not exceed 25 characters",
      };
    }

    if (error === false) {
      setShowConfirmation(true);
    } else {
      setFieldError(errorDetails);
    }
  };

  return (
    <>
      <Dialog
        open={showModal}
        onClose={(e) => {
          e.preventDefault();
        }}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Add Report Section</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              Report section will be available for all future campaigns. Existing Reports are not changed. The name length
              is limited to 25 characters.
              <Box height={10} />
              <strong>Note: Your changes are saved immediately for the workspace when saving this popup.</strong>
            </Typography>
          </DialogContentText>

          {/* ------- Section name ------- */}
          <StyledStack direction={"row"}>
            <TextInputField
              fieldRequired={true}
              elemId="section_name"
              label="Section Name (Visible in CAP Explorer)"
              fieldName="section_name"
              showlabel={true}
              defaultVal={
                typeof currentStateData.new_report_section !== "undefined" &&
                typeof currentStateData.new_report_section.tab_text !== "undefined"
                  ? currentStateData.new_report_section.tab_text
                  : ""
              }
              inputType={"text"}
              onChangeCall={(e) => {
                handleFieldChange({
                  tab_text: e.target.value,
                });
              }}
              fieldError={
                typeof fieldError.error !== "undefined" &&
                fieldError.error === true &&
                typeof fieldError.tab_text !== "undefined"
                  ? fieldError.tab_text
                  : ""
              }
              errorStyle="tooltip"
            />
          </StyledStack>

          <Box height={5} />
          {/* ------- dashboard name ------- */}
          <StyledStack direction={"row"}>
            <TextInputField
              fieldRequired={true}
              elemId="dashboard_name"
              label="Dashboard Name (name in Tableau)"
              fieldName="dashboard_name"
              showlabel={true}
              defaultVal={
                typeof currentStateData.new_report_section !== "undefined" &&
                typeof currentStateData.new_report_section.tab_name !== "undefined"
                  ? currentStateData.new_report_section.tab_name
                  : ""
              }
              inputType={"text"}
              onChangeCall={(e) => {
                handleFieldChange({
                  tab_name: e.target.value,
                });
              }}
              fieldError={
                typeof fieldError.error !== "undefined" &&
                fieldError.error === true &&
                typeof fieldError.tab_name !== "undefined"
                  ? fieldError.tab_name
                  : ""
              }
              errorStyle="tooltip"
            />
          </StyledStack>
        </DialogContent>
        <Box height={20} />
        <DialogActions>
          <Stack direction={"row"} spacing={2}>
            <CloseButton
              onClick={(e) => {
                e.preventDefault();
                resetNewSectionDetails();
                closeModal(false);
              }}
              label="Cancel"
              icon={<Close />}
            />
            <ActionButton
              onClick={(e) => {
                e.preventDefault();
                saveNewSection();
              }}
              label={"Save"}
              icon={<Save />}
            />
          </Stack>
        </DialogActions>
      </Dialog>
      {/* ------- confirmation popup (on click of save button) -------- */}
      {showConfirmation && (
        <Confirmation
          showConfirmationPopup={showConfirmation}
          closePopup={(e) => {
            setShowConfirmation(false);
          }}
          confirmationActionCall={() => {
            saveNewSectionForCampaignReports(campaignId).then((info) => {
              if (info.success === true) {
                clearSectionsList();
                closeModal();
              }
            });
          }}
          confirmationMessage={
            typeof currentStateData.report_settings !== "undefined" &&
            typeof currentStateData.report_settings.campaign_settings_saved !== "undefined" &&
            currentStateData.report_settings.campaign_settings_saved === true ? (
              <>
                Saving will update your campaign's section details to the current workspace details. The campaign's
                visibility settings are only adjusted if you save the Report Settings. <Box height={10}/> Are you sure you want to
                proceed?
              </>
            ) : (
              "Are you sure you want to confirm and apply all the changes you have made?"
            )
          }
        />
      )}
    </>
  );
};

export default NewReportSection;
