import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";

export const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? 280 : `calc(${theme.spacing(7)} + 1px)`,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  ...(open && {
    "& .MuiDrawer-paper": {
      width: 260,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    },
  }),
  ...(!open && {
    "& .MuiDrawer-paper": {
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
    },
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
  padding: `${theme.spacing(2)}px 0`,
  marginTop: "10px",
  marginBottom: "10px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  // marginLeft: open ? "240px" : "0px",
  width: open ? "calc(100% - 230px)" : "calc(100% - 38px)",
  backgroundColor: "#fff",
  color: "black",
  boxShadow: "none",
  // backdropFilter: "blur(0.2px)",
}));

export const RightButtonGroup = styled("div")({
  marginLeft: "auto", // Push buttons to the right
  display: "flex",
  alignItems: "center",
});

export const navItemTextStyle = {
  fontSize: "1rem",
  fontFamily: "Arial, sans-serif",
  fontWeight: "bold",
  color: "#62626E",
};
