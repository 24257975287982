import React from "react";
import { Box, Stack, easing } from "@mui/material";
import { StyledCheckbox, StyledMainBox, StyledStack } from "../../../components/commonComponents/StyledComponents/styled";
import TextInputField from "../../../components/commonComponents/TextInputs/TextInputField";
import TabularList from "../../../components/commonComponents/TabularLists/TabularList";
import { useDispatch, useSelector } from "react-redux";
import { updateStateData } from "../../../Store/actions";
import { permissionConfig } from "./permissionScopes";

const AddRole = () => {
  const restrictedAreas = permissionConfig.scopes;
  const dispatchAction = useDispatch();
  const currentStateData = useSelector((state) => state.userSelections);
  const selectedPermissions =
    typeof currentStateData.role_permissions !== "undefined" ? currentStateData.role_permissions : {};

  const savePermissions = (scope, permissionSelections) => {
    dispatchAction(
      updateStateData({
        role_permissions: {
          ...selectedPermissions,
          [scope]: {
            ...selectedPermissions[scope],
            ...permissionSelections,
          },
        },
      })
    );
  };

  React.useEffect(() => {
    //set default role permissions (all permissions will be false)
    let defaultPermissions = {};
    restrictedAreas.forEach((scope) => {
      defaultPermissions = {
        ...defaultPermissions,
        [scope]: {
          can_view: false,
          can_edit: false,
        },
      };
    });
    dispatchAction(
      updateStateData({
        role_permissions: defaultPermissions,
      })
    );
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      {/* ========== main component body =========== */}
      <StyledMainBox component="main">
        <Box>
          <Stack direction={"row"} spacing={4}>
            {/* ------- campaign name -------- */}
            <TextInputField
              fieldRequired={true}
              elemId="roleName"
              label="Role Name"
              fieldName="role_name"
              showlabel={true}
              defaultVal=""
              inputType={"text"}
              onChangeCall={(e) => {
                e.preventDefault();
                dispatchAction(
                  updateStateData({
                    new_role_name: e.target.value,
                  })
                );
              }}
            />
          </Stack>
          <Box height={40} />
          {/* -------------- Permissions grid ----------------- */}
          <Stack direction={"row"} spacing={4}>
            <TabularList
              fieldNames={["Permission Scope", "View", "Edit"]}
              fieldValues={restrictedAreas.map((scope) => {
                return {
                  "Permission Scope": <StyledStack direction={"row"}> {scope} </StyledStack>,
                  View: (
                    <StyledStack direction={"row"}>
                      <StyledCheckbox
                        checked={
                          typeof selectedPermissions[scope] !== "undefined" ? selectedPermissions[scope].can_view : false
                        }
                        onChange={(e) => {
                          savePermissions(scope, { can_view: e.target.checked });
                        }}
                      />
                    </StyledStack>
                  ),
                  Edit: (
                    <StyledStack direction={"row"}>
                      <StyledCheckbox
                        checked={
                          typeof selectedPermissions[scope] !== "undefined" ? selectedPermissions[scope].can_edit : false
                        }
                        onChange={(e) => {
                          //view permission will automatically be true if edit is true.
                          let selections = {
                            can_edit: e.target.checked,
                          };
                          if (e.target.checked) {
                            selections = {
                              ...selections,
                              can_view: true,
                            };
                          }
                          savePermissions(scope, selections);
                        }}
                      />
                    </StyledStack>
                  ),
                };
              })}
            />
          </Stack>
        </Box>
      </StyledMainBox>
    </Box>
  );
};

export default AddRole;
