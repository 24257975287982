import * as React from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DarkRedActionButton } from "../Buttons/Styles";
import { Typography } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UploadButton({ uploadLabel, onChangeCall, uploadButtonId, acceptedFileType }) {
  const [fileName, setFileName] = React.useState("");
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(selectedFile.name);
    // If you want to pass the file to a parent component or perform additional actions, call the onChangeCall prop
    if (typeof onChangeCall !== "undefined") {
      onChangeCall(selectedFile);
    }
  };

  return (
    <>
      <DarkRedActionButton component="label" variant="contained" startIcon={<CloudUploadIcon />}>
        {uploadLabel}
        <VisuallyHiddenInput
          type="file"
          id={uploadButtonId}
          onChange={handleFileChange}
          accept={acceptedFileType}
          // You can add additional attributes like accept, multiple, etc. based on your requirements
        />
      </DarkRedActionButton>
      <Typography variant="body2">{fileName}</Typography>
    </>
  );
}
