/*
    This file has all the functions used in the marketing activities feature.
*/
import { updateStateData, deleteItems } from "../Store/actions";
import store from "../Store/store";
import { fetchCampaign, fetchStores } from "./campaignRequests";
import { fetchGtinList } from "./campaignSheet";
import { sendApiRequest } from "./requestConfig";
import { hitTableauApi } from "./tableauApiRequests";

export const fetchRequiredDetailsBeforePopup = async (campaignInfo, navigate) => {
  fetchCampaign(campaignInfo.campaign_id, navigate).then((info) => {
    fetchStores(campaignInfo.retailer_company_id).then((stores) => {
      fetchMarketingActivities(campaignInfo.campaign_id);
      fetchGtinList();
    });
  });
};

export const fetchMarketingActivities = async (campaign_id) => {
  const fetchData = await sendApiRequest("marketing_activity?campaign_id=" + campaign_id, {}, "GET");
  // console.log("fetchdata",fetchData);
  const reduxStateData = store.getState().userSelections;

  if (typeof fetchData.success !== "undefined" && fetchData.success === true) {
    let filteredActivitiesData = [];
    let usedActivityTypes = [];
    let manuallyAddedActivities = [];
    const preDefinedActivityList = ["activity_1", "activity_2", "activity_3", "activity_4"];
    const availableActivityTypes = [
      "activity_5",
      "activity_6",
      "activity_7",
      "activity_8",
      "activity_9",
      "activity_10",
      "activity_11",
      "activity_12",
      "activity_13",
      "activity_14",
    ];
    const storeDetails = typeof reduxStateData.stores_detail !== "undefined" ? reduxStateData.stores_detail : {};
    let activityOptions = [];
    if (typeof fetchData.response.activities_data !== "undefined" && fetchData.response.activities_data.length !== 0) {
      fetchData.response.activities_data.forEach((activity) => {
        let uniqueWeeks = [...new Set(activity.week.split(", "))];
        let uniquegtin = [...new Set(activity.gtin.split(","))];
        let uniqueGtinDesc = [...new Set(activity.description.split(","))];
        let uniqueRegions = [...new Set(activity.id_region.split(","))].toString();
        let uniqueStores = [...new Set(activity.id_store.split(","))];
        let uniqueMarkets = [...new Set(activity.id_market_type.split(","))].toString();
        let storeNames = [];
        uniqueStores.forEach((idStore) => {
          if (Object.keys(storeDetails).length !== 0) {
            storeNames.push(storeDetails[idStore].name_store);
          }
        });

        filteredActivitiesData.push({
          ...activity,
          week: uniqueWeeks,
          gtin: uniquegtin,
          id_region: uniqueRegions,
          id_market_type: uniqueMarkets,
          id_store: uniqueStores,
          store_names: storeNames,
          description: uniqueGtinDesc,
        });
        // unique activity name options for filter dropdowns on campaign analytics
        activityOptions = !activityOptions.includes(activity.name_activity)
          ? [...activityOptions, activity.name_activity]
          : [...activityOptions];
        if (
          !preDefinedActivityList.includes(activity.activity_type) &&
          !usedActivityTypes.includes(activity.activity_type)
        ) {
          manuallyAddedActivities.push({ value: activity.activity_type, label: activity.name_activity });
        }
        usedActivityTypes.push(activity.activity_type);
      });
    }

    // check which activity types from available set are already used
    const notUsedActivities = availableActivityTypes.filter((activity) => {
      return !usedActivityTypes.includes(activity);
    });
    // save activity data in redux state

    store.dispatch(
      updateStateData({
        activities_data: filteredActivitiesData,
        next_available_activity: notUsedActivities[0],
        manual_activity_list: manuallyAddedActivities,
        activityOptions: activityOptions.sort(),
      })
    );
  }

  return fetchData;
};

// create marketing records in database
export const saveMarketingActivities = async (newActivityRecord) => {
  // if gtin is not selected by user, then we have to add a dummy value manually to avoid errors from server.
  if (typeof newActivityRecord.gtin === "undefined") {
    newActivityRecord = {
      ...newActivityRecord,
      gtin: [
        {
          gtin: null,
          description: newActivityRecord.activity_name,
        },
      ],
    };
  }
  // if discount and original price are also not entered by user, then we have to set a default value to 0
  if (typeof newActivityRecord.original_price === "undefined") {
    newActivityRecord = {
      ...newActivityRecord,
      original_price: null,
    };
  }
  if (typeof newActivityRecord.discounted_price === "undefined") {
    newActivityRecord = {
      ...newActivityRecord,
      discounted_price: null,
    };
  }
  const saveData = await sendApiRequest("marketing_activity/add", newActivityRecord, "POST");

  if (typeof saveData.success !== "undefined" && saveData.success === true) {
    // refresh activities listing to show new records
    fetchMarketingActivities(newActivityRecord.campaign_id);
  }

  return saveData;
};

export const updateMarketingActivities = async (updateActivityRecords) => {
  let dataToUpdate = updateActivityRecords.data_to_update;
  // if gtin is not selected by user, then we have to add a dummy value manually to avoid errors from server.
  if (typeof dataToUpdate.gtin === "undefined") {
    dataToUpdate = {
      ...dataToUpdate,
      gtin: [
        {
          gtin: null,
          description: dataToUpdate.activity_name,
        },
      ],
    };
  }
  // if discount and original price are also not entered by user, then we have to set a default value to 0
  if (typeof dataToUpdate.original_price === "undefined") {
    dataToUpdate = {
      ...dataToUpdate,
      original_price: null,
    };
  }
  if (typeof dataToUpdate.discounted_price === "undefined") {
    dataToUpdate = {
      ...dataToUpdate,
      discounted_price: null,
    };
  }

  let activityUpdateData = {
    ...updateActivityRecords,
    data_to_update: dataToUpdate,
  };

  const updateData = await sendApiRequest("marketing_activity/update", activityUpdateData, "POST");

  if (typeof updateData.success !== "undefined" && updateData.success === true) {
    // refresh activities listing to show new records
    fetchMarketingActivities(updateActivityRecords.data_to_update.campaign_id);
  }

  return updateData;
};

export const deleteMarketingActivities = async () => {
  const currentStateData = store.getState().userSelections;
  const activitiesToDelete = currentStateData.activity_ids_to_delete;
  const deleteData = await sendApiRequest(
    "marketing_activity/delete",
    {
      activity_ids: activitiesToDelete,
    },
    "POST"
  );

  if (typeof deleteData.success !== "undefined" && deleteData.success === true) {
    // refresh activities listing to show new records
    fetchMarketingActivities(currentStateData.campaign_detail.campaign_id);
  }

  return deleteData;
};

export const resetMarketingActivitiesData = () => {
  store.dispatch(
    deleteItems([
      "activities_data",
      "next_available_activity",
      "manual_activity_list",
      "gtin_list",
      "campaign_weeks",
      "activity_selections",
      "activity_ids_to_update",
      "activity_to_update",
    ])
  );
};
