import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { BorderBottom } from "@mui/icons-material";

const commonStyle = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  boxSizing: "border-box",
  margin: "0px",
  cursor: "pointer",
  userSelect: "none",
  verticalAlign: "middle",
  appearance: "none",
  textDecoration: "none",
  fontWeight: "700",
  lineHeight: "1.71429",
  fontSize: "0.875rem",
  textTransform: "unset",
  minWidth: "64px",
  padding: "6px 12px",
  borderRadius: "8px",
  transition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  boxShadow: "none",
};

const buttonWithBorder = {
  ...commonStyle,
  color: "#AF3241",
  borderColor: "#AF3241",
  backgroundColor: "transparent",
  "&.MuiButton-outlined": {
    border: `1px solid #AF3241`,
  },
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "#AF3241",
    color: "#AF3241",
  },
};

const buttonWithoutBorder = {
  ...commonStyle,
  color: "#AF3241",
  borderColor: "#AF3241",
  backgroundColor: "transparent",
  "&.MuiButton-outlined": {
  },
  "&:hover": {
    backgroundColor: "transparent",
    color: "#AF3241",
    boxShadow: "none",
    textDecoration: "underline",
  },
};

export const DarkRedActionButton = styled(Button)(() => ({
  ...commonStyle,
  color: "#fff",
  backgroundColor: "#AF3241",
  "&:hover": {
    backgroundColor: "#AF3241",
  },
  outline: "0px",
  border: "0px currentcolor",
}));

export const LightRedActionButton = styled(Button)(() => ({
  ...commonStyle,
  color: "#fff",
  backgroundColor: "#F04B55",
  "&:hover": {
    backgroundColor: "#F04B55",
  },
  outline: "0px",
  border: "0px currentcolor",
}));

export const DarkGreyActionButton = styled(Button)(() => ({
  ...commonStyle,
  color: "#fff",
  backgroundColor: "#2D2D37",
  "&:hover": {
    backgroundColor: "#2D2D37",
  },
  outline: "0px",
  border: "0px currentcolor",
}));

export const DarkLogInButton = styled(Button)(() => ({
  ...commonStyle,
  color: "#fff",
  backgroundColor: "#2D2D37",
  "&:hover": {
    backgroundColor: "#2D2D37",
  },
  outline: "0px",
  border: "0px currentcolor",
}));

export const LightGreyActionButton = styled(Button)(() => ({
  ...commonStyle,
  color: "#fff",
  backgroundColor: "#62626E",
  "&:hover": {
    backgroundColor: "#62626E",
  },
  outline: "0px",
  border: "0px currentcolor",
}));

export const CloseButtonStyle = styled(Button)(() => ({
  ...buttonWithBorder,
}));

export const TextButtonStyle = styled(Button)(() => ({
  ...buttonWithoutBorder,
}));

export const DisabledButtonStyle = styled(Button)(() => ({
  ...buttonWithBorder,
  cursor: "not-allowed",
}));

export const DisabledLightGreyButtonStyle = styled(Button)(() => ({
  ...commonStyle,
  color: "#fff",
  cursor: "not-allowed",
  backgroundColor: "#62626E",
  "&:hover": {
    backgroundColor: "#62626E",
  },
  outline: "0px",
  border: "0px currentcolor",
}));

export const BorderedButtonStyle = styled(Button)(() => ({
  ...buttonWithBorder,
}));
