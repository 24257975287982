import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

import TextInputField from "../../TextInputs/TextInputField";
import ActionButton from "../../Buttons/ActionButton";
import { Close, Password } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CloseButton from "../../Buttons/CloseButton";
import { checkUserDetailsFromCookie, resetUserPassword } from "../../../../api_calls/userManagement";

const ResetPassword = ({ open, onClose, size }) => {
  const currentStateData = useSelector((state) => state.userSelections);
  const [showConfirmation, setshowConfirmation] = React.useState(false);

  const [formData, setFormData] = React.useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [userData, setUserData] = React.useState({});

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    // Validate the form fields
    if (!formData.newPassword || !formData.confirmPassword) {
      toast("Please fill in all fields.", { type: "error" });
    } else if (formData.newPassword !== formData.confirmPassword) {
      toast("New password and confirm password do not match.", { type: "error" });
    } else {
      setshowConfirmation(true);
    }
  };

  const updatePassword = () => {
    //send reset password request
    resetUserPassword(formData.newPassword);
    onClose();
  };

  React.useEffect(() => {
    // fetch user detail from the cookies
    const checkUserDetail = checkUserDetailsFromCookie();
    
    if (checkUserDetail.success === true) {
      setUserData(checkUserDetail.user_detail);
    }
    setFormData({
      newPassword: "",
      confirmPassword: "",
    });
    setshowConfirmation(false);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={size}
    >
      <DialogTitle>Account Details</DialogTitle>
      <DialogContent>
        {showConfirmation === false ? (
          <Box
            height={20}
            sx={{
              width: "370px",
              height: "256px",
            }}
          >
            <Box height={20} />
            {typeof userData.user_name !== "undefined" && (
              <Stack spacing={2.6} direction={"row"}>
                <Typography variant="body2">User: </Typography>
                <Typography variant="body2">{userData.user_name}</Typography>
              </Stack>
            )}
            <Box height={20} />
            {typeof userData.user_email !== "undefined" && (
              <Stack spacing={2} direction={"row"}>
                <Typography variant="body2">Email:</Typography>
                <Typography variant="body2">{userData.user_email}</Typography>
              </Stack>
            )}
            <Box height={20} />
            <br />
            <Typography variant="body3">Change Password:</Typography>
            {/* -------- new password ----------- */}
            <Stack spacing={4} direction={"row"}>
              <TextInputField
                fieldRequired={true}
                elemId="newPassword"
                label="New Password"
                fieldName="newPassword"
                showlabel={true}
                defaultVal={formData.newPassword}
                inputType={"password"}
                onChangeCall={handleInputChange}
              />
            </Stack>
            <Box height={20} />
            {/* -------- confirm password ----------- */}
            <Stack spacing={4} direction={"row"}>
              <TextInputField
                fieldRequired={true}
                elemId="confirmPassword"
                label="Confirm New Password"
                fieldName="confirmPassword"
                showlabel={true}
                defaultVal={formData.confirmPassword}
                inputType={"password"}
                onChangeCall={handleInputChange}
              />
            </Stack>
          </Box>
        ) : (
          <Box
            height={20}
            sx={{
              // width: "800px",
              height: "50px",
            }}
          >
            <Stack spacing={4} direction={"row"}>
              <Typography variant="body2">
                By confirming this action, your password will be updated, and you will be logged out. You will need to log
                in again with your new password to continue using the system.
              </Typography>
            </Stack>
          </Box>
        )}
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton onClick={onClose} label="Cancel" icon={<Close />} />
          <ActionButton
            onClick={showConfirmation ? updatePassword : handleResetPassword}
            label={showConfirmation ? "Confirm" : "Update"}
            icon={<Password />}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPassword;
