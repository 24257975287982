import React from "react";
import { toast } from "react-toastify";
import { useCustomTranslation } from "../../hooks/useCustomTranslation";
import { Box, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import ActionButton from "./Buttons/ActionButton";
import { Check, Close } from "@mui/icons-material";
import TextInputField from "./TextInputs/TextInputField";
import { StyledMainBox, StyledStack } from "./StyledComponents/styled";
import CloseButton from "./Buttons/CloseButton";

const ConfirmationPopup = (props) => {
  const [confirmText, setConfirmText] = React.useState("");
  const t = useCustomTranslation();

  const performAction = async (e) => {
    e.preventDefault();
    // check if user typed yes or not
    if (confirmText !== "" && confirmText.toLowerCase() === "delete") {
      props.confirmationActionCall();
      props.closePopup(false);
    } else {
      toast("Please type delete in the input box.", { type: "error" });
    }
  };

  return (
    <Dialog
      open={props.showConfirmationPopup}
      onClose={(e) => {
        e.preventDefault();
      }}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle>{t("action_confirmation_modal_heading")}</DialogTitle>
      <DialogContent>
        <StyledMainBox component="main">
          <Box>
            <Typography variant="body2">Are you sure you want to delete the record?</Typography>
            <StyledStack direction={"row"} spacing={4}>
              {/* ------- confirmation text field  ------- */}
              <TextInputField
                fieldRequired={false}
                elemId="confirmation_text"
                label="Type DELETE"
                fieldName="confirmation_text"
                showlabel={true}
                defaultVal={confirmText}
                inputType={"text"}
                onChangeCall={(e) => {
                  setConfirmText(e.target.value.trim());
                }}
              />
            </StyledStack>
          </Box>
        </StyledMainBox>
      </DialogContent>
      <Box height={20} />
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              props.closePopup(false);
            }}
            label={t("close_text")}
            icon={<Close />}
          />
          <ActionButton onClick={performAction} label={t("confirm_button_text")} icon={<Check />} />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
