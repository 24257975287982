import * as React from "react";
import { DisabledButtonStyle, DisabledLightGreyButtonStyle } from "./Styles";
import { HtmlTooltip } from "../StyledComponents/styled";

export default function DisabledButton({ label, onClick, icon, toolTipContent }) {
  return (
    <HtmlTooltip title={toolTipContent}>
      <DisabledButtonStyle component={"a"} variant="outlined" onClick={onClick} startIcon={icon}>
        {label}
      </DisabledButtonStyle>
    </HtmlTooltip>
  );
}

export const DisabledGreyButton = ({ label, onClick, icon, toolTipContent }) => {
  return (
    <HtmlTooltip title={toolTipContent}>
      <DisabledLightGreyButtonStyle component={"button"} variant="contained" onClick={onClick} startIcon={icon}>
        {label}
      </DisabledLightGreyButtonStyle>
    </HtmlTooltip>
  );
};
